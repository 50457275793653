import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../../../components/common/EditButton";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import ButtonCommon from "../../../../components/common/ButtonCommon";
import UploadFileCommon from "../../../../components/common/UploadFileCommon";
import SampleImage from "../../../../media/images/example-upload.jpg";
import { Delete } from "@mui/icons-material";
const Overview = () => {
  return (
    <>
      {" "}
      <Paper sx={{ p: 3 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 41/61
            </Typography>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_whyChooseUs_overview_metaTitle"
              }
              contentField="Meta Title"
            />
          </Box>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 157/160
            </Typography>

            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_whyChooseUs_overview_metaDescription"
              }
              contentField="Meta Description"
            />
          </Box>
          <Box>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_whyChooseUs_overview_metaKeywords"
              }
              contentField="Meta Keywords"
              classHeight="h-100"
            />
          </Box>
        </Stack>
      </Paper>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={3}>
          <UploadFileCommon
            keyCaption="fileUpload_caption"
            contentCaption="Upload File"
          />

          <Typography className="text-14 mt-5">
            Size <EditButton keyField={"fileUpload_size"} contentField="Size" />
            : 1440 X 463 Pixel{" "}
            <EditButton keyField={"fileUpload_unit"} contentField="Pixel" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Stack direction={"row"} alignItems={"flex-end"}>
            <img src={SampleImage} alt="why-choose-us" />{" "}
            <Delete color="error" />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={3}>
          <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
            <ButtonCommon
              keyField={
                "saveButton"
              }
              contentField="Save"
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
