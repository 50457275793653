import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import UploadFileCommon from './UploadFileCommon'
import EditButton from './EditButton'
import { Delete } from '@mui/icons-material'
import SampleImage from "../../media/images/example-upload.jpg";

interface Props {
  isRevert?: boolean
}
const UploadProfileAndSampleCommon = ({isRevert}: Props) => {
  return (
    <Box className="d-flex gap-20" sx={{ flexDirection: isRevert ? "row-reverse" : "row"}}>
              
              <Box>
                <Stack direction={"row"} alignItems={"flex-end"}>
                  <img src={SampleImage} alt="why-choose-us" className='w-70 h-70 rounded-50' style={{objectFit: "cover"}} />{" "}
                  {/* <Delete color="error" /> */}
                </Stack>
              </Box><Box>
                <UploadFileCommon keyCaption="profileUpload_caption" contentCaption='Upload Profile Picture' />
              </Box>
            </Box>
  )
}

export default UploadProfileAndSampleCommon