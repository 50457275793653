import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from './EditButton'
import TextFieldCommon from './TextFieldCommon'
import UploadImageAndSampleCommon from './UploadImageAndSampleCommon'
import SelectColorCommon from './SelectColorCommon'
interface Props {
  sectionName: string;
  sectionKey: string;
  showButton?: boolean
}
const SectionCommon = ({sectionName, sectionKey, showButton}: Props) => {
  return (
    <>
        <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
          <Stack spacing={5}>
            <Box>
            <Typography className="text-16 fw-600 text-tkg-blue">{sectionName} <EditButton
                    keyField={`${sectionKey}_header`}
                    contentField={sectionName}
                  /> </Typography>
            </Box>

            <Box>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={
                    `checkLength`
                  }
                  contentField="Characters"
                />
                : 41/50
              </Typography>
              <TextFieldCommon
                keyField={
                  `${sectionKey}_title`
                }
                contentField="Title"
              />
            </Box>
            <Box>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={
                    `checkLength`
                  }
                  contentField="Characters"
                />
                : 157/120
              </Typography>

              <TextFieldCommon
                keyField={
                  `${sectionKey}_textBody`
                }
                contentField="Text Body"
              />
            </Box>
            <UploadImageAndSampleCommon />

           {!!showButton ? <SelectColorCommon sectionKey={sectionKey} buttonContent="Learn More" /> : null}
          </Stack>
        </Paper>
      </>
  )
}

export default SectionCommon