import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ContentLanguageForm, ContentLanguagePayload, DetailContentLanguageResponse } from "../../interfaces/contentLanguage";
import languageContentService from "../../services/languageService";
import { useGlobalContext } from "../../context/MyGlobalContext";
import validationMessage from "../../constants/validationMessage";

const validationSchema = yup.object().shape({
  parentId: yup
    .number()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  section: yup.string().required(validationMessage.required),
  contents: yup.array(
    yup.object().shape({
      lang: yup.string().required(validationMessage.required),
      content: yup
        .string()
        .nullable()
        .transform((v) => (v === undefined ? null : v)),
    })
  ),
  childType: yup
    .string()
    .nullable()
    .transform((val) => (val === undefined ? null : val)),
  tag: yup
    .string()
    .nullable()
    .transform((val) => (val === undefined ? null : val)),
});

const FormContentLanguage = () => {
  const { setOpenCreateLanguage, keyLanguageContent, openCreateLanguage, setListDataLanguage} = useGlobalContext();
  const [contentDataDetail, setContentDataDetail] =
    useState<DetailContentLanguageResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isEditBtn, setIsEditBtn] = useState(false);

  useEffect(() => {
    if (contentDataDetail?.en || contentDataDetail?.fr || contentDataDetail?.cn) {
      setIsEditBtn(true);
    } else {
      setIsEditBtn(false);
    }
  }, [contentDataDetail]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContentLanguageForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contents",
  });

  const getDataDetailByKey = async (section: string) => {
    setLoading(true)
    const response = await languageContentService.getContentByKey(section);
    if (response?.status === 200) {
      const data: DetailContentLanguageResponse = response.data;
      setContentDataDetail(data);
      const initData = {
        section: keyLanguageContent.section,
        contents: [
          {
            lang: "en",
            content: data.en?.content ?? keyLanguageContent.content,
          },
          {
            lang: "fr",
            content: data.fr?.content ?? "",
          },
          {
            lang: "cn",
            content: data.cn?.content ?? "",
          },
        ],
      };
      reset(initData);
    }
    setLoading(false)
  };

  useEffect(() => {
      getDataDetailByKey(keyLanguageContent.section)
  }, []);


  const submit: SubmitHandler<ContentLanguageForm> = async (data) => {    
    if (contentDataDetail?.en || contentDataDetail?.fr || contentDataDetail?.cn) {
      setLoadingBtn(true)
      const payload: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.section,        
        contents: data?.contents
          ? data?.contents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const response = await languageContentService.updateContentLanguage(
        keyLanguageContent.section,
        payload
      );

      if (response?.status === 200) {
        Swal.fire({
          title: `Update Language Successfully!`,
          showConfirmButton: true,
          icon: "success",
          position: "center",
        });
        setOpenCreateLanguage(false);
        const responseList = await languageContentService.getListContent(1);
        if (responseList?.status === 200) {
          setListDataLanguage(responseList.data);
        }
      }
      setLoadingBtn(false)
    } else {
      setLoadingBtn(true)
      const payload: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.section,        
        contents: data?.contents
          ? data?.contents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const response = await languageContentService.storeContentLanguage(
        payload
      );

      if (response?.status === 200) {
        Swal.fire({
          title: `Store Language Successfully!`,
          showConfirmButton: true,
          icon: "success",
          position: "center",
        });
        setOpenCreateLanguage(false);
        const responseList = await languageContentService.getListContent(1);
        if (responseList?.status === 200) {
          setListDataLanguage(responseList.data);
        }
      }
      setLoadingBtn(false)
    }
  };

  const error: SubmitErrorHandler<ContentLanguageForm> = (errors) => {
    console.log(errors);
    
  }
  const renderTitleLang = (lang: string) => {
    switch (lang) {
      case "en":
        return "English";
      case "fr":
        return "French";
      case "cn":
        return "Chinese";
      default:
        return "";
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={openCreateLanguage}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
          bgcolor: "#103551",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        {contentDataDetail?.en
          ? `Update Content of ${contentDataDetail?.en?.content ?? keyLanguageContent.content}`
          : `Add Content of ${keyLanguageContent.content}`}

        <IconButton aria-label="close" onClick={() => setOpenCreateLanguage(false)}>
          <Close sx={{ color: "#fff" }} />
        </IconButton>
      </DialogTitle>
      <form id={`form-handle-task`} onSubmit={handleSubmit(submit, error)}>
        <DialogContent>
          <Box>
            {loading ? (
              <Stack
                sx={{ width: "100%" }}
                spacing={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Stack>
            ) : (
              <Grid container spacing={2}>
                {fields.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <TextField
                      className={isEditBtn ? "bg-readonly" : ""}
                      {...register(`contents.${index}.content`)}
                      label={renderTitleLang(item.lang)}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ readOnly: isEditBtn }}
                      error={errors.contents?.[index]?.content !== undefined}
                      helperText={
                        errors.contents?.[index]?.content !== undefined
                          ? validationMessage.required
                          : ""
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isEditBtn ? (
            <Button variant={"contained"} onClick={() => setIsEditBtn(false)}>
              Edit
            </Button>
          ) : (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Button
                variant={"contained"}
                onClick={() => setIsEditBtn(true)}
                sx={{
                  bgcolor: "#808080",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Button>
              <Button disabled={loadingBtn} type="submit" variant={"contained"}>
                Submit
              </Button>
            </Stack>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormContentLanguage;
