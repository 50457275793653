import { Box, Table, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import TableHeader from "../../components/Table/TableHeader";
import EditButton from "../../components/common/EditButton";

const TablePriceRoom = () => {
  return (
    <div>
      <Table className="table ">
        <TableHead>
          <TableHeader>
            <TableCell className="text-center text-dark fw-600">
              Passenger{" "}
              <EditButton keyField="passenger" contentField="Passenger" />
            </TableCell>
         
            <TableCell className="text-center text-dark fw-600">
              Price <EditButton keyField="price" contentField="Price" />
            </TableCell>
          
            <TableCell className="text-center text-dark fw-600">
              Tax
              <EditButton keyField="tax" contentField="Tax" />
            </TableCell>
         
            <TableCell className="text-center text-dark fw-600">
              Total(USD) <EditButton keyField="total" contentField="Total" />
            </TableCell>
          </TableHeader>
        </TableHead>
        <TableRow className="item-hover">
          <TableCell align="center">Mr. Single Traveller</TableCell>
          <TableCell align="center">7000</TableCell>
          <TableCell align="center">0</TableCell>
          <TableCell align="center">7000</TableCell>
        </TableRow>
        <TableRow className="item-hover">
          <TableCell align="center"></TableCell>
          <TableCell align="center" colSpan={2}>
            Total Cost{" "}
            <EditButton keyField="totalCost" contentField="Total Cost" />
          </TableCell>
          <TableCell align="center">1200</TableCell>
        </TableRow>
        <TableRow className="item-hover">
          <TableCell align="center"></TableCell>
          <TableCell align="center" colSpan={2}>
            LESS commission{" "}
            <EditButton keyField="manage_booking_lessCommission" contentField="LESS commission" />
          </TableCell>
          <TableCell align="center">1200</TableCell>
        </TableRow>
        <TableRow className="item-hover">
          <TableCell align="center"></TableCell>
          <TableCell align="center" colSpan={2}>
            Total Payable{" "}
            <EditButton keyField="manage_booking_total_payable" contentField="Total Payable" />
          </TableCell>
          <TableCell align="center">1200</TableCell>
        </TableRow>
      </Table>
    </div>
  );
};

export default TablePriceRoom;
