import { Edit } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { useGlobalContext } from '../../context/MyGlobalContext';

interface Props {
    keyFieldChecked: string;
    contentFieldChecked: string;
    keyFieldUnChecked: string;
    contentFieldUnChecked: string;
    color?: string;
}
const EditButtonSwitch = ({keyFieldChecked,
  contentFieldChecked,
  keyFieldUnChecked,
  contentFieldUnChecked, color="#0000008a"}: Props) => {
    const { setOpenCreateSwitchLanguage, setKeySwitchLanguageContent } = useGlobalContext();    
  return (
    <IconButton size='small' onClick={() => {setOpenCreateSwitchLanguage(true); setKeySwitchLanguageContent({
        sectionChecked: keyFieldChecked,
        contentChecked: contentFieldChecked,
        sectionUnChecked: keyFieldUnChecked,
        contentUnChecked: contentFieldUnChecked
    })}}>
        <Edit sx={{color: color, fontSize: "18px"}} />
    </IconButton>
  )
}

export default EditButtonSwitch