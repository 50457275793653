import { AccountCircleOutlined, EmojiObjectsOutlined, FavoriteBorder, Search } from "@mui/icons-material";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import EditButton from "../../components/common/EditButton";
const MobileAppPage = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
      <Grid container spacing={2} >
        <Grid item xs={12} md={3} className="bg-tkg-blue text-white">
          <Stack spacing={1}>
            <FavoriteBorder />
            <Typography variant="h6">
              Wishlist{" "}
              <EditButton
                keyField={"mobile_app_bottomMenu_wishlist"}
                contentField="Wishlist"
              />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3} className="bg-tkg-blue text-white">
          <Stack spacing={1}>
            <EmojiObjectsOutlined />
            <Typography variant="h6">
              Inspiration{" "}
              <EditButton
                keyField={"mobile_app_bottomMenu_inspiration"}
                contentField="Inspiration"
              />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3} className="bg-tkg-blue text-white">
          <Stack spacing={1}>
            <Search />
            <Typography variant="h6">
              Search{" "}
              <EditButton
                keyField={"mobile_app_bottomMenu_search"}
                contentField="Search"
              />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3} className="bg-tkg-blue text-white">
          <Stack spacing={1}>
            <AccountCircleOutlined />
            <Typography variant="h6">
              Profile{" "}
              <EditButton
                keyField={"mobile_app_bottomMenu_profile"}
                contentField="Profile"
              />
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MobileAppPage;
