import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonCommon from "../../../../components/common/ButtonCommon";
import EditButton from "../../../../components/common/EditButton";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import WrapperCard from "../../../../components/common/WrapperCard";
import ButtonSelectColour from "../../../../components/common/ButtonSelectColour";
import AutoCompleteCommon from "../../../../components/common/AutoCompleteCommon";
import SearchBoxFeature from "../common-feature/SearchBoxFeature";
import HighlightFeatureBelowSearchBox from "../common-feature/HighlightFeatureBelowSearchBox";

const SearchAgent = () => {
  return (
    <>
      {" "}
      <Box className="bg-white" sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 41/61
            </Typography>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_searchAgent_title"
              }
              contentField="Title"
            />
          </Box>

          <SearchBoxFeature keywordField={"homepage_navigationMenu_findLocalAgent_searchAgent"} placeHolder={"Enter postcode or suburb"} />
          <HighlightFeatureBelowSearchBox />
        </Stack>
      </Box>
      <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
        <ButtonCommon
          keyField={
            "saveButton"
          }
          contentField="Save"
        />
      </Stack>
    </>
  );
};

export default SearchAgent;
