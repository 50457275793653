import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import TextFieldCommon from "./TextFieldCommon";
import ButtonCommon from "./ButtonCommon";

interface Props {
  isRevert?: boolean;
  sectionKey: string;
  buttonContent: string;
}
const SelectColorCommon = ({
  sectionKey,
  buttonContent,
  isRevert = false,
}: Props) => {
  return (
    <>
      {isRevert ? (
        <Stack direction={"row"} alignItems={"center"} spacing={5}>
          <Box>
            <ButtonCommon
              keyField={`${sectionKey}_buttonContent`}
              contentField={buttonContent}
            />
          </Box>
          <Box className="w-half">
            <TextFieldCommon
              keyField={`${sectionKey}_buttonURL`}
              contentField="URL"
            />
          </Box>
        </Stack>
      ) : (
        <Stack direction={"row"} alignItems={"center"} spacing={5}>
          <Box className="w-half">
            <TextFieldCommon
              keyField={`${sectionKey}_buttonURL`}
              contentField="URL"
            />
          </Box>

          <Box>
            <ButtonCommon
              keyField={`${sectionKey}_buttonContent`}
              contentField={buttonContent}
            />
          </Box>
        </Stack>
      )}
    </>
  );
};

export default SelectColorCommon;
