import TableCell from "@mui/material/TableCell";
import TableHeader from "../../../../components/Table/TableHeader";
import EditButton from "../../../../components/common/EditButton";

const TableHeading = () => {
  return (
    <TableHeader>
      <TableCell className="bg-slight-blue text-left">#</TableCell>
      <TableCell className="bg-slight-blue text-left">Name <EditButton keyField="navigationMenu_inspiration_list_table_header_name" contentField="Name" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Type <EditButton keyField="navigationMenu_inspiration_list_table_header_type" contentField="Type" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Country <EditButton keyField="navigationMenu_inspiration_list_table_header_country" contentField="Country" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Destination <EditButton keyField="navigationMenu_inspiration_list_table_header_destination" contentField="Destination" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Created <EditButton keyField="navigationMenu_inspiration_list_table_header_created" contentField="Created" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Updated <EditButton keyField="navigationMenu_inspiration_list_table_header_updated" contentField="Updated" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Actions <EditButton keyField="navigationMenu_inspiration_list_table_header_actions" contentField="Actions" /></TableCell>
    </TableHeader>
  );
};

export default TableHeading;
