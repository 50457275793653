import { Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import UploadFileCommon from "../../../../components/common/UploadFileCommon";
import AutoCompleteCommon from "../../../../components/common/AutoCompleteCommon";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import TextEditorCommon from "../../../../components/common/TextEditorCommon";
import EditButton from "../../../../components/common/EditButton";
import ButtonCommon from "../../../../components/common/ButtonCommon";

const StoreInspirationPage = () => {
  return (
    <>
      <Paper sx={{ p: 3, mb: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <UploadFileCommon
              keyCaption="imageUpload_caption"
              contentCaption="Upload Image"
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} md={2}>
            <AutoCompleteCommon
              keyField="navigationMenu_inspiration_store_typeField"
              contentField="Type"
              defaultData={[]}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <AutoCompleteCommon
              keyField="navigationMenu_inspiration_store_countryField"
              contentField="Country"
              defaultData={[]}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <AutoCompleteCommon
              keyField="navigationMenu_inspiration_store_destinationField"
              contentField="Destination"
              defaultData={[]}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <TextFieldCommon
                  keyField="navigationMenu_inspiration_store_titleField"
                  contentField="Title"
                />
                <TextEditorCommon
                  keyField="navigationMenu_inspiration_store_descriptionField"
                  contentField="Description"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <UploadFileCommon
                  keyCaption="imageUpload_caption"
                  contentCaption="Upload Image"
                />
                <TextFieldCommon keyField="imageDescriptionField" contentField="Image Description" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography className="text-16 fw-600">
                    Section{" "}
                    <EditButton
                      keyField="navigationMenu_inspiration_store_sectionHeader"
                      contentField="Section"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="navigationMenu_inspiration_store_sectionTitle"
                    contentField="Title"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextEditorCommon
                    keyField="navigationMenu_inspiration_store_sectionContent"
                    contentField="Content"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <UploadFileCommon
                    keyCaption="imageUpload_caption"
                    contentCaption="Upload Image"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    className="h-full"
                    alignItems={"flex-end"}
                    justifyContent={"flex-end"}
                  >
                    <ButtonCommon
                      keyField="addMoreButton"
                      contentField="Add More"
                      bgColor="bg-white"
                      textColor="text-tkg-blue"
                      iconColor="#0000008a"
                      borderColor="border-color-primary"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography className="text-20 fw-600">
                    Custom FAQs{" "}
                    <EditButton
                      keyField="customFAQsField"
                      contentField="Custom FAQs"
                    />
                  </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                <Typography className="text-16 fw-600">
                   Question 
                    <EditButton
                      keyField="navigationMenu_inspiration_store_CustomFAQ"
                      contentField="Question"
                    /> 1
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_faqs_questionField"
                    contentField="Question"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextEditorCommon
                    keyField="homepage_faqs_answerField"
                    contentField="Answer"
                  />
                </Grid>
               
                <Grid item xs={12}>
                  <Stack
                    className="h-full"
                    alignItems={"flex-end"}
                    justifyContent={"flex-end"}
                  >
                    <ButtonCommon
                      keyField="addMoreButton"
                      contentField="Add More"
                      bgColor="bg-white"
                      textColor="text-tkg-blue"
                      iconColor="#0000008a"
                      borderColor="border-color-primary"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <Stack alignItems={"flex-end"}>
        <ButtonCommon keyField="submitButton" contentField="Submit" />
      </Stack>
    </>
  );
};

export default StoreInspirationPage;
