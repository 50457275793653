import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../../../components/common/EditButton'
import TextFieldCommon from '../../../../components/common/TextFieldCommon'
import UploadImageAndSampleCommon from '../../../../components/common/UploadImageAndSampleCommon'
import SelectColorCommon from '../../../../components/common/SelectColorCommon'

const SearchLink = () => {
  return (
    <>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 41/50
            </Typography>
            <TextFieldCommon
              keyField={"homepage_navigationMenu_whyChooseUs_searchLink_title"}
              contentField="Title"
            />
          </Box>
          <UploadImageAndSampleCommon />
          <SelectColorCommon isRevert={true} buttonContent='Search' sectionKey='homepage_navigationMenu_whyChooseUs_searchLink' />
        </Stack>
      </Paper>
      

    </>
  )
}

export default SearchLink