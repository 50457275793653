import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  TreeItem,
  TreeItemProps,
  treeItemClasses,
} from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginOutlined, MoreVertOutlined, PhoneIphone, QueryStats } from "@mui/icons-material";
import languageContentService from "../services/languageService";
import {
  LanguageItem,
  LanguageItemFormat,
} from "../interfaces/contentLanguage";
import { Stack } from "@mui/material";
import { useGlobalContext } from "../context/MyGlobalContext";
declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  bgColorForDarkMode?: string;
  color?: string;
  colorForDarkMode?: string;
  labelIcon: any;
  labelInfo?: string;
  labelText: string;
  active?: boolean;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
})) as unknown as typeof TreeItem;

const StyledTreeItem = React.forwardRef(function StyledTreeItem(
  props: StyledTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    active,
    ...other
  } = props;

  //   #fff3
  const styleProps = {
    // "--tree-view-bg-color": active ? "#fff3" : "transparent",
    backgroundColor: active ? "#fff3" : "transparent",
    margin: "5px",
    borderRadius: "8px",
    marginLeft: "16px",
  };

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 0.5,
            pr: 0,
          }}
        >
          <Box component={LabelIcon} color="white" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{
              fontWeight: "300",
              flexGrow: 1,
              color: "white",
              fontFamily: "Montserrat",
            }}
          >
            {labelText}
          </Typography>
        </Box>
      }
      style={styleProps}
      {...other}
      ref={ref}
    />
  );
});

export function toNestedSidebar(data: LanguageItem[], parentId = null) {
  return data.reduce((r: LanguageItemFormat[], e: any) => {
    const tags = e.tags ? JSON.parse(e.tags ?? "{}") : null;
    if (tags && parentId === tags?.parentId) {
      const object = { ...e, tags };
      const children = toNestedSidebar(data, e.id);

      if (children.length) {
        object.children = children;
      }

      r.push(object);
    }

    return r;
  }, []);
}
export default function TKGTreeView() {
  const location = useLocation();
  const navigate = useNavigate();
  const [dataSidebar, setDataSidebar] = React.useState<
    LanguageItemFormat[] | null
  >(null);
  const { setListDataLanguage} = useGlobalContext();
  const fetchDataSidebar = async () => {
    const response = await languageContentService.getListContent(1);
    if (response?.status === 200) {
      setListDataLanguage(response.data);
      localStorage.setItem("lang", JSON.stringify(response.data));
      setDataSidebar(
        toNestedSidebar(
          response.data.en.filter(
            (item: LanguageItem) =>
              JSON.parse(item.tags ?? "{}")?.type === "Sidebar"
          )
        )
      );
    }
  };

  React.useEffect(() => {
    fetchDataSidebar();
  }, []);

  const renderContent = (item: LanguageItemFormat) => {
    if (item.children) {
      return (
        <StyledTreeItem
          nodeId={String(item.id)}
          labelText={""}
          labelIcon={""}
          label={
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
             {item.tags.icon && <img
                alt="svg icon"
                src={`${process.env.REACT_APP_URL_DOMAIN}/${item.tags.icon}`}
                width={20}
              />}
              <Typography sx={{color: "#fff"}}>{item.content}</Typography>
            </Stack>
          }
        >
          {item.children.map((child) => renderContent(child))}
        </StyledTreeItem>
      );
    } else {
      return (
        <StyledTreeItem
          nodeId={String(item.id)}
          active={location.pathname === item.tags.url}
          labelText={""}
          labelIcon={""}
          label={
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
             {item.tags.icon && <img
                alt="svg icon"
                src={`${process.env.REACT_APP_URL_DOMAIN}/${item.tags.icon}`}
                width={20}
              />}
              <Typography sx={{color: "#fff"}}>{item.content}</Typography>
            </Stack>
          }
          onClick={() => navigate(item.tags.url)}
        />
      );
    }
  };
  return (
    <TreeView
      aria-label="TKG"
      defaultCollapseIcon={<ExpandMoreIcon style={{ color: "white" }} />}
      defaultExpandIcon={<ChevronRightIcon style={{ color: "white" }} />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {dataSidebar &&
        dataSidebar.map((item: LanguageItemFormat, index: number) =>
          renderContent(item)
        )}
       {dataSidebar && <><StyledTreeItem
          nodeId={"mobile-app"}
          active={location.pathname === "/mobile-app"}
          labelText={""}
          labelIcon={""}
          label={
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <PhoneIphone sx={{color: "#fff"}} />
              <Typography sx={{color: "#fff"}}>Mobile App</Typography>
            </Stack>
          }
          onClick={() => navigate("/mobile-app")}
        />
        <StyledTreeItem
          nodeId={"login-page"}
          active={location.pathname === "/login-page"}
          labelText={""}
          labelIcon={""}
          label={
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <LoginOutlined sx={{color: "#fff"}} />
              <Typography sx={{color: "#fff"}}>Login Page</Typography>
            </Stack>
          }
          onClick={() => navigate("/login-page")}
        />
        <StyledTreeItem
          nodeId={"others"}
          active={location.pathname === "/others-feature"}
          labelText={""}
          labelIcon={""}
          label={
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <MoreVertOutlined sx={{color: "#fff"}} />
              <Typography sx={{color: "#fff"}}>Others Feature</Typography>
            </Stack>
          }
          onClick={() => navigate("/others-feature")}
        /></>}
    </TreeView>
  );
}
