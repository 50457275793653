import { Box, Grid, Tab, Typography } from "@mui/material";
import React from "react";
import BookingDetails from "./BookingDetails";
import EditButton from "../../components/common/EditButton";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ByPaypalTab from "./paymentTabs/ByPaypalTab";
import WithBankTransferTab from "./paymentTabs/WithBankTransferTab";
import WithCreditCard from "./paymentTabs/WithCreditCard";

const PaymentDetails = () => {
  const [value, setValue] = React.useState<string>("paypal");

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" className="text-20 fw-600 py-30">
            How do you want to pay?{" "}
            <EditButton
              keyField="bookingPage_paymentDetails_title"
              contentField="How do you want to pay?"
            />
          </Typography>

          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    sx={{
                      fontSize: "14px",
                      textTransform: "capitalize",
                      bgcolor: value === "paypal" ? "#205682" : "#fff",
                      color: value === "paypal" ? "#fff !important" : "#205682",
                      border: "1px solid #205682",
                      borderRadius: "12px",
                      mr: 1
                      //   "&:hover": { bgcolor: "#205682", color: "#fff" },
                    }}
                    label={<Typography className="text-14 fw-600">Pay by Papal <EditButton keyField="bookingPage_paymentDetails_payByPaypal" contentField="Pay by Papal" /></Typography>}
                    value="paypal"
                  />
                  <Tab
                    label={<Typography className="text-14 fw-600">With Bank Transfer <EditButton keyField="bookingPage_paymentDetails_withBankTransfer" contentField="With Bank Transfer" /></Typography>}
                    value="bank"
                    sx={{
                      fontSize: "14px",
                      textTransform: "capitalize",
                      bgcolor: value === "bank" ? "#205682" : "#fff",
                      color: value === "bank" ? "#fff !important" : "#205682",
                      border: "1px solid #205682",
                      borderRadius: "12px",
                      mr: 1
                      //   "&:hover": { bgcolor: "#205682", color: "#fff" },
                    }}
                  />
                  <Tab
                    label={<Typography className="text-14 fw-600">With Credit Card <EditButton keyField="bookingPage_paymentDetails_withCreditCard" contentField="With Credit Card" /></Typography>}
                    value="credit-card"
                    sx={{
                      fontSize: "14px",
                      textTransform: "capitalize",
                      bgcolor: value === "credit-card" ? "#205682" : "#fff",
                      color: value === "credit-card" ? "#fff !important" : "#205682",
                      border: "1px solid #205682",
                      borderRadius: "12px",
                      //   "&:hover": { bgcolor: "#205682", color: "#fff" },
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value="paypal"><ByPaypalTab /></TabPanel>
              <TabPanel value="bank"><WithBankTransferTab /></TabPanel>
              <TabPanel value="credit-card"><WithCreditCard /></TabPanel>
            </TabContext>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <BookingDetails />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentDetails;
