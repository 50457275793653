import { Edit } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useCallback } from 'react'
import { useGlobalContext } from '../../context/MyGlobalContext';
import { checkDataLanguageMissing } from '../../utils';
import { ContentLanguageResponse } from '../../interfaces/contentLanguage';

interface Props {
    keyField: string;
    contentField?: string;
    color?: string;
}
const EditButton = ({keyField, contentField= "", color="#0000008a"}: Props) => {
    const { setOpenCreateLanguage, setKeyLanguageContent, listDataLanguage } = useGlobalContext();        

const renderColor = useCallback(
    (listData: ContentLanguageResponse) => {
        const dataCheck = checkDataLanguageMissing(keyField, listData)        
        if(dataCheck.fr && dataCheck.cn) {
            return "#0000008a"    
        }else if(dataCheck.fr && !dataCheck.cn) {
            return "green"
    
        } else if(!dataCheck.fr && dataCheck.cn) {
            return "orange"
        } else if (!dataCheck.fr && !dataCheck.cn) {
            return "red"
        } 
    },
  [listDataLanguage],
)

return (
    <IconButton size='small' onClick={() => {setOpenCreateLanguage(true); setKeyLanguageContent({
        section: keyField,
        content: contentField,
    })}}>
        <Edit sx={{color: listDataLanguage ? renderColor(listDataLanguage) : color, fontSize: "18px"}} />
    </IconButton>
  )
}

export default EditButton