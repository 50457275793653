import React from 'react'
import WrapperCard from '../../../../components/common/WrapperCard'
import { Box, Grid, Typography } from '@mui/material'
import EditButton from '../../../../components/common/EditButton'
import TextFieldCommon from '../../../../components/common/TextFieldCommon'
import AutoCompleteCommon from '../../../../components/common/AutoCompleteCommon'

const HighlightFeatureBelowSearchBox = () => {
  return (
    <WrapperCard
            cardTitleContent="Highlight Features Below Search Box"
            cardTitleKey={
              "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_titleHeader"
            }
            children={
              <Box p={3} py={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography className="text-14 mb-10">
                      Characters{" "}
                      <EditButton
                        keyField={
                          "checkLength"
                        }
                        contentField="Characters"
                      />
                      : 41/40
                    </Typography>
                    <TextFieldCommon
                      keyField={
                        "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_title"
                      }
                      contentField="Title"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="text-14 mb-10">
                      Data Limit{" "}
                      <EditButton
                        keyField={
                          "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_countries_dataLimit"
                        }
                        contentField="Data Limit"
                      />
                      : 6 <EditButton
                        keyField={
                          "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_countries_dataLimit_unit"
                        }
                        contentField="Countries"
                      />
                    </Typography>
                    <AutoCompleteCommon
                      keyField={
                        "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_countries"
                      }
                      contentField="Countries"
                      defaultData={[]}
                    />
                  </Grid>
                </Grid>
              </Box>
            }
          />
  )
}

export default HighlightFeatureBelowSearchBox