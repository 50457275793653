import { Box, Table, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import TableHeader from '../../components/Table/TableHeader'
import EditButton from '../../components/common/EditButton'
import ButtonCommon from '../../components/common/ButtonCommon'

const TablePaymentDetails = () => {
  return (
    <div>
         <Table className="table">
            <TableHead>
              <TableHeader>
              <TableCell className="text-center text-dark fw-600">
              Payment Details{" "}
              <EditButton keyField="manage_booking_payment_detail" contentField="Payment Details" />
            </TableCell>
         
            <TableCell className="text-center text-dark fw-600">
            Amount <EditButton keyField="manage_booking_payment_amount" contentField="Amount" />
            </TableCell>
          
            <TableCell className="text-center text-dark fw-600">
            Due Date
              <EditButton keyField="manage_booking_payment_due_date" contentField="Due Date" />
            </TableCell>
            <TableCell className="text-center text-dark fw-600">
            
            </TableCell>
              </TableHeader>
            </TableHead>
            <TableRow className="item-hover">
              <TableCell align="center">
              Total  <EditButton
                  keyField="booking_details_priceTable_header_priceField"
                  contentField="Total"
                />
              </TableCell>
             
              <TableCell align="center">0</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
              <div className="d-inline-block">
                <ButtonCommon
                  bgColor="bg-orange"
                  textColor="text-white"
                  borderRadius="rounded-8"
                  keyField="payFullButton"
                  contentField="Pay Full"
                />
              </div>
              </TableCell>

            </TableRow>
            <TableRow className="item-hover">
              <TableCell align="center">
              1st <EditButton
                  keyField="ranking_1st"
                  contentField="1st"
                /> Deposit  <EditButton
                  keyField="deposit"
                  contentField="Deposit"
                />
              </TableCell>
             
              <TableCell align="center">0</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
              <div className="d-inline-block">
                <ButtonCommon
                  bgColor="bg-orange"
                  textColor="text-white"
                  borderRadius="rounded-8"
                  keyField="payFullButton"
                  contentField="Pay Full"
                />
              </div>
              </TableCell>

            </TableRow>
            <TableRow className="item-hover">
              <TableCell align="center">
              2nd  <EditButton
                  keyField="ranking_2nd"
                  contentField="2nd"
                /> Deposit
              </TableCell>
             
              <TableCell align="center">0</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
              <div className="d-inline-block">
                <ButtonCommon
                  bgColor="bg-orange"
                  textColor="text-white"
                  borderRadius="rounded-8"
                  keyField="payFullButton"
                  contentField="Pay Full"
                />
              </div>
              </TableCell>

            </TableRow>
            <TableRow className="item-hover">
              <TableCell align="center">
              3rd  <EditButton
                  keyField="ranking_3rd"
                  contentField="3rd"
                /> Deposit
              </TableCell>
             
              <TableCell align="center">0</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
              <div className="d-inline-block">
                <ButtonCommon
                  bgColor="bg-orange"
                  textColor="text-white"
                  borderRadius="rounded-8"
                  keyField="payNowButton"
                  contentField="Pay Now"
                />
              </div>
              </TableCell>

            </TableRow>
            <TableRow className="item-hover">
              <TableCell align="center">
              th  <EditButton
                  keyField="ranking_th"
                  contentField="th"
                />Deposit
              </TableCell>
             
              <TableCell align="center">0</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
              <div className="d-inline-block">
                <ButtonCommon
                  bgColor="bg-orange"
                  textColor="text-white"
                  borderRadius="rounded-8"
                  keyField="payFullButton"
                  contentField="Pay Full"
                />
              </div>
              </TableCell>

            </TableRow>
            <TableRow className="item-hover">
              <TableCell align="center">
              Balance  <EditButton
                  keyField="balance"
                  contentField="Balance"
                />
              </TableCell>
             
              <TableCell align="center">0</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">
              <div className="d-inline-block">
                <ButtonCommon
                  bgColor="bg-orange"
                  textColor="text-white"
                  borderRadius="rounded-8"
                  keyField="payFullButton"
                  contentField="Pay Full"
                />
              </div>
              </TableCell>

            </TableRow>
           
          </Table>
    </div>
  )
}

export default TablePaymentDetails