import { Box, Tab, Typography } from "@mui/material";
import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EditButton from "../../components/common/EditButton";
import LoginModalTab from "./tabs-content/LoginModal";
import AgentLoginTab from "./tabs-content/AgentLogin";
import PassengerLoginTab from "./tabs-content/PassengerLogin";
import RegistrationPassengerTab from "./tabs-content/RegistrationPassengerForm";
import RegistrationAgentTab from "./tabs-content/RegistrationAgentForm";
import ForgetPasswordForm from "./tabs-content/ForgetPasswordForm";

export default function LoginPage() {
  const [value, setValue] = React.useState("passenger-registration-form");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ bgcolor: "background.paper", borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
           
              <Tab label={
                <Typography className="text-14 mb-10">
                 Passenger Registration <EditButton
                    keyField={
                      "loginPage_passengerRegistrationTab"
                    }
                    contentField="Passenger Registration"
                  />
                </Typography>
              } value={"passenger-registration-form"} />
               <Tab label={
                <Typography className="text-14 mb-10">
                 Agent Registration <EditButton
                    keyField={
                      "loginPage_agentRegistrationTab"
                    }
                    contentField="Agent Registration"
                  />
                </Typography>
              } value={"agent-registration-form"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Login Modal
                </Typography>
              } value={"login-modal"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Agent Login{" "}
                  <EditButton
                    keyField={
                      "loginPage_agentLoginTab"
                    }
                    contentField="Agent Login"
                  />
                </Typography>
              } value={"agent-login"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Passenger Login{" "}
                  <EditButton
                    keyField={
                      "loginPage_passengerLoginTab"
                    }
                    contentField="Passenger Login"
                  />
                </Typography>
              } value={"passenger-login"} />
           <Tab label={
                <Typography className="text-14 mb-10">
                  Forget Password{" "}
                  <EditButton
                    keyField={
                      "loginPage_forgetPasswordTab"
                    }
                    contentField="Forget Password"
                  />
                </Typography>
              } value={"forget-password"} />
          </TabList>
        </Box>
        <TabPanel value="passenger-registration-form" sx={{ px: 0 }}>
          <RegistrationPassengerTab />
        </TabPanel>
        <TabPanel value="agent-registration-form" sx={{ px: 0 }}>
          <RegistrationAgentTab />
        </TabPanel>
        <TabPanel value="login-modal" sx={{ px: 0 }}>
          <LoginModalTab />
        </TabPanel>
        <TabPanel value="agent-login" sx={{ px: 0 }}>
          <AgentLoginTab />
        </TabPanel>
        <TabPanel value="passenger-login" sx={{ px: 0 }}>
          <PassengerLoginTab />
        </TabPanel>
        <TabPanel value="forget-password" sx={{ px: 0 }}>
          <ForgetPasswordForm />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
