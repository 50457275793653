import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import SampleImage from "../../../../media/images/example-upload.jpg";
import {
  BusinessCenterSharp,
  CheckBox,
  Delete,
  People,
} from "@mui/icons-material";
import EditButton from "../../../components/common/EditButton";
import ButtonCommon from "../../../components/common/ButtonCommon";
import TextFieldCommon from "../../../components/common/TextFieldCommon";
const ForgetPasswordForm = () => {
  return (
    <>
      <Box>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12} md={4} justifyContent={"center"}>
            <Paper sx={{ p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-22 fw-600 ">
                    Forget Password{" "}
                    <EditButton
                      keyField="loginPage_forgetPassword_title"
                      contentField="Forget Password"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="loginPage_forgetPassword_emailField"
                    contentField="Email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Back to Login{" "}
                    <EditButton
                      keyField="loginPage_forgetPassword_backToLogin"
                      contentField="Back to Login"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box className="d-flex align-center">
                    <CheckBox sx={{ mr: "5px" }} />
                    I'm not a robot{" "}
                    <EditButton
                      keyField={"loginPage_forgetPassword_labelCheckNotRobot"}
                      contentField="I'm not a robot"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <ButtonCommon keyField="submitButton" contentField="Submit" />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ForgetPasswordForm;
