import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Clock from "react-live-clock";

import {
  Autocomplete,
  Backdrop,
  Badge,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Logo from "../media/images/logo-tkg.png";
import userService from "../services/userService";
import { responseSendSignInCode } from "../interfaces/authentication";
import { useGlobalContext } from "../context/MyGlobalContext";
import {
  Brightness1,
  Language,
  Notifications,
  Search,
} from "@mui/icons-material";
import { useUrlSearchParams } from "use-url-search-params";
import MainSiteHeader from "./MainSiteHeader";
import FormContentLanguage from "../components/common/FormContentLanguage";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18t from "../config-translation";
import FormContentLanguageSwitch from "../components/common/FormContentLanguageSwitch";
import settingService from "../services/setting";
import { makeSlugKey, makeTitle } from "../utils";
import { CompanyData } from "../interfaces/settings";
import EditButton from "../components/common/EditButton";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  paddingBottom: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  textAlign: "center",
}));

const MainSiteLayoutInner = ({ memuList }: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const {
    openCreateLanguage,
    openCreateSwitchLanguage,
    setCompanyLogo,
    setCompanyName,
  } = useGlobalContext();
  const [params] = useUrlSearchParams();
  const { t, i18n } = useTranslation();
  let location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const token = localStorage.getItem("accessToken");

  const getCurrentUser = async () => {
    try {
      let [responseUser] = await Promise.all([userService.getCurrent()]);

      // const response = await userService.getCurrent();
      let authData: responseSendSignInCode = responseUser.data;
      localStorage.setItem("user", JSON.stringify(authData));
      setIsLoading(false);
      navigate("/");
    } catch (e: any) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("timeZone");
      navigate("/");
    }
  };
  const getGeneralData = async () => {
    try {
      const companySetting = await settingService.companySettings.getCompany();

      if (companySetting?.status === 200 && companySetting?.data?.length > 0) {
        const obj = companySetting.data.reduce(
          (o: any, cur: CompanyData) => ({ ...o, [cur.key]: cur.value }),
          {}
        );

        localStorage.setItem("companyData", JSON.stringify(obj));
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    const companyData = localStorage.getItem("companyData");
    if (!companyData) {
      getGeneralData();
    }
  }, []);

  React.useEffect(() => {
    if (params?.token && params?.companyCode) {
      localStorage.setItem("accessToken", `${params?.token}`);
      localStorage.setItem("companyCode", `${params?.companyCode}`);
      localStorage.setItem("timeZone", `${params?.timeZone}`);
      getCurrentUser();
    }
  }, [params]);

  React.useEffect(() => {
    if (token) {
      setIsLoading(false);
    }
  }, [token]);
  const listlang = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "French",
      value: "fr",
    },
    {
      label: "Chinese",
      value: "cn",
    },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      {isLoading ? (
        <Backdrop
          sx={{
            color: "#fff",
            background: "white",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress style={{ color: "black" }} />
        </Backdrop>
      ) : (
        <>
          <CssBaseline />
          <MainSiteHeader {...{ open, handleDrawerOpen }} />
          <Drawer
            PaperProps={{
              sx: {
                background: "#283c4c",
                color: "white",
              },
            }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <Link to="/" style={{ margin: "0 auto", display: "block" }}>
                <img src={Logo} alt="" style={{ width: "200px" }} />
              </Link>
            </DrawerHeader>
            <Divider />
            {memuList}
          </Drawer>
          <Main open={open} style={{ height: "100%" }}>
            <Box
              className="mt-47 rounded-8 mb-5"
              sx={{
                color: "#000000",
                fontSize: 16,
                fontWeight: 500,
                boxShadow: "unset",
                display: "flex",
                justifyContent: location.pathname.split("/").pop() ? "space-between" : "flex-end",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {location.pathname.split("/").pop() && <Box>
                <Typography className="text-24 fw-500">
                  {makeTitle(location.pathname.split("/").pop() ?? "")} {makeTitle(location.pathname.split("/").pop() ?? "") && <EditButton keyField={`page_header_${makeSlugKey(makeTitle(location.pathname.split("/").pop() ?? ""))}`}
            contentField={makeTitle(location.pathname.split("/").pop() ?? "")} />}
                </Typography>
              </Box>}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              >
                <IconButton className="mr-10" color="inherit">
                  <Search />
                </IconButton>

                <Clock
                  format="h:mm A"
                  interval={1000}
                  ticking={true}
                  style={{ fontSize: "23px" }}
                  timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                />

                <IconButton
                  color="inherit"
                  className="ml-10"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <Badge badgeContent={0} color="error">
                    <Notifications sx={{ color: "#174D75" }} />
                  </Badge>
                </IconButton>
              </Box>
            </Box>
            <Box component={"div"} sx={{ height: "100%", mb: "180px" }}>
              <Outlet /> 
            </Box>
              <Stack alignItems={"flex-end"} sx={{
                mt: 3,
                position: "fixed",
                left: 280,
                bottom: 90,
                width: "calc(100% - 280px)",
                textAlign: "center",
                
              }}>
                <Box className="flex-center gap-10 display-inline-block bg-white px-20 py-5">
                  <Typography className="text-14 fw-600">Translate Color Code:</Typography>
                  <Box className="flex-center gap-15">
                  <Typography className="text-12 flex-center"><Brightness1 sx={{mr: "5px", fontSize: "12px", color: "red"}} /> Not Yet</Typography>
                  <Typography className="text-12 flex-center"><Brightness1 sx={{mr: "5px", fontSize: "12px", color: "green"}} /> French</Typography>

                  <Typography className="text-12 flex-center"><Brightness1 sx={{mr: "5px", fontSize: "12px", color: "orange"}} /> Chinese</Typography>
                  <Typography className="text-12 flex-center"><Brightness1 sx={{mr: "5px", fontSize: "12px", color: "#0000008a"}} />Done</Typography>


                  </Box>
                </Box>
              </Stack>
            <Box
              sx={{
                bgcolor: "#fff",
                mt: 3,
                position: "fixed",
                left: 280,
                bottom: 0,
                width: "calc(100% - 280px)",
                height: "90px",
                textAlign: "center",
                py: 2,
                px: 4,
                borderTop: "1px solid #d9d9d9",
              }}
            >
            
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    color="#000"
                    variant="subtitle1"
                    fontSize={"16px"}
                    fontWeight={600}
                  >
                    {`Help Desk: +61 8 7082 5321`}
                  </Typography>
                </Box>

                <Box display={"flex"} alignItems={"center"}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    spacing={"5px"}
                    mr={"25px"}
                  >
                    <Typography
                      color="#000"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`User Guide`}
                    </Typography>
                    <Typography
                      color="#000"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`|`}
                    </Typography>
                    <Typography
                      color="#000"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`FAQs`}
                    </Typography>
                    <Typography
                      color="#000"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`|`}
                    </Typography>
                    <Typography
                      color="#000"
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                    >
                      {`Support`}
                    </Typography>
                  </Stack>

                  <Box display={"flex"} alignItems={"center"}>
                    <Language sx={{ mr: "5px", color: "#000" }} />
                    <Autocomplete
                      sx={{ width: "100px" }}
                      options={listlang}
                      disableClearable
                      defaultValue={listlang[0]}
                      onChange={(e, value) => {
                        if (value) i18n.changeLanguage(value.value);
                      }}
                      getOptionLabel={(option) => option.label}
                      id="clear-on-escape"
                      clearOnEscape
                      renderInput={(params) => (
                        <TextField {...params} label="" variant="standard" />
                      )}
                    />
                    {/* <ExpandMore sx={{ ml: "5px", color: "#000" }} /> */}
                  </Box>
                </Box>
              </Box>
              <Typography color="#000" variant="subtitle1" fontSize={"14px"}>
                {`© ${new Date().getFullYear()} - TKG Platform Technology`}
              </Typography>
            </Box>
          </Main>
        </>
      )}
      {openCreateLanguage && <FormContentLanguage />}
      {openCreateSwitchLanguage && <FormContentLanguageSwitch />}
    </Box>
  );
};

const MainSiteLayout = ({ memuList }: any) => {
  return (
    <I18nextProvider i18n={i18t}>
      <MainSiteLayoutInner memuList={memuList} />
    </I18nextProvider>
  );
};
export default MainSiteLayout;
