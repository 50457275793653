import { Box, Grid, Paper, Table, TableHead, Typography } from '@mui/material'
import React from 'react'
import ButtonCommon from '../../../../components/common/ButtonCommon';
import { Add } from '@mui/icons-material';
import TextFieldCommon from '../../../../components/common/TextFieldCommon';
import AutoCompleteCommon from '../../../../components/common/AutoCompleteCommon';
import EditButton from '../../../../components/common/EditButton';
import TableHeading from './TableHeading';

const ListInspiration = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
                <Box sx={{boxShadow: 1}} className="rounded-10 py-20 flex-center">
                   <ButtonCommon startIcon={<Add />} keyField='addNewButton' contentField='Add New'
                   /> 
                </Box>
              
            </Grid>
            <Grid item xs={12} md={10}>
            <Box sx={{boxShadow: 1}} className="rounded-10 pt-20 pb-12 px-20 flex-center">

              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextFieldCommon keyField="navigationMenu_inspiration_list_filter_keyWordField" contentField="keyword"/>
                </Grid>
                <Grid item xs={12} md={3}>
                  <AutoCompleteCommon keyField="navigationMenu_inspiration_list_filter_typeField" contentField="Type" defaultData={[]} />
                </Grid>
                <Grid item xs={12} md={3}>
                <AutoCompleteCommon keyField="navigationMenu_inspiration_list_filter_countryField" contentField="Country" defaultData={[]} />

                </Grid>
                <Grid item xs={12} md={3}>
                <AutoCompleteCommon keyField="navigationMenu_inspiration_list_filter_tourTypeField" contentField="Tour Type" defaultData={[]} />

                </Grid>

              </Grid>
              </Box>
            </Grid>
          </Grid>
          </Grid>
          <Grid item xs={12}>
           <Grid container spacing={2}>
            <Grid item xs={12}>
                Show <EditButton keyField="navigationMenu_inspiration_list_table_pagination_show" contentField="Show" /> <Typography className='text-14 border-full py-5 px-10 rounded-8' component={"span"}>10</Typography> of <EditButton keyField="navigationMenu_inspiration_list_table_pagination_of" contentField="of" /> <b>3</b>
            </Grid>
            <Grid item xs={12}>
            <Table className="table">
                <TableHead><TableHeading /></TableHead>
                </Table>
            </Grid>
           </Grid>
          </Grid>
        </Grid>
    </Paper>
  )
}

export default ListInspiration