import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ContentLanguageForm, ContentLanguagePayload, ContentLanguageSwitchForm, DetailContentLanguageResponse } from "../../interfaces/contentLanguage";
import languageContentService from "../../services/languageService";
import { makeid } from "../../utils";
import { useGlobalContext } from "../../context/MyGlobalContext";
import validationMessage from "../../constants/validationMessage";

const validationSchema = yup.object().shape({
  sectionChecked: yup.string().required(validationMessage.required),
  sectionUnChecked: yup.string().required(validationMessage.required),
  checkedContents: yup.array(
    yup.object().shape({
      lang: yup.string().required(validationMessage.required),
      content: yup
        .string()
        .nullable()
        .transform((v) => (v === undefined ? null : v)),
    })
  ),
  unCheckedContents: yup.array(
    yup.object().shape({
      lang: yup.string().required(validationMessage.required),
      content: yup
        .string()
        .nullable()
        .transform((v) => (v === undefined ? null : v)),
    })
  ),
  tag: yup
    .string()
    .nullable()
    .transform((val) => (val === undefined ? null : val)),
});

const FormContentLanguageSwitch = () => {
  const { setOpenCreateSwitchLanguage, keySwitchLanguageContent, openCreateSwitchLanguage } = useGlobalContext();
  const [contentDataDetailChecked, setContentDataDetailChecked] =
    useState<DetailContentLanguageResponse | null>(null);
  const [contentDataDetailUnChecked, setContentDataDetailUnChecked] =
    useState<DetailContentLanguageResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [isEditBtn, setIsEditBtn] = useState(false);

  useEffect(() => {
    if (contentDataDetailChecked?.en || contentDataDetailChecked?.fr || contentDataDetailChecked?.cn || contentDataDetailUnChecked?.en || contentDataDetailUnChecked?.fr || contentDataDetailUnChecked?.cn) {
      setIsEditBtn(true);
    } else {
      setIsEditBtn(false);
    }
  }, [contentDataDetailChecked, contentDataDetailUnChecked]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContentLanguageSwitchForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const { fields: checkedFields } = useFieldArray({
    control,
    name: "checkedContents",
  });
  const { fields: unCheckedFields } = useFieldArray({
    control,
    name: "unCheckedContents",
  });

  const getDataDetailByKey = async (sectionChecked: string, sectionUnChecked: string) => {
    setLoading(true)
    const [responseChecked, responseUnChecked] = await Promise.all([
      languageContentService.getContentByKey(sectionChecked),
      languageContentService.getContentByKey(sectionUnChecked),
    ])
    if (responseChecked?.status === 200 || responseUnChecked?.status === 200) {
      const dataChecked: DetailContentLanguageResponse = responseChecked?.data;
      const dataUnChecked: DetailContentLanguageResponse = responseUnChecked?.data;
      setContentDataDetailChecked(dataChecked);
      setContentDataDetailUnChecked(dataUnChecked);
      const initData = {
        sectionChecked: keySwitchLanguageContent.sectionChecked,
        sectionUnChecked: keySwitchLanguageContent.sectionUnChecked,
        checkedContents: [
          {
            lang: "en",
            content: dataChecked?.en?.content ?? keySwitchLanguageContent.contentChecked,
          },
          {
            lang: "fr",
            content: dataChecked?.fr?.content ?? "",
          },
          {
            lang: "cn",
            content: dataChecked?.cn?.content ?? "",
          },
        ],
        unCheckedContents: [
          {
            lang: "en",
            content: dataUnChecked?.en?.content ?? keySwitchLanguageContent.contentUnChecked,
          },
          {
            lang: "fr",
            content: dataUnChecked?.fr?.content ?? "",
          },
          {
            lang: "cn",
            content: dataUnChecked?.cn?.content ?? "",
          },
        ],
      };
      reset(initData);
    }
    setLoading(false)
  };

  useEffect(() => {
      getDataDetailByKey(keySwitchLanguageContent.sectionChecked, keySwitchLanguageContent.sectionUnChecked)
  }, []);


  const submit: SubmitHandler<ContentLanguageSwitchForm> = async (data) => {
    console.log(data);
    
    if((contentDataDetailChecked?.en || contentDataDetailChecked?.fr || contentDataDetailChecked?.cn) && (contentDataDetailUnChecked?.en || contentDataDetailUnChecked?.fr || contentDataDetailUnChecked?.cn)){
      const payloadChecked: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.sectionChecked,        
        contents: data?.checkedContents
          ? data?.checkedContents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const payloadUnchecked: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.sectionUnChecked,        
        contents: data?.unCheckedContents
          ? data?.unCheckedContents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const [responseChecked, responseUnchecked] = await Promise.all([
        languageContentService.updateContentLanguage( keySwitchLanguageContent.sectionChecked, payloadChecked),
        languageContentService.updateContentLanguage( keySwitchLanguageContent.sectionUnChecked, payloadUnchecked),
      ])
      if (responseChecked?.status === 200 || responseUnchecked?.status === 200) {
        Swal.fire({
          title: `Update Language Successfully!`,
          showConfirmButton: true,
          icon: "success",
          position: "center",
        });
        setOpenCreateSwitchLanguage(false);
      }
    } else if((contentDataDetailChecked?.en || contentDataDetailChecked?.fr || contentDataDetailChecked?.cn) && (!contentDataDetailUnChecked?.en && !contentDataDetailUnChecked?.fr && !contentDataDetailUnChecked?.cn)) {
      const payloadChecked: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.sectionChecked,        
        contents: data?.checkedContents
          ? data?.checkedContents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const payloadUnchecked: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.sectionUnChecked,        
        contents: data?.unCheckedContents
          ? data?.unCheckedContents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const [responseChecked, responseUnchecked] = await Promise.all([
        languageContentService.updateContentLanguage( keySwitchLanguageContent.sectionChecked, payloadChecked),
        languageContentService.storeContentLanguage(payloadUnchecked),
      ])
      if (responseChecked?.status === 200 || responseUnchecked?.status === 200) {
        Swal.fire({
          title: `Update Language Successfully!`,
          showConfirmButton: true,
          icon: "success",
          position: "center",
        });
        setOpenCreateSwitchLanguage(false);
      }
    } else if((contentDataDetailUnChecked?.en || contentDataDetailUnChecked?.fr || contentDataDetailUnChecked?.cn) && (!contentDataDetailChecked?.en && !contentDataDetailChecked?.fr && !contentDataDetailChecked?.cn)) {
      const payloadChecked: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.sectionChecked,        
        contents: data?.checkedContents
          ? data?.checkedContents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const payloadUnchecked: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.sectionUnChecked,        
        contents: data?.unCheckedContents
          ? data?.unCheckedContents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const [responseChecked, responseUnchecked] = await Promise.all([
        languageContentService.storeContentLanguage(payloadChecked),
        languageContentService.updateContentLanguage( keySwitchLanguageContent.sectionUnChecked, payloadUnchecked),
      ])
      if (responseChecked?.status === 200 || responseUnchecked?.status === 200) {
        Swal.fire({
          title: `Update Language Successfully!`,
          showConfirmButton: true,
          icon: "success",
          position: "center",
        });
        setOpenCreateSwitchLanguage(false);
      }
    } else if((!contentDataDetailChecked?.en && !contentDataDetailChecked?.fr && !contentDataDetailChecked?.cn) && (!contentDataDetailUnChecked?.en && !contentDataDetailUnChecked?.fr && !contentDataDetailUnChecked?.cn)) {
      const payloadChecked: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.sectionChecked,        
        contents: data?.checkedContents
          ? data?.checkedContents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const payloadUnchecked: ContentLanguagePayload = {
        mainSiteId: 1,
        section: data.sectionUnChecked,        
        contents: data?.unCheckedContents
          ? data?.unCheckedContents.map((item) => {
              return {
                lang: item.lang,
                content: item.content,
              };
            })
          : [],
          tags: JSON.stringify({
            type: "FilterZone",
            parentId: null,
            level: 1,
            url: "",
          })
      };
      const [responseChecked, responseUnchecked] = await Promise.all([
        languageContentService.storeContentLanguage(payloadChecked),
        languageContentService.storeContentLanguage(payloadUnchecked),
      ])
      if (responseChecked?.status === 200 || responseUnchecked?.status === 200) {
        Swal.fire({
          title: `Create Language Successfully!`,
          showConfirmButton: true,
          icon: "success",
          position: "center",
        });
        setOpenCreateSwitchLanguage(false);
      }
    }
   
  };

  const error: SubmitErrorHandler<ContentLanguageSwitchForm> = (errors) => {
    console.log(errors);
  }
  const renderTitleLang = (lang: string) => {
    switch (lang) {
      case "en":
        return "English";
      case "fr":
        return "French";
      case "cn":
        return "Chinese";
      default:
        return "";
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={openCreateSwitchLanguage}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: 2,
          bgcolor: "#103551",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        {(contentDataDetailChecked?.en || contentDataDetailChecked?.fr || contentDataDetailChecked?.cn || contentDataDetailUnChecked?.en || contentDataDetailUnChecked?.fr || contentDataDetailUnChecked?.cn)
          ? `Update Content of Switch Button`
          : `Add Content of Switch Button`}

        <IconButton aria-label="close" onClick={() => setOpenCreateSwitchLanguage(false)}>
          <Close sx={{ color: "#fff" }} />
        </IconButton>
      </DialogTitle>
      <form id={`form-handle-task`} onSubmit={handleSubmit(submit, error)}>
        <DialogContent>
          <Box>
            {loading ? (
              <Stack
                sx={{ width: "100%" }}
                spacing={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Stack>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                {checkedFields.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <TextField
                      className={isEditBtn ? "bg-readonly" : ""}
                      {...register(`checkedContents.${index}.content`)}
                      label={renderTitleLang(item.lang)}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ readOnly: isEditBtn }}
                      error={errors.checkedContents?.[index]?.content !== undefined}
                      helperText={
                        errors.checkedContents?.[index]?.content !== undefined
                          ? validationMessage.required
                          : ""
                      }
                    />
                  </Grid>
                ))}
                </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                {unCheckedFields.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <TextField
                      className={isEditBtn ? "bg-readonly" : ""}
                      {...register(`unCheckedContents.${index}.content`)}
                      label={renderTitleLang(item.lang)}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ readOnly: isEditBtn }}
                      error={errors.unCheckedContents?.[index]?.content !== undefined}
                      helperText={
                        errors.unCheckedContents?.[index]?.content !== undefined
                          ? validationMessage.required
                          : ""
                      }
                    />
                  </Grid>
                ))}
                </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isEditBtn ? (
            <Button variant={"contained"} onClick={() => setIsEditBtn(false)}>
              Edit
            </Button>
          ) : (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Button
                variant={"contained"}
                onClick={() => setIsEditBtn(true)}
                sx={{
                  bgcolor: "#808080",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant={"contained"}>
                Submit
              </Button>
            </Stack>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormContentLanguageSwitch;
