import { Box, Button, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../../components/common/EditButton'
import TableHeader from '../../../components/Table/TableHeader'

const ByPaypalTab = () => {
  return (
    <Box>
        <Typography className='text-14'>All transactions are secure and encrypted with PayPal Checkout <EditButton keyField="bookingPage_paymentDetails_byPaypal_warrant" contentField="All transactions are secure and encrypted with PayPal Checkout" /></Typography>
        <Typography className='text-14'><strong>*Note: <EditButton keyField="bookingPage_paymentDetails_note" contentField="*Note" /></strong> If you make payment by PayPal, 0% surcharge fee will apply. <EditButton keyField="bookingPage_paymentDetails_byPaypal_surchargeInfo" contentField="If you make payment by PayPal, 0% surcharge fee will apply." /></Typography>

        <Box className="mt-20">
          <Typography className="text-16 mb-20">
            <strong>Room</strong>
            <EditButton
              keyField={"booking_details_pricingInfo_room"}
              contentField="Room"
            />{" "}
            1: 1 Adult(s){" "}
            <EditButton
                  keyField="booking_details_priceTable_row_adultField"
                  contentField="Adult(s)"
                />
            , 1 Child(ren){" "}
            <EditButton
                  keyField="booking_details_priceTable_row_childField"
                  contentField="Child(ren)"
                />
          </Typography>
          <Table className="table">
            <TableHead>
              <TableHeader>
                <TableCell colSpan={4} className="bg-tkg-blue text-center text-white">
                  $0 (USD)
                </TableCell>
              </TableHeader>
            </TableHead>
            <TableRow className="item-hover">
              <TableCell align="left">
              </TableCell>
              <TableCell align="left">Price  <EditButton
                  keyField="booking_details_priceTable_header_priceField"
                  contentField="Price"
                /></TableCell>
              <TableCell align="left">Due Date <EditButton
                  keyField="booking_details_priceTable_header_dueDateField"
                  contentField="Due Date"
                /></TableCell>
              <TableCell align="center">Action <EditButton
                  keyField="booking_details_priceTable_header_actionField"
                  contentField="Action"
                /></TableCell>
            </TableRow>
            <TableRow className="item-hover">
              <TableCell align="left">
                Full <EditButton
                  keyField="booking_details_priceTable_row_fullField"
                  contentField="Full"
                />
              </TableCell>
              <TableCell align="left">$2,098 USD</TableCell>
              <TableCell align="left">12 Jul, 2024</TableCell>
              <TableCell align="left"><Box className="text-14 text-center p-8 px-20 rounded-12" sx={{ cursor: "pointer", bgcolor: "#ffc439"}}><b style={{background: "#003087]"}}>Pay</b><b style={{background: "#009cde]"}}>Pal</b></Box></TableCell>
            </TableRow>
          </Table>
        </Box>
    </Box>
  )
}

export default ByPaypalTab