import React from 'react'
import MetaSEO from './MetaSEO'
import ContentItem from './ContentItem'
import { Stack } from '@mui/material'
import ButtonCommon from '../../components/common/ButtonCommon'

const FeatureServicePage = () => {
  return (
    <>
       <MetaSEO /> 
       <ContentItem />
       <Stack alignItems={"flex-end"}>
      <ButtonCommon keyField="saveButton" contentField="Save" />
      </Stack>
    </>
  )
}

export default FeatureServicePage