import TableCell from "@mui/material/TableCell";
import TableHeader from "../../../components/Table/TableHeader";
import EditButton from "../../../components/common/EditButton";

const TableHeading = () => {
  return (
    <TableHeader>
      <TableCell className="bg-slight-blue text-left">Avatar <EditButton keyField="homePage_customerReview_list_table_header_avatar" contentField="Avatar" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Country <EditButton keyField="homePage_customerReview_list_table_header_country" contentField="Country" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Full Name<EditButton keyField="homePage_customerReview_list_table_header_fullName" contentField="Full Name" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Review <EditButton keyField="homePage_customerReview_list_table_header_review" contentField="Review" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Actions <EditButton keyField="homePage_customerReview_list_table_header_actions" contentField="Actions" /></TableCell>
    </TableHeader>
  );
};

export default TableHeading;
