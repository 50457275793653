import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import ButtonCommon from "../../components/common/ButtonCommon";
import BrochureSample1 from "../../media/images/brochure1.png";
import BrochureSample2 from "../../media/images/brochure2.jpg";

import { CloudUpload, Delete, Edit } from "@mui/icons-material";
import UploadFileCommon from "../../components/common/UploadFileCommon";
import UploadImageAndSampleCommon from "../../components/common/UploadImageAndSampleCommon";
import SelectColorCommon from "../../components/common/SelectColorCommon";

const CardAdjustments = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
      <Typography className="text-16 fw-600 text-tkg-blue mb-20">
        Card Adjustments{" "}
        <EditButton
          keyField={"homepage_knowMore_inspirationByUs_cardAdjustments_header"}
          contentField="Card Adjustments"
        />
      </Typography>
      <Stack spacing={5}>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            41/25
          </Typography>
          <TextFieldCommon
            keyField={
              "titleField"
            }
            contentField="Title"
          />
        </Box>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            157/55
          </Typography>

          <TextFieldCommon
            keyField={
              "bodyContentField"
            }
            contentField="Subtitle"
          />
        </Box>

        <Box>
          <SelectColorCommon sectionKey="homepage_knowMore_inspirationByUs_cardAdjustments" buttonContent="Learn More" />
        </Box>

        <Box>
         <UploadImageAndSampleCommon />
        </Box>
        <Stack alignItems={"flex-end"}>
      <ButtonCommon keyField="saveButton" contentField="Save" />
      </Stack>
      </Stack>
    </Paper>
  );
};

export default CardAdjustments;
