import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Facebook, Lock, YouTube } from "@mui/icons-material";
import ButtonCommon from "../../components/common/ButtonCommon";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import UploadIconAndSampleCommon from "../../components/common/UploadIconAndSampleCommon";
import AmexMethod from "../../media/images/amex.png";
import MastercardMethod from "../../media/images/mastercard.png";
import VisaMethod from "../../media/images/visa.png";
import PaypalMethod from "../../media/images/paypal.png";
const FooterPage = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
        <Divider textAlign="left" className="mb-20">
                      <Chip color="primary" label={<Typography>
                        Follow Us <EditButton keyField={"footer_followUs"} contentField="Follow Us" />
                      </Typography>} />
                     
          </Divider> <Stack direction="row" spacing={2} alignItems={"center"}>
                      <YouTube sx={{ color: "#00668d", fontSize: "40px" }} />
                      <Facebook sx={{ color: "#00668d", fontSize: "40px" }} />
                    </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
        <Divider textAlign="left" className="mb-20">
                      <Chip color="primary" label={<Typography>
                        Download Tweet Trip App <EditButton keyField={"footer_downloadTweetTripApp"} contentField="Download Tweet Trip App" />
                      </Typography>} />
                     
          </Divider> 
          <Stack spacing={2} className="w-full">
          <TextFieldCommon
          keyField={
            "appNameField"
          }
          contentField="App Name"
        />
         <TextFieldCommon
          keyField={
            "googlePlayField"
          }
          contentField="Google Play Link"
        />
         <TextFieldCommon
          keyField={
            "appStroreField"
          }
          contentField="App Store Link"
        />

                    </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
        <Divider textAlign="left" className="mb-20">
                      <Chip color="primary" label={<Typography>
                        Email address <EditButton keyField={"footer_emailAddress"} contentField="Email address" />
                      </Typography>} />
                     
          </Divider> 
          <Stack spacing={2} className="w-full">
          <Typography className="text-14 mb-10">
          Subscribe to our newsletter <EditButton keyField={"footer_emailAddress_subscribeNewsletter"} contentField="Subscribe to our newsletter" />
                      </Typography>
          
          <Stack direction="row" spacing={2}>
            <Box className="w-half">
                <TextFieldCommon 
          keyField={
            ""
          }
          contentField=""
          placeHolder="Email"
          placeHolderKey="footer_emailAddress_emailPlaceHolder"
        />
              </Box>
            
        <ButtonCommon keyField={"subscribeButton"} contentField="Subscribe" />
          </Stack>
          
 
                    </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
        <Divider textAlign="left" className="mb-20">
                      <Chip color="primary" label={<Typography>
                        Payment method <EditButton keyField={"footer_paymentMethod"} contentField="Payment method" />
                      </Typography>} />
                     
          </Divider> 
          <Stack direction="row" spacing={2} className="w-full">
<Typography className="w-quarter">We accept <EditButton keyField={"footer_paymentMethod_weAccept"} contentField="We accept" /></Typography>
                    <Stack direction="row" spacing={2}>
<Lock /> 
<img src={AmexMethod} alt="amex" />

<img src={MastercardMethod} alt="mastercard" />

<img src={VisaMethod} alt="visa" />

<img src={PaypalMethod} alt="paypal" />
                      </Stack>
                    </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Stack alignItems={"flex-end"}>
              <IconButton>
                <Delete color="error" />
              </IconButton>
            </Stack>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography className="text-14 mb-10">
                  Characters{" "}
                  <EditButton
                    keyField={"checkLength"}
                    contentField="Characters"
                  />
                  : 30/30
                </Typography>

                <TextFieldCommon keyField={"titleField"} contentField="Title" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider textAlign="left">
                  <Typography className="border-full rounded-50 py-5 px-10">
                    Content{" "}
                    <EditButton
                      keyField={"contentField"}
                      contentField="Content"
                    />
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box sx={{ p: 2 }}>
                  <Stack alignItems={"flex-end"}>
                    <IconButton>
                      <Delete color="error" />
                    </IconButton>
                  </Stack>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox defaultChecked />} label={<Typography>Include Icon <EditButton keyField={"footer_contentItem_includeIcon"} contentField="Include Icon" /></Typography>} />
                    </Grid>
                    <Grid item xs={12}>
                        <UploadIconAndSampleCommon classWidth="w-full" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="text-14 mb-10">
                        Characters{" "}
                        <EditButton
                          keyField={"checkLength"}
                          contentField="Characters"
                        />
                        : 30/30
                      </Typography>

                      <TextFieldCommon
                        keyField={"textField"}
                        contentField="Text"
                      />

                      <Box mt={2} />
                    </Grid>

                    <Grid item xs={12}>
                      <Stack
                        className="h-full"
                        alignItems={"flex-end"}
                        justifyContent={"flex-end"}
                      >
                        <ButtonCommon
                          keyField="addContentButton"
                          contentField="Add Content"
                          bgColor="bg-white"
                          textColor="text-tkg-blue"
                          iconColor="#0000008a"
                          borderColor="border-color-primary"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          <Stack
            className="h-full"
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
          >
            <ButtonCommon
              keyField="addMoreButton"
              contentField="Add More"
              bgColor="bg-white"
              textColor="text-tkg-blue"
              iconColor="#0000008a"
              borderColor="border-color-primary"
            />
          </Stack>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <ButtonCommon keyField="saveButton" contentField="Save" />
      </Box>
    </Paper>
  );
};

export default FooterPage;
