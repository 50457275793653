import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import EditButton from "../../components/common/EditButton";
import AutoCompleteCommon from "../../components/common/AutoCompleteCommon";
import { useTranslation } from "react-i18next";

const AgentPortal = () => {
  const { t, i18n } = useTranslation();

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">Agent Portal<EditButton
                    keyField={`masterSetup_agentPortal_cardHeader`}
                    contentField={'Agent Portal'}
                  /></Typography>
                  
      <Divider />
      <Box mt={2}>
        <Grid container spacing={2} p={4}>
          <Grid item xs={12} md={6}>
            <AutoCompleteCommon keyField="masterSetup_agentPortal_portalType" contentField="Portal Type" defaultData={["English", "French", "Chinese"]} />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoCompleteCommon keyField="masterSetup_agentPortal_passengerType" contentField="Passenger Type" defaultData={["USD", "EUR", "GBP"]} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default AgentPortal;
