import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EditButton from "../../components/common/EditButton";
import SwitchWithName from "../../components/switch/SwitchWithName";
import CheckboxCommon from "../../components/common/CheckboxCommon";
import AutoCompleteCommon from "../../components/common/AutoCompleteCommon";

const ChooseLanguageAndCurrency = () => {
  const [check, setCheck] = useState(true);
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">Choose Language & Currency <EditButton
                    keyField={`masterSetup_chooseLanguageAndCurrency_cardHeader`}
                    contentField={'Choose Language & Currency'}
                  /></Typography>
      <Divider />
      <Box mt={2}>
        <Grid container spacing={2} p={4}>
          <Grid item xs={12} md={6}>
            <AutoCompleteCommon keyField="masterSetup_chooseLanguageAndCurrency_language" contentField="Language" defaultData={["English", "French", "Chinese"]} />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoCompleteCommon keyField="masterSetup_chooseLanguageAndCurrency_currency" contentField="Currency" defaultData={["USD", "EUR", "GBP"]} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ChooseLanguageAndCurrency;
