import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../../../components/common/EditButton";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import SectionCommon from "../../../../components/common/SectionCommon";
import ButtonCommon from "../../../../components/common/ButtonCommon";
import DataLimitCommon from "../../../../components/common/DataLimitCommon";
import SelectColorCommon from "../../../../components/common/SelectColorCommon";
import AutoCompleteCommon from "../../../../components/common/AutoCompleteCommon";

const Specialise = () => {
  return (
    <>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              41/50
            </Typography>
            <TextFieldCommon
              keyField={"homepage_navigationMenu_whyChooseUs_specialise_title"}
              contentField="Title"
            />
          </Box>
          <Box>
            
            <AutoCompleteCommon
              keyField={"homepage_navigationMenu_whyChooseUs_specialise_topBlogMoreInformation"}
              contentField="Top More Information Blog"
              defaultData={["Item 1", "Item 2"]}
            />
            </Box>
            <Box>
            <AutoCompleteCommon
              keyField={"homepage_navigationMenu_whyChooseUs_specialise_moreInformationField"}
              contentField="More Information Blogs"
              defaultData={["Item 1", "Item 2"]}
            />
          </Box>
        </Stack>
      </Paper>
      <SectionCommon
        sectionName="Header Section"
        sectionKey={"homepage_navigationMenu_whyChooseUs_specialise_section"}
        showButton={true}
      />
      <DataLimitCommon showButton={true} />

      <Stack className="mt-30" alignItems={"flex-end"}>
        <ButtonCommon
          keyField={
            "addMoreButton"
          }
          contentField="Add More"
          bgColor="bg-white"
          textColor="text-tkg-blue"
          iconColor="#0000008a"
          borderColor="border-color-primary"
        />
      </Stack>
    </>
  );
};

export default Specialise;
