import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import UploadImageAndSampleCommon from "../../../../components/common/UploadImageAndSampleCommon";
import EditButton from "../../../../components/common/EditButton";
import CheckboxCommon from "../../../../components/common/CheckboxCommon";
import SwitchWithName from "../../../../components/switch/SwitchWithName";
import ButtonCommon from "../../../../components/common/ButtonCommon";
import SelectColorCommon from "../../../../components/common/SelectColorCommon";

const Subscribe = () => {
  return (
    <>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              41/50
            </Typography>
            <TextFieldCommon
              keyField={"homepage_navigationMenu_whyChooseUs_subscribe_title"}
              contentField="Title"
            />
          </Box>
          <UploadImageAndSampleCommon />

          <Stack spacing={2}>
            <Grid container spacing={2} alignItems={"flex-end"}>
              <Grid item xs={12} md={6}>
                <Typography className="text-14 mb-10">
                  Characters{" "}
                  <EditButton
                    keyField={"checkLength"}
                    contentField="Characters"
                  />
                  : 20
                </Typography>
                <TextFieldCommon
                  keyField=""
                  placeHolderKey="homepage_navigationMenu_whyChooseUs_subscribe_placeHolderFirstName"
                  contentField=""
                  placeHolder="Enter First Name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CheckboxCommon
                  keyField={
                    "homepage_navigationMenu_whyChooseUs_subscribe_mandatoryField"
                  }
                  contentField="Mandatory Field"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems={"flex-end"}>
              <Grid item xs={12} md={6}>
                <Typography className="text-14 mb-10">
                  Characters{" "}
                  <EditButton
                    keyField={"checkLength"}
                    contentField="Characters"
                  />
                  : 20
                </Typography>
                <TextFieldCommon
                  keyField=""
                  placeHolderKey="homepage_navigationMenu_whyChooseUs_subscribe_placeHolderLastName"
                  contentField=""
                  placeHolder="Enter Last Name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CheckboxCommon
                  keyField={
                    "homepage_navigationMenu_whyChooseUs_subscribe_mandatoryField"
                  }
                  contentField="Mandatory Field"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems={"flex-end"}>
              <Grid item xs={12} md={6}>
                <Typography className="text-14 mb-10">
                  Characters{" "}
                  <EditButton
                    keyField={"checkLength"}
                    contentField="Characters"
                  />
                  : 20
                </Typography>
                <TextFieldCommon
                  keyField=""
                  placeHolderKey="homepage_navigationMenu_whyChooseUs_subscribe_placeHolderEmail"
                  contentField=""
                  placeHolder="Enter Email"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CheckboxCommon
                  keyField={
                    "homepage_navigationMenu_whyChooseUs_subscribe_mandatoryField"
                  }
                  contentField="Mandatory Field"
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ px: 10, py: 5, mb: 2 }}>
        <Stack spacing={15} direction={"row"} alignItems={"center"}>
          <ButtonCommon
            keyField={
              "homepage_navigationMenu_whyChooseUs_subscribe_subscribeButton"
            }
            contentField="Subscribe"
          />
          <Box className="d-flex align-center">
            Robot Check For Subscription{" "}
            <EditButton
              keyField={
                "homepage_navigationMenu_whyChooseUs_subscribe_labelCheckForSubscription"
              }
              contentField="Robot Check For Subscription"
            />
            <SwitchWithName
              checked={true}
              setChecked={() => {}}
              textChecked={"Check"}
              textUnchecked={"Uncheck"}
              keyField="homepage_navigationMenu_whyChooseUs_subscribe_switchCheckForSubscription"
            />
          </Box>
        </Stack>
      </Paper>
      <Stack className="mt-30" alignItems={"flex-end"}>
        <ButtonCommon
          keyField={"saveButton"}
          contentField="Save"
        />
      </Stack>
    </>
  );
};

export default Subscribe;
