import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import EditButton from './EditButton'

interface Props {
    cardTitleKey: string
    cardTitleContent: string
    children: JSX.Element
}
const WrapperCard = ({cardTitleKey, cardTitleContent, ...props}: Props) => {
  return (
    <Paper sx={{mb: 2}}>
        <Box className="p-10 border-bottom">
        <Typography className="text-16 fw-600">{cardTitleContent} <EditButton
                    keyField={cardTitleKey}
                    contentField={cardTitleContent}
                  /> </Typography>
        </Box>
        {props.children}
        </Paper>
  )
}

export default WrapperCard