import {
  Box,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import EditButton from "../../../components/common/EditButton";
import TextFieldCommon from "../../../components/common/TextFieldCommon";
import UploadImageAndSampleCommon from "../../../components/common/UploadImageAndSampleCommon";
import ButtonCommon from "../../../components/common/ButtonCommon";
import TextEditorCommon from "../../../components/common/TextEditorCommon";
import UploadFileCommon from "../../../components/common/UploadFileCommon";
import { VerticalAlignTopOutlined } from "@mui/icons-material";

const NavigationAboutUs = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Box className="mb-20">
            <Typography className="text-18 fw-600">
              Banner{" "}
              <EditButton
                keyField="homepage_navigation_aboutUs_banner_header"
                contentField="Banner"
              />
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextFieldCommon
                keyField="homepage_navigation_aboutUs_banner_title"
                contentField="Title"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <UploadImageAndSampleCommon classWidth="w-full" isRevert={true} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Box className="mb-20">
            <Typography className="text-18 fw-600">
              Our Journey{" "}
              <EditButton
                keyField="homepage_navigation_aboutUs_ourJourney_header"
                contentField="Our Journey"
              />
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldCommon
                      keyField="homepage_navigation_aboutUs_ourJourney_time"
                      contentField="Time"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCommon
                      keyField="homepage_navigation_aboutUs_ourJourney_title"
                      contentField="Title"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCommon
                      keyField="homepage_navigation_aboutUs_ourJourney_subtitle"
                      contentField="Sub Title"
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="d-inline-block">
                <ButtonCommon
                  keyField={"addOurJourneyButton"}
                  contentField="Add Our Journey"
                  bgColor="bg-white"
                  textColor="text-tkg-blue"
                  iconColor="#0000008a"
                  borderColor="border-color-primary"
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Box className="mb-20">
            <Typography className="text-18 fw-600">
              Our Company{" "}
              <EditButton
                keyField="homepage_navigation_aboutUs_ourCompany_header"
                contentField="Our Company"
              />
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextEditorCommon />
            </Grid>
            <Grid item xs={12} md={6}>
              <UploadImageAndSampleCommon classWidth="w-full" isRevert={true} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Box className="mb-20">
            <Typography className="text-18 fw-600">
              Values{" "}
              <EditButton
                keyField="homepage_navigation_aboutUs_values_header"
                contentField="Values"
              />
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <UploadFileCommon
                      keyCaption="imageUpload_caption"
                      contentCaption="Upload Image"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCommon
                      keyField="homepage_navigation_aboutUs_values_title"
                      contentField="Title"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCommon
                      keyField="homepage_navigation_aboutUs_values_content"
                      contentField="Content"
                      classHeight="h-100"
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="d-inline-block">
                <ButtonCommon
                  keyField={"addValuesButton"}
                  contentField="Add Values"
                  bgColor="bg-white"
                  textColor="text-tkg-blue"
                  iconColor="#0000008a"
                  borderColor="border-color-primary"
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Box className="mb-20">
            <Typography className="text-18 fw-600">
              What set us apart?{" "}
              <EditButton
                keyField="homepage_navigation_aboutUs_setUsApart_header"
                contentField="What set us apart?"
              />
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <UploadFileCommon
                      keyCaption="imageUpload_caption"
                      contentCaption="Upload Image"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCommon
                      keyField="homepage_navigation_aboutUs_setUsApart_title"
                      contentField="Title"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCommon
                      keyField="homepage_navigation_aboutUs_setUsApart_subtitle"
                      contentField="Sub Title"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCommon
                      keyField="homepage_navigation_aboutUs_setUsApart_content"
                      contentField="Content"
                      classHeight="h-100"
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="d-inline-block">
                <ButtonCommon
                  keyField={"addSetUsApartButton"}
                  contentField="Add set us apart"
                  bgColor="bg-white"
                  textColor="text-tkg-blue"
                  iconColor="#0000008a"
                  borderColor="border-color-primary"
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Box className="mb-20">
            <Typography className="text-18 fw-600">
              Happy Clients{" "}
              <EditButton
                keyField="homepage_navigation_aboutUs_happyClient_header"
                contentField="Happy Clients"
              />
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigation_aboutUs_happyClient_counterField"
                    contentField="Counter"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="text-18 fw-600"> PASSENGERS TRAVEL WITH US EACH YEAR <EditButton keyField="homepage_navigation_aboutUs_happyClient_travellerEachYearField" contentField="PASSENGERS TRAVEL WITH US EACH YEAR" /> </Typography>
                                   

                </Grid>
                <Grid item xs={12}>
                  <UploadFileCommon
                    keyCaption="imageUpload_caption"
                    contentCaption="Upload Image"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldCommon keyField="titleField" contentField="Title" />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="subtitleField"
                    contentField="Subtitle"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="ontentField"
                    contentField="Content"
                    classHeight="h-100"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Box className="mb-20">
            <Typography className="text-18 fw-600">
              Submit Form{" "}
              <EditButton
                keyField="homepage_navigation_aboutUs_submitForm_header"
                contentField="Submit Form"
              />
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigation_aboutUs_submitForm_title"
                    contentField="Title"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigation_aboutUs_submitForm_subtitle"
                    contentField="Sub Title"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <ButtonCommon
                      keyField="resumeButton"
                      contentField="Resume"
                      startIcon={<VerticalAlignTopOutlined />}
                      bgColor="bg-white"
                      textColor="text-tkg-blue"
                      iconColor="#0000008a"
                      borderColor="border-color-primary"
                    />
                    <ButtonCommon keyField="sendButton" contentField="Send" />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <UploadFileCommon
                keyCaption="imageUpload_caption"
                contentCaption="Upload Image"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems={"flex-end"}>
          <Box className="d-inline-block">
            <ButtonCommon keyField="saveButton" contentField="Save" />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default NavigationAboutUs;
