import React from "react";
import { Box, Card, Grid, Paper, Stack, Typography } from "@mui/material";
import EditButton from "../../../components/common/EditButton";
import TextFieldCommon from "../../../components/common/TextFieldCommon";
import UploadProfileAndSampleCommon from "../../../components/common/UploadProfileAndSampleCommon";
import AutoCompleteCommon from "../../../components/common/AutoCompleteCommon";
import TextEditorCommon from "../../../components/common/TextEditorCommon";
import ButtonCommon from "../../../components/common/ButtonCommon";
const FaqForm = () => {
  return (
    <>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <AutoCompleteCommon
                  keyField="homepage_faqs_typeField"
                  contentField="Type"
                  defaultData={[]}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AutoCompleteCommon
                  keyField="homepage_faqs_countryField"
                  contentField="Country"
                  defaultData={[]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldCommon
                  keyField="homepage_faqs_questionField"
                  contentField="Question"
                />
              </Grid>
              <Grid item xs={12}>
                <TextEditorCommon
                  keyField={"homepage_faqs_answerField"}
                  contentField="Answer"
                />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <Stack alignItems={"flex-end"}>
        <ButtonCommon keyField="saveButton" contentField="Save" />
      </Stack>
    </>
  );
};

export default FaqForm;
