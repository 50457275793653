import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../../components/common/EditButton";
import TextFieldCommon from "../../../components/common/TextFieldCommon";
import ButtonCommon from "../../../components/common/ButtonCommon";

const NavigationContactUs = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography className="text-18 fw-600 mb-20">
                Head Office{" "}
                <EditButton
                  contentField="Head Office"
                  keyField="homepage_navigationMenu_contactUs_headOffice_header"
                />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_headOffice_address"
                    contentField="Address"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_headOffice_phone"
                    contentField="Phone number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_headOffice_email"
                    contentField="Email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_headOffice_website"
                    contentField="Website URL"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-18 fw-600 mb-20">
                Open Hours
                <EditButton
                  contentField="Open Hours"
                  keyField="homepage_navigationMenu_contactUs_openHours_header"
                />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_openHours_time"
                    contentField="Time"
                  />
                </Grid>
               
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <ButtonCommon
                      keyField="addTimeButton"
                      contentField="Add Time"
                    />
                    <ButtonCommon
                      keyField="removeTimeButton"
                      contentField="Remove Time"
                      bgColor="bg-danger"
                      textColor="text-white"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography className="text-18 fw-600 mb-20">
                Reservation & support
                <EditButton
                  contentField="Reservation & support"
                  keyField="homepage_navigationMenu_contactUs_reservationSupport_header"
                />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_reservationSupport_location"
                    contentField="Location"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_reservationSupport_phone"
                    contentField="Phone"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <ButtonCommon
                      keyField="addLocationButton"
                      contentField="Add Location"
                    />
                    <ButtonCommon
                      keyField="removeLocationButton"
                      contentField="Remove Location"
                      bgColor="bg-danger"
                      textColor="text-white"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextFieldCommon
                keyField="homepage_navigationMenu_contactUs_reservationSupport_emailSupport"
                contentField="Email Support"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="text-18 fw-600 mb-20">
                Emergence Contact
                <EditButton
                  contentField="Emergence Contact"
                  keyField="homepage_navigationMenu_contactUs_emergenceContact_header"
                />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_emergenceContact_phone"
                    contentField="Phone number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_emergenceContact_email"
                    contentField="Email"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_emergenceContact_appName"
                    contentField="App Name"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldCommon
                    keyField="homepage_navigationMenu_contactUs_emergenceContact_appURL"
                    contentField="App URL"
                  />
                </Grid>
               
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <ButtonCommon
                      keyField="addAppButton"
                      contentField="Add App"
                    />
                    <ButtonCommon
                      keyField="removeAppButton"
                      contentField="Remove App"
                      bgColor="bg-danger"
                      textColor="text-white"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems={"flex-end"}>
          <Box className="d-inline-block">
            <ButtonCommon keyField="saveButton" contentField="Save" />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default NavigationContactUs;
