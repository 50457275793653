import { Box, Tab, Typography } from "@mui/material";
import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Overview from "./tabs-content/Overview";
import WhyUs from "./tabs-content/WhyUs";
import Specialise from "./tabs-content/Specialise";
import FAQ from "./tabs-content/FAQ";
import SearchLink from "./tabs-content/SearchLink";
import Subscribe from "./tabs-content/Subscribe";
import EditButton from "../../../components/common/EditButton";
import AccreditationsCompany from "./tabs-content/AccreditationsCompany";

export default function NavigationWhyChooseUsPage() {
  const [value, setValue] = React.useState("overview");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ bgcolor: "background.paper", borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            <Tab label={
                <Typography className="text-14 mb-10">
                  Overview{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_whyChooseUs_overviewTab"
                    }
                    contentField="Overview"
                  />
                </Typography>
              } value={"overview"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Why Us{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_whyChooseUs_whyUsTab"
                    }
                    contentField="Why Us"
                  />
                </Typography>
              } value={"why-us"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Specialise{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_whyChooseUs_specialiseTab"
                    }
                    contentField="Specialise"
                  />
                </Typography>
              } value={"specialise"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  FAQ{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_whyChooseUs_faqTab"
                    }
                    contentField="FAQ"
                  />
                </Typography>
              } value={"faq"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Search Link{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_whyChooseUs_searchLinkTab"
                    }
                    contentField="Search Link"
                  />
                </Typography>
              } value={"search-link"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Subscribe{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_whyChooseUs_subscribeTab"
                    }
                    contentField="Subscribe"
                  />
                </Typography>
              } value={"subscribe"} />

            <Tab label={
                <Typography className="text-14 mb-10">
                  Accreditations{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_whyChooseUs_accreditationsCompanyTab"
                    }
                    contentField="Accreditaions Company"
                  />
                </Typography>
              } value={"accreditations-company"} />
          </TabList>
        </Box>
        <TabPanel value="overview" sx={{ px: 0 }}>
          <Overview />
        </TabPanel>
        <TabPanel value="why-us" sx={{ px: 0 }}>
          <WhyUs />
        </TabPanel>
        <TabPanel value="specialise" sx={{ px: 0 }}>
          <Specialise />
        </TabPanel>
        <TabPanel value="faq" sx={{ px: 0 }}>
          <FAQ />
        </TabPanel>
        <TabPanel value="search-link" sx={{ px: 0 }}>
          <SearchLink />
        </TabPanel>
        <TabPanel value="subscribe" sx={{ px: 0 }}>
          <Subscribe />
        </TabPanel>
        <TabPanel value="accreditations-company" sx={{ px: 0 }}>
          <AccreditationsCompany />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
