import React from 'react'
import { Box, Card, Grid, Paper, Stack, Typography } from '@mui/material'
import EditButton from '../../../components/common/EditButton'
import TextFieldCommon from '../../../components/common/TextFieldCommon'
import UploadProfileAndSampleCommon from '../../../components/common/UploadProfileAndSampleCommon'
import AutoCompleteCommon from '../../../components/common/AutoCompleteCommon'
import TextEditorCommon from '../../../components/common/TextEditorCommon'
import ButtonCommon from '../../../components/common/ButtonCommon'
const CustomerReviewForm = () => {
  return (
    <>
       <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
        <Box>
        <Typography className="text-14 mb-10">
          Characters{" "}
          <EditButton keyField={"checkLength"} contentField="Characters" />:
          41/50
        </Typography>
        <TextFieldCommon
          keyField={"homepage_customerReview_titleField"}
          contentField="Title"
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}> 
                <UploadProfileAndSampleCommon />
              </Grid>
              <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <TextFieldCommon
                  keyField="homepage_customerReview_fullNameField"
                  contentField="Full Name"
                />
              </Grid>
              <Grid item xs={12}>
                <AutoCompleteCommon
                  keyField="homepage_customerReview_countryField"
                  contentField="Country"
                  defaultData={[]}
                />
              </Grid>
              </Grid>
              </Grid>
              <Grid item xs={12}>
                 <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              157/120
            </Typography>

            <TextEditorCommon
              keyField={"homepage_customerReview_bodyTextField"}
              contentField="Body Text"
            />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        </Grid>

        </Stack>
      </Paper>
      <Stack alignItems={"flex-end"}>
      <ButtonCommon keyField="saveButton" contentField="Save" />
      </Stack>
    </>
  )
}

export default CustomerReviewForm