import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import ButtonCommon from "../../components/common/ButtonCommon";
import SelectColorCommon from "../../components/common/SelectColorCommon";
import UploadImageAndSampleCommon from "../../components/common/UploadImageAndSampleCommon";

const AgentFinder = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
      <Typography className="text-16 fw-600 text-tkg-blue mb-20">
        Agent Finder{" "}
        <EditButton
          keyField={"homepage_knowMore_agentFinderForm_agentFinder_header"}
          contentField="Agent Finder"
        />
      </Typography>
      <Stack spacing={5}>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            41/25
          </Typography>
          <TextFieldCommon keyField={"titleField"} contentField="Title" />
        </Box>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            157/55
          </Typography>

          <TextFieldCommon keyField={"subtitleField"} contentField="Subtitle" />
        </Box>
        <Stack spacing={2}>
          <Typography className="text-16 fw-600">
            Postcode / Suburb{" "}
            <EditButton
              keyField={
                "homepage_knowMore_agentFinderForm_agentFinder_searchButtonTitle"
              }
              contentField="Postcode / Suburb"
            />
          </Typography>{" "}
          <Stack direction={"row"} alignItems={"center"} spacing={5}>
            <Box className="w-half">
              <TextFieldCommon
                placeHolderKey={`homepage_knowMore_agentFinderForm_agentFinder_searchButtonPlaceholder`}
                placeHolder="Enter your Postcode or Suburb"
                keyField={``}
                contentField={""}
              />
            </Box>

            <Box>
              <ButtonCommon
                keyField={`homepage_knowMore_agentFinderForm_agentFinder_searchButtonContent`}
                contentField={"Find"}
              />
            </Box>
          </Stack>
        </Stack>

        <Box>
          <UploadImageAndSampleCommon showFormat={true} isRevert={true} />
        </Box>
        <Stack alignItems={"flex-end"}>
          <ButtonCommon keyField="saveButton" contentField="Save" />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default AgentFinder;
