import React from 'react'
import WrapperCard from '../../../../components/common/WrapperCard'
import { Box, Grid, Typography } from '@mui/material'
import EditButton from '../../../../components/common/EditButton'
import TextFieldCommon from '../../../../components/common/TextFieldCommon'
import ButtonSelectColour from '../../../../components/common/ButtonSelectColour'
import ButtonCommon from '../../../../components/common/ButtonCommon'

type Props = {
  keywordField: string;
  placeHolder: string;
}
const SearchBoxFeature = ({keywordField, placeHolder}: Props) => {
  return (
    <WrapperCard
            cardTitleContent="Search Box"
            cardTitleKey={
              `${keywordField}_searchBox_titleHeader`
            }
            children={
              <Box p={3} py={4}>
                <Grid container spacing={5} alignItems={"flex-end"}>
                  <Grid item xs={12} md={10}>
                    <Typography className="text-14 mb-10">
                      Characters{" "}
                      <EditButton
                        keyField={
                          "checkLength"
                        }
                        contentField="Characters"
                      />
                      : 41/40
                    </Typography>
                    <TextFieldCommon
                    keyField=''
                    contentField=''
                      placeHolderKey={
                        `${keywordField}_searchBox_input`
                      }
                      placeHolder={placeHolder}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <ButtonCommon  keyField={
                        `${keywordField}_searchBox_button`
                      }
                      contentField="Search" />
                  </Grid>
              
                </Grid>
              </Box>
            }
          />
  )
}

export default SearchBoxFeature