import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import TextFieldCommon from "../../../components/common/TextFieldCommon";
import EditButton from "../../../components/common/EditButton";
import CheckboxCommon from "../../../components/common/CheckboxCommon";
import ButtonCommon from "../../../components/common/ButtonCommon";
import { CheckBox } from "@mui/icons-material";
import TextEditorCommon from "../../../components/common/TextEditorCommon";
import UploadIconAndSampleCommon from "../../../components/common/UploadIconAndSampleCommon";
import AutoCompleteCommon from "../../../components/common/AutoCompleteCommon";

const RegistrationPassengerTab = () => {
  return (
    <>
      <Paper sx={{ p: 3 }}>
        <Stack spacing={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={3}>
                <Typography className="text-28 fw-600">
                  CUSTOMER DETAILS
                  <EditButton
                    keyField="loginPage_passengerResgistration_title"
                    contentField="CUSTOMER DETAILS"
                  />
                </Typography>
                <Typography className="text-14">
                Already have an account yet?
                  <EditButton
                    keyField="loginPage_passengerResgistration_subtitle"
                    contentField="Already have an account yet?"
                  />
                  <Typography className="text-14 text-tkg-blue" component={"span"}>Login <EditButton
                    keyField="loginPage_passengerResgistration_subtitle_login"
                    contentField="Login"
                  /></Typography>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}> 
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                      <AutoCompleteCommon
                        keyField="titleField"
                        contentField="Title"
                        defaultData={["Mr", "Mrs", "Miss"]}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <CheckboxCommon
                        keyField={
                          "mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextFieldCommon
                        keyField="firstNameField"
                        contentField="First Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <CheckboxCommon
                        keyField={
                          "mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextFieldCommon
                        keyField="surnameField"
                        contentField="Surname"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <CheckboxCommon
                        keyField={
                          "mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextFieldCommon
                        keyField="dateBirthField"
                        contentField="Date of Birth"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <CheckboxCommon
                        keyField={
                          "mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextFieldCommon
                        keyField="emailField"
                        contentField="Email"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <CheckboxCommon
                        keyField={
                          "mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <TextFieldCommon
                        keyField="passwordField"
                        contentField="Password"
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <CheckboxCommon
                        keyField={
                          "mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextFieldCommon
                        keyField="confirmPasswordField"
                        contentField="Confirm Password"
                      />
                      <Typography className="text-14 text-danger">
                      Password does not match! <EditButton keyField="loginPage_registration_passwordNotMatch" contentField="Password does not match!" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <CheckboxCommon
                        keyField={
                          "mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="d-flex align-center">
                      <CheckBox sx={{ mr: "5px" }} />
                      I have read and agree to the{" "}
                      <EditButton
                        keyField={
                          "loginPage_registration_labelCheckAgreeTerms"
                        }
                        contentField="I have read and agree to the"
                      />
                      <Typography className="text-18 text-orange fw-700 " component={"span"}>Terms & Conditions <EditButton
                        keyField={
                          "loginPage_registration_termsCondition"
                        }
                        contentField="Terms & Conditions"
                      /></Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="d-flex align-center">
                      <CheckBox sx={{ mr: "5px" }} />
                      Subscribe to our Newsletter to get the best offers{" "}
                      <EditButton
                        keyField={
                          "loginPage_registration_labelSubscribeNewsletter"
                        }
                        contentField="Subscribe to our Newsletter to get the best offers"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="d-flex align-center">
                      <CheckBox sx={{ mr: "5px" }} />
                      I'm not a robot{" "}
                      <EditButton
                        keyField={
                          "loginPage_agentLogin_labelCheckNotRobot"
                        }
                        contentField="I'm not a robot"
                      />
                    </Box>
                  </Grid>


                  <Grid item xs={12} md={12}>
                    <Stack alignItems={"center"}>
                        <Box className="d-inline-block">
                          <ButtonCommon
                      bgColor="bg-orange"
                      textColor="text-white"
                        keyField={"registerNowButton"}
                        contentField="Register Now"
                      />
                        </Box>
                    </Stack>
                      
                  </Grid>
              
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </>
  );
};

export default RegistrationPassengerTab;
