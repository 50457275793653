import {Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import EditButton from './EditButton'
import { useTranslation } from 'react-i18next';

interface Props {
  keyField: string
  contentField: string;
}
const CheckboxCommon = ({keyField, contentField}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <FormControlLabel
              control={<Checkbox />}
              label={
                <>
                  {contentField}{" "}
                  <EditButton
                    keyField={keyField}
                    contentField={contentField}
                  />
                </>
              }
            />
  )
}

export default CheckboxCommon