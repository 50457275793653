import {
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import EditButton from "../../components/common/EditButton";
import TableHeader from "../../components/Table/TableHeader";

const BookingDetails = () => {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" className="text-20 fw-600 mb-20">
        Your Booking Details{" "}
        <EditButton
          keyField={"booking_details_header"}
          contentField="Your Booking Details"
        />
      </Typography>
      <Typography variant="h6" className="text-16 mb-20">
        9 DAY VIETNAM IMPRESSION TOUR
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="text-14 fw-600">
            Check-in{" "}
            <EditButton
              keyField={"booking_details_checkin"}
              contentField="Check-in"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="text-14 fw-600">
            Check-out{" "}
            <EditButton
              keyField={"booking_details_checkout"}
              contentField="Check-out"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="text-14 fw-600">
            Durations{" "}
            <EditButton
              keyField={"booking_details_durations"}
              contentField="Durations"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="text-14 fw-600">
            Room Type{" "}
            <EditButton
              keyField={"booking_details_roomType"}
              contentField="Room Type"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="text-14 fw-600">
            Tour Type{" "}
            <EditButton
              keyField={"booking_details_tourType"}
              contentField="Tour Type"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="text-14 fw-600">
            Group Size{" "}
            <EditButton
              keyField={"booking_details_groupSize"}
              contentField="Group Size"
            />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="text-14 fw-600">
            Accommodation{" "}
            <EditButton
              keyField={"booking_details_accommodation"}
              contentField="Accommodation"
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography className="text-16 mb-20">
            Room{" "}
            <EditButton
              keyField={"booking_details_pricingInfo_room"}
              contentField="Room"
            />{" "}
            1: 1 Adult{" "}
            <EditButton
              keyField={"booking_details_pricingInfo_adult"}
              contentField="Adult"
            />
            , 1 Child{" "}
            <EditButton
              keyField={"booking_details_pricingInfo_child"}
              contentField="Child"
            />
          </Typography>
          <Table className="table">
            <TableHead>
              <TableHeader>
                <TableCell className="bg-slight-blue text-left">
                  Quantity{" "}
                  <EditButton
                    keyField="booking_details_priceTable_header_quantityField"
                    contentField="Quantity"
                  />
                </TableCell>
                <TableCell className="bg-slight-blue text-left">
                  Price{" "}
                  <EditButton
                    keyField="booking_details_priceTable_header_priceField"
                    contentField="Price"
                  />
                </TableCell>
                <TableCell className="bg-slight-blue text-left">
                  Single Sup{" "}
                  <EditButton
                    keyField="booking_details_priceTable_header_singleSupField"
                    contentField="Single Sup"
                  />
                </TableCell>
              </TableHeader>
            </TableHead>
            <TableRow className="item-hover">
              <TableCell align="left">
                Adult(s){" "}
                <EditButton
                  keyField="booking_details_priceTable_row_adultField"
                  contentField="Adult(s)"
                />
                : 1 x $0
              </TableCell>
              <TableCell align="left">$0</TableCell>
              <TableCell align="left">$1,049</TableCell>
            </TableRow>
            <TableRow className="item-hover">
              <TableCell align="left">
                Child(ren){" "}
                <EditButton
                  keyField="booking_details_priceTable_row_childField"
                  contentField="Child(ren)"
                />
                : 1 x $0
              </TableCell>
              <TableCell align="left">$0</TableCell>
              <TableCell align="left">$1,049</TableCell>
            </TableRow>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack alignItems={"flex-end"}>
            <Typography className="text-16">
              Total Price:
              <EditButton
                keyField="booking_details_pricingInfo_totalPrice"
                contentField="Total Price"
              />{" "}
              <Typography className="text-20 fw-600" component={"span"}>
                $2,098
              </Typography>
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BookingDetails;
