import { createBrowserRouter } from "react-router-dom";
import MainSiteLayout from "../layouts/MainSiteLayout";
import TKGTreeView from "../layouts/menu";
import MasterSeoPage from "../pages/master-seo";
import MasterSetupPage from "../pages/master-setup";
import TestPage from "../pages/testPage";
import NavigationWhyChooseUsPage from "../pages/navigation-menu/why-choose-us/NavigationWhyChooseUsPage";
import NavigationFindLocalAgent from "../pages/navigation-menu/find-local-agent/NavigationFindLocalAgent";
import HeroSectionPage from "../pages/hero-section";
import FeatureDestinationPage from "../pages/feature-destination";
import FeatureServicePage from "../pages/feature-service";
import BestSellingProductPage from "../pages/best-selling-product";
import WhyChooseUsPage from "../pages/why-choose-us";
import AgentFinderPage from "../pages/agent-finder";
import BrochureUploadPage from "../pages/brochure-upload";
import InspirationByUsPage from "../pages/inspiration-by-us";
import OtherPage from "../pages/other";
import NavigationContactUs from "../pages/navigation-menu/contact-us";
import NavigationAboutUs from "../pages/navigation-menu/about-us";
import MobileAppPage from "../pages/mobile-app";
import OthersFeaturePage from "../pages/others-feature";
import ListInspiration from "../pages/navigation-menu/inspiration/list-inspiration";
import StoreInspirationPage from "../pages/navigation-menu/inspiration/store-inspiration";
import ViewInspirationPage from "../pages/navigation-menu/inspiration/view-inspiration";
import ListReview from "../pages/customer-review/list-review";
import CustomerReviewForm from "../pages/customer-review/store-review/CustomerReviewForm";
import TermAndConditionPage from "../pages/term-and-condition";
import PrivacyPolicyPage from "../pages/privacy-policy";
import FooterPage from "../pages/footer";
import CookiePolicyPage from "../pages/cookie-policy";
import LoginPage from "../pages/login-page";
import ListFaqs from "../pages/faqs/list-faqs";
import FaqForm from "../pages/faqs/store-faq/CustomerReviewForm";
import BookingPage from "../pages/booking";
import ViewFaqs from "../pages/faqs/view-faqs";
import ProductsDisplay from "../pages/products-display";
import ManageBooking from "../pages/manage-booking";

// @ts-ignore
const router = createBrowserRouter(
    [
        {
            path: "/",
            element:<MainSiteLayout memuList={<TKGTreeView />} />,
            children: [
                {
                    path: "/master-seo",
                    element: <MasterSeoPage />
                },
                {
                    path: "/master-setup",
                    element: <MasterSetupPage />
                },
                {
                    path: "/content-language",
                    element: <MasterSeoPage />
                },
                {
                    path: "/homepage",
                    children: [
                        {
                            path: "/homepage/navigation-menu",
                            children: [
                                {
                                    path: "/homepage/navigation-menu/why-choose-us",
                                    element: <NavigationWhyChooseUsPage />
                                },
                                {
                                    path: "/homepage/navigation-menu/find-local-agent",
                                    element: <NavigationFindLocalAgent />
                                },
                                {
                                    path: "/homepage/navigation-menu/contact-us",
                                    element: <NavigationContactUs />,
                                  },
                                  {
                                    path: "/homepage/navigation-menu/about-us",
                                    element: <NavigationAboutUs />,
                                  },
                                  {
                                    path: "/homepage/navigation-menu/inspiration",
                                    children: [
                                        {
                                            path: "/homepage/navigation-menu/inspiration",
                                            element: <ListInspiration />,
                                        },
                                        {
                                            path: "/homepage/navigation-menu/inspiration/view",
                                            element: <ViewInspirationPage />,
                                        },
                                        {
                                            path: "/homepage/navigation-menu/inspiration/store",
                                            element: <StoreInspirationPage />,
                                        }
                                    ]
                                  }
                            ]
                        },
                        {
                            path: "/homepage/hero-section",
                            element: <HeroSectionPage />
                        },
                        {
                            path: "/homepage/feature-destination",
                            element: <FeatureDestinationPage />
                        },
                        {
                            path: "/homepage/feature-service",
                            element: <FeatureServicePage />
                        },
                        {
                            path: "/homepage/best-selling-product",
                            element: <BestSellingProductPage />
                        },
                        {
                            path: "/homepage/why-choose-us",
                            element: <WhyChooseUsPage />
                        },
                        {
                            path: "/homepage/customer-review",
                            children: [
                                {
                                    path: "/homepage/customer-review",
                                    element: <ListReview />
                                }, 
                                {
                                    path: "/homepage/customer-review/add-review",
                                    element: <CustomerReviewForm />
                                }
                            ]
                        },
                        {
                            path: "/homepage/know-more",
                            children: [
                                {
                                    path: "/homepage/know-more/agent-finder",
                                    element: <AgentFinderPage />
                                },
                                {
                                    path: "/homepage/know-more/brochure-upload",
                                    element: <BrochureUploadPage />
                                },
                                {
                                    path: "/homepage/know-more/inspiration-by-us",
                                    element: <InspirationByUsPage />
                                },

                            ]
                        },
                        {
                            path: "/homepage/other",
                            element: <OtherPage />
                        },
                        {
                            path: "/homepage/faqs",
                            children: [
                                {
                                    path: "/homepage/faqs",
                                    element: <ListFaqs />
                                }, 
                                {
                                    path: "/homepage/faqs/add-faq",
                                    element: <FaqForm />
                                },
                                {
                                    path: "/homepage/faqs/view-faq",
                                    element: <ViewFaqs />
                                }
                            ]
                        },
                       
                      
                    ]
                }, {
                            path: "/terms-and-conditions",
                            element: <TermAndConditionPage />
                        },
                        {
                            path: "/privacy-policy",
                            element: <PrivacyPolicyPage />
                        },
                        {
                            path: "/cookie-policy",
                            element: <CookiePolicyPage />
                        },
                {
                    path: "/footer",
                    children: [
                        {
                            path: "/footer",
                            element: <FooterPage />
                        },
                        {
                            path: "/footer/company",
                            element: <MasterSeoPage />
                        },
                        {
                            path: "/footer/contact-us",
                            element: <MasterSeoPage />
                        },
                        {
                            path: "/footer/bussiness-information",
                            element: <MasterSeoPage />
                        },
                        {
                            path: "/footer/subscription",
                            element: <MasterSeoPage />
                        },
                        {
                            path: "/footer/social-pages",
                            element: <MasterSeoPage />
                        },
                        {
                            path: "/footer/app-links",
                            element: <MasterSeoPage />
                        },
                       
                    ]
                },
                {
                    path: "/products-display",
                    element: <ProductsDisplay />
                },
                {
                    path: "/itinerary",
                    element: <MasterSeoPage />
                },
                {
                    path: "/mobile-app",
                    element: <MobileAppPage />
                },
                {
                    path: "/others-feature",
                    element: <OthersFeaturePage />
                },
                {
                    path: "/login-page",
                    element: <LoginPage />
                },
                {
                    path: "booking",
                    element: <BookingPage />
                },
                {
                    path: "/test-user-view",
                    element: <TestPage />
                },
                {
                    path: "/manage-booking",
                    element: <ManageBooking />
                },
            ],
        },


    ]
)

export default router;
