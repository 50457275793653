import { Box, Typography } from '@mui/material'
import React from 'react'
import EditButton from './EditButton'

interface Props {
  keyField: string
  contentField: string;
  classHeight?: string;
  placeHolder?: string;
  placeHolderKey?: string
  bgTitle?: string
  bgPlaceHolder?: string
  slotIcon?: JSX.Element
}
const TextFieldCommon = ({keyField, contentField, placeHolder, placeHolderKey, classHeight="h-45", bgTitle="bg-white", bgPlaceHolder="bg-white", slotIcon}: Props) => {
  return (
    <Box className={`border-full position-relative ${classHeight} rounded-10`}>
    {!!contentField  ? <Typography className={`text-16 fw-700 position-absolute top-minus-15 left-10 ${bgTitle}`}>
      {contentField} <EditButton keyField={keyField} contentField={contentField} />
    </Typography> : null}

    {slotIcon}{(!!placeHolder && !!placeHolderKey) ? <Typography className={`text-16 position-absolute bottom-10 left-10 ${bgPlaceHolder}`}>
      {placeHolder} <EditButton keyField={placeHolderKey} contentField={placeHolder} />
    </Typography> : null}
  </Box>
  )
}

export default TextFieldCommon