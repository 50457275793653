import React from "react";
import MasterSeo from "../master-seo";
import ContentItem from "./ContentItem";
import { Stack } from "@mui/material";
import ButtonCommon from "../../components/common/ButtonCommon";

const WhyChooseUsPage = () => {
  return (
    <>
      <MasterSeo />
      <ContentItem />
      <Stack alignItems={"flex-end"}>
        <ButtonCommon
          keyField="saveButton"
          contentField="Save"
        />
      </Stack>
    </>
  );
};

export default WhyChooseUsPage;
