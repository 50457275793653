import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonCommon from "../../../../components/common/ButtonCommon";
import EditButton from "../../../../components/common/EditButton";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import UploadIconAndSampleCommon from "../../../../components/common/UploadIconAndSampleCommon";
import TextEditorCommon from "../../../../components/common/TextEditorCommon";

const AgentPage = () => {
  return (
    <>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        {" "}
        <Stack spacing={2}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              41/61
            </Typography>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_agentPage_metaTitle"
              }
              contentField="Meta Title"
            />
          </Box>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              157/160
            </Typography>

            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_agentPage_metaDescription"
              }
              contentField="Meta Description"
            />
          </Box>
          <Box>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_agentPage_metaKeywords"
              }
              contentField="Meta Keywords"
              classHeight="h-100"
            />
          </Box>
        </Stack>
      </Paper>

      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            41/61
          </Typography>
          <TextFieldCommon
            keyField={
              "homepage_navigationMenu_findLocalAgent_agentPage_agencyName"
            }
            contentField="Agency Name"
          />
        </Box>
      </Paper>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <UploadIconAndSampleCommon classWidth="w-full" />
          </Grid>
          <Grid item xs={12} md={7}>
            <Box>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 41/61
              </Typography>
              <TextFieldCommon
                keyField={
                  "homepage_navigationMenu_findLocalAgent_agentPage_agencyAddress"
                }
                contentField="Agency Address"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <UploadIconAndSampleCommon classWidth="w-full" />
          </Grid>
          <Grid item xs={12} md={7}>
            <Box>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 41/61
              </Typography>
              <TextFieldCommon
                keyField={
                  "homepage_navigationMenu_findLocalAgent_agentPage_agencyEmail"
                }
                contentField="Agency Email"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <UploadIconAndSampleCommon classWidth="w-full" />
          </Grid>
          <Grid item xs={12} md={7}>
            <Box>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 41/61
              </Typography>
              <TextFieldCommon
                keyField={
                  "homepage_navigationMenu_findLocalAgent_agentPage_agencyPhoneNumber"
                }
                contentField="Agency Phone Number"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <UploadIconAndSampleCommon classWidth="w-full" />
          </Grid>
          <Grid item xs={12} md={7}>
            <Box mb={2}>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 41/61
              </Typography>
              <TextFieldCommon
                keyField={
                  "homepage_navigationMenu_findLocalAgent_agentPage_agencyWebsite"
                }
                contentField="Agency Website"
              />
            </Box>
            <Box>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 41/61
              </Typography>
              <TextFieldCommon
                keyField={
                  "homepage_navigationMenu_findLocalAgent_agentPage_agencyWebsiteURL"
                }
                contentField="URL"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Box>
          <TextEditorCommon
            keyField={
              "homepage_navigationMenu_findLocalAgent_agentPage_aboutAgency"
            }
            contentField="About Agency"
          />
        </Box>
      </Paper>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <TextFieldCommon
                keyField={
                  "homepage_navigationMenu_findLocalAgent_agentPage_googleMapLatitude"
                }
                contentField="Google Map Latitude"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <TextFieldCommon
                keyField={
                  "homepage_navigationMenu_findLocalAgent_agentPage_googleMapLongitude"
                }
                contentField="Google Map Longitude"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <UploadIconAndSampleCommon />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        {" "}
        <Stack spacing={2}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              41/61
            </Typography>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_agentPage_searchResultTitle"
              }
              contentField="Search Result Title"
            />
          </Box>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              157/160
            </Typography>

            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_agentPage_searchResultSubtitle"
              }
              contentField="Search Result Subtitle"
            />
          </Box>
        </Stack>
      </Paper>
      <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
        <ButtonCommon
          keyField={
            "saveButton"
          }
          contentField="Save"
        />
      </Stack>
    </>
  );
};

export default AgentPage;
