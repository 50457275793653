import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import UploadFileCommon from "./UploadFileCommon";
import EditButton from "./EditButton";
import { Delete, Rowing } from "@mui/icons-material";
interface Props {
  classWidth?: string;
}
const UploadIconAndSampleCommon = ({ classWidth = "w-half" }: Props) => {
  return (
    <Box className={`d-flex gap-20 ${classWidth}`}>
      <Box className="w-quarter">
        <Stack direction={"row"} alignItems={"flex-end"}>
          <Rowing sx={{ color: "primary.main", fontSize: "32px" }} />
          <Delete color="error" sx={{ fontSize: "16px" }} />
        </Stack>
      </Box>
      <Box className="w-half">
        <UploadFileCommon
          keyCaption="iconUpload_caption"
          contentCaption="Upload Icon"
        />
        <Typography className="text-13 mt-5">
          Icon Size{" "}
          <EditButton keyField={"iconUpload_size"} contentField="Icon Size" />:
          48 X 48 Pixel{" "}
          <EditButton keyField={"iconUpload_unit"} contentField="Pixel" />
        </Typography>
        <Typography className="text-13 mt-5">
          Icon Format{" "}
          <EditButton
            keyField={"iconUpload_format_title"}
            contentField="Icon Format"
          />
          : SVG{" "}
          <EditButton keyField={"iconUpload_format_unit"} contentField="SVG" />
        </Typography>
      </Box>
    </Box>
  );
};

export default UploadIconAndSampleCommon;
