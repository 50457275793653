import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../../../components/common/EditButton";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import ButtonCommon from "../../../../components/common/ButtonCommon";

const FAQ = () => {
  return (
    <>
      {" "}
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 157/50
            </Typography>

            <TextFieldCommon
              keyField={"homepage_navigationMenu_whyChooseUs_faq_title"}
              contentField="Title"
            />
          </Box>
        </Stack>
      </Paper>
      <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
        <ButtonCommon
          keyField={"saveButton"}
          contentField="Save"
        />
      </Stack>
    </>
  );
};

export default FAQ;
