import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonCommon from "../../../../components/common/ButtonCommon";
import EditButton from "../../../../components/common/EditButton";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import SearchBoxFeature from "../common-feature/SearchBoxFeature";
import HighlightFeatureBelowSearchBox from "../common-feature/HighlightFeatureBelowSearchBox";
import SwitchWithName from "../../../../components/switch/SwitchWithName";
import SelectColorCommon from "../../../../components/common/SelectColorCommon";
import CheckboxCommon from "../../../../components/common/CheckboxCommon";

const SearchResult = () => {
  return (
    <>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        {" "}
        <Stack spacing={2}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              41/61
            </Typography>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_searchResult_metaTitle"
              }
              contentField="Meta Title"
            />
          </Box>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              157/160
            </Typography>

            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_searchResult_metaDescription"
              }
              contentField="Meta Description"
            />
          </Box>
          <Box>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_searchResult_metaKeywords"
              }
              contentField="Meta Keywords"
              classHeight="h-100"
            />
          </Box>
        </Stack>
      </Paper>

      <SearchBoxFeature keywordField={"homepage_navigationMenu_findLocalAgent_searchResult"} placeHolder={"Enter postcode or suburb"} />
      <HighlightFeatureBelowSearchBox />
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton
              keyField={
                "homepage_navigationMenu_findLocalAgent_searchResult_resultTitle_checkLength"
              }
              contentField="Characters"
            />
            : 41/61
          </Typography>
          <TextFieldCommon
            keyField={
              "homepage_navigationMenu_findLocalAgent_searchResult_resultTitle"
            }
            contentField="Result Title"
          />
        </Box>
      </Paper>

      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-24 fw-600">Contact Form <EditButton
                  keyField={"homepage_navigationMenu_findLocalAgent_searchResult_contactFormTitle"}
                  contentField="Contact Form"
                /></Typography>
          </Box>
          <Grid container spacing={2} alignItems={"flex-end"}>
            <Grid item xs={12}>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 20
              </Typography>
              <TextFieldCommon
                keyField=""
                placeHolderKey="homepage_navigationMenu_findLocalAgent_searchResult_justContactUs"
                contentField=""
                placeHolder="If you don't found what you want, just contact us"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems={"flex-end"}>
            <Grid item xs={12} md={6}>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 20
              </Typography>
              <TextFieldCommon
                keyField=""
                placeHolderKey="homepage_navigationMenu_findLocalAgent_searchResult_contactFormFirstName"
                contentField=""
                placeHolder="Enter First name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckboxCommon
                keyField={
                  "homepage_navigationMenu_whyChooseUs_subscribe_mandatoryField"
                }
                contentField="Mandatory Field"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems={"flex-end"}>
            <Grid item xs={12} md={6}>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 20
              </Typography>
              <TextFieldCommon
                keyField=""
                placeHolderKey="homepage_navigationMenu_findLocalAgent_searchResult_contactFormLastName"
                contentField=""
                placeHolder="Enter Last name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckboxCommon
                keyField={
                  "homepage_navigationMenu_whyChooseUs_subscribe_mandatoryField"
                }
                contentField="Mandatory Field"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems={"flex-end"}>
            <Grid item xs={12} md={6}>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 20
              </Typography>
              <TextFieldCommon
                keyField=""
                placeHolderKey="homepage_navigationMenu_findLocalAgent_searchResult_contactFormEmail"
                contentField=""
                placeHolder="Enter Email"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckboxCommon
                keyField={
                  "homepage_navigationMenu_whyChooseUs_subscribe_mandatoryField"
                }
                contentField="Mandatory Field"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems={"flex-end"}>
            <Grid item xs={12} md={6}>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 20
              </Typography>
              <TextFieldCommon
                keyField=""
                placeHolderKey="homepage_navigationMenu_findLocalAgent_searchResult_contactFormSubject"
                contentField=""
                placeHolder="Enter Subject"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckboxCommon
                keyField={
                  "homepage_navigationMenu_whyChooseUs_subscribe_mandatoryField"
                }
                contentField="Mandatory Field"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems={"flex-end"}>
            <Grid item xs={12} md={6}>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={"checkLength"}
                  contentField="Characters"
                />
                : 20
              </Typography>
              <TextFieldCommon
                keyField=""
                placeHolderKey="homepage_navigationMenu_findLocalAgent_searchResult_contactFormYourMessage"
                contentField=""
                placeHolder="Enter Message"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckboxCommon
                keyField={
                  "homepage_navigationMenu_whyChooseUs_subscribe_mandatoryField"
                }
                contentField="Mandatory Field"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectColorCommon
                sectionKey="homepage_navigationMenu_findLocalAgent_searchResult"
                buttonContent="Send Message"
              />
            </Grid>
            <Grid item xs={12}>
              <Box className="d-flex align-center">
                Robot Check For Subscription{" "}
                <EditButton
                  keyField={
                    "homepage_navigationMenu_whyChooseUs_subscribe_labelCheckForSubscription"
                  }
                  contentField="Robot Check For Subscription"
                />
                <SwitchWithName
                  checked={true}
                  setChecked={() => {}}
                  textChecked={"Check"}
                  textUnchecked={"Uncheck"}
                  keyField="homepage_navigationMenu_findLocalAgent_searchResult_switchCheckForSubscription"
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Paper>

      <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
        <ButtonCommon keyField={"saveButton"} contentField="Save" />
      </Stack>
    </>
  );
};

export default SearchResult;
