import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../../../components/common/EditButton'
import TextFieldCommon from '../../../../components/common/TextFieldCommon'
import ButtonCommon from '../../../../components/common/ButtonCommon'
import TextEditorCommon from '../../../../components/common/TextEditorCommon'
import UploadIconAndSampleCommon from '../../../../components/common/UploadIconAndSampleCommon'

const Hero = () => {
  return (
    <>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 41/50
            </Typography>
            <TextFieldCommon
              keyField={"homepage_navigationMenu_findLocalAgent_title"}
              contentField="Title"
            />
          </Box>

<UploadIconAndSampleCommon />


          <Stack spacing={2}>
            <Box><Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 41/50
            </Typography>
            <TextFieldCommon
              keyField={"homepage_navigationMenu_findLocalAgent_subTitle"}
              contentField="Subtitle"
            /></Box>

            
<Box>
  <Typography className="text-14">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 41/300
            </Typography>
            <TextEditorCommon
              keyField={"homepage_navigationMenu_findLocalAgent_bodyText"}
              contentField="Body Text"
            />
</Box>
            
          </Stack>
          
        </Stack>
      </Paper>
      

      <Stack alignItems={"flex-end"}>
        <ButtonCommon
          keyField={"addMoreButton"}
          contentField="Save"
        />
      </Stack>
    </>
  )
}

export default Hero