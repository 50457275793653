import { Box, Button, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../../components/common/EditButton'
import TableHeader from '../../../components/Table/TableHeader'

const WithBankTransferTab = () => {
  return (
    <Box>

        <Box className="mt-20">
          <Typography className="text-16 mb-20">
            <strong>Room</strong>
            <EditButton
              keyField={"booking_details_pricingInfo_room"}
              contentField="Room"
            />{" "}
            1: 1 Adult(s){" "}
            <EditButton
                  keyField="booking_details_priceTable_row_adultField"
                  contentField="Adult(s)"
                />
            , 1 Child(ren){" "}
            <EditButton
                  keyField="booking_details_priceTable_row_childField"
                  contentField="Child(ren)"
                />
          </Typography>
          <Table className="table">
            <TableHead>
              <TableHeader>
                <TableCell colSpan={3} className="bg-tkg-blue text-center text-white">
                  $0 (USD)
                </TableCell>
              </TableHeader>
            </TableHead>
            <TableRow className="item-hover">
              <TableCell align="left">
              </TableCell>
              <TableCell align="left">Price  <EditButton
                  keyField="booking_details_priceTable_header_priceField"
                  contentField="Price"
                /></TableCell>
              <TableCell align="left">Due Date <EditButton
                  keyField="booking_details_priceTable_header_dueDateField"
                  contentField="Due Date"
                /></TableCell>
              
            </TableRow>
            <TableRow className="item-hover">
              <TableCell align="left">
                Full <EditButton
                  keyField="booking_details_priceTable_row_fullField"
                  contentField="Full"
                />
              </TableCell>
              <TableCell align="left">$2,098 USD</TableCell>
              <TableCell align="left">12 Jul, 2024</TableCell>
            </TableRow>
          </Table>
        </Box>

        <Typography className='text-16 mt-10'>
        Please submit your booking on our website, and you will receive an invoice along with instructions on how to make the payment via bank transfer. We offer the convenience of bank accounts in AUD, USD, and EUR, so you can choose the currency that best suits you.
        <EditButton
              keyField={"booking_details_payment_instructions"}
              contentField="Please submit your booking on our website, and you will receive an invoice along with instructions on how to make the payment via bank transfer. We offer the convenience of bank accounts in AUD, USD, and EUR, so you can choose the currency that best suits you."
            />
        </Typography>
    </Box>
  )
}

export default WithBankTransferTab