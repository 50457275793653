import { AccountCircleOutlined, EmojiObjectsOutlined, FavoriteBorder, Search } from "@mui/icons-material";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import EditButton from "../../components/common/EditButton";
const OthersFeaturePage = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
     Other Feature
    </Paper>
  );
};

export default OthersFeaturePage;
