import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import TextFieldCommon from "../../../components/common/TextFieldCommon";
import EditButton from "../../../components/common/EditButton";
import CheckboxCommon from "../../../components/common/CheckboxCommon";
import ButtonCommon from "../../../components/common/ButtonCommon";
import SwitchWithName from "../../../components/switch/SwitchWithName";
import { CheckBox } from "@mui/icons-material";
import TextEditorCommon from "../../../components/common/TextEditorCommon";
import UploadIconAndSampleCommon from "../../../components/common/UploadIconAndSampleCommon";
import UploadImageAndSampleCommon from "../../../components/common/UploadImageAndSampleCommon";

const AgentLoginTab = () => {
  return (
    <>
      {" "}
      <Paper sx={{ p: 3 }}>
        <Stack spacing={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-22 fw-600">
                    Welcome To{" "}
                    <EditButton
                      keyField="loginPage_agentLogin_title"
                      contentField="Welcome To"
                    />
                  </Typography>
                   <Typography className="text-16 fw-600">
                    Our B2B Agent Portal{" "}
                    <EditButton
                      keyField="loginPage_agentLogin_subtitle"
                      contentField="Our B2B Agent Portal"
                    />
                  </Typography>
                </Grid>
                
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldCommon
                        keyField=""
                        placeHolderKey="loginPage_agentLogin_placeHolderEmail"
                        contentField=""
                        placeHolder="Email Address"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CheckboxCommon
                        keyField={
                          "loginPage_agentLogin_mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldCommon
                        keyField=""
                        placeHolderKey="loginPage_agentLogin_placeHolderPassword"
                        contentField=""
                        placeHolder="Password"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CheckboxCommon
                        keyField={
                          "loginPage_agentLogin_mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="d-flex align-center">
                      <CheckBox sx={{ mr: "5px" }} />
                      I'm not a robot{" "}
                      <EditButton
                        keyField={
                          "loginPage_agentLogin_labelCheckNotRobot"
                        }
                        contentField="I'm not a robot"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <ButtonCommon
                      bgColor="bg-tkg-blue"
                      textColor="text-white"
                        keyField={"signInButton"}
                        contentField="Sign In"
                      />
                  </Grid>
                <Grid item xs={12}>
                  <Box className="d-flex align-center justify-between">
                  <Typography className="text-14">
                    Forgot Your Password?{" "}
                    <EditButton
                      keyField="loginPage_agentLogin_forgotPassword"
                      contentField="Forgot Your Password?"
                    />
                  </Typography>
                  <Typography className="text-14">
                    Resgistration{" "}
                    <EditButton
                      keyField="loginPage_agentLogin_resgistration"
                      contentField="Resgistration"
                    />
                  </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextEditorCommon keyField="loginPage_agentLogin_loginInformation" contentField="Login Information" />
                </Grid>
                <Grid item xs={12}>
                      <UploadIconAndSampleCommon classWidth="w-full" />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="text-14 fw-700">
                    Help Desk - 1800519 631 Or +61 (8) 7226 1898{" "}
                    <EditButton
                      keyField="loginPage_agentLogin_helpDesk"
                      contentField="Help Desk - 1800519 631 Or +61 (8) 7226 1898"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <UploadImageAndSampleCommon classWidth="w-full" />
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </>
  );
};

export default AgentLoginTab;
