import { Box, Grid, Paper, Stack, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { Add, Search } from '@mui/icons-material';
import TableHeading from './TableHeading';
import ButtonCommon from '../../../components/common/ButtonCommon';
import TextFieldCommon from '../../../components/common/TextFieldCommon';
import AutoCompleteCommon from '../../../components/common/AutoCompleteCommon';
import EditButton from '../../../components/common/EditButton';

const ListFaqs = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
                <Stack className='d-inline-block'>
                   <ButtonCommon startIcon={<Add />} keyField='addNewButton' contentField='Add New'
                   /> 
                </Stack>
          </Grid>
          <Grid item xs={12}>
           <Grid container spacing={2}>
            <Grid item xs={12}>
                Show <EditButton keyField="table_pagination_show" contentField="Show" /> <Typography className='text-14 border-full py-5 px-10 rounded-8' component={"span"}>10</Typography> of <EditButton keyField="table_pagination_of" contentField="of" /> <b>3</b>
            </Grid>
            <Grid item xs={12}>
            <Table className="table">
                <TableHead><TableHeading /></TableHead>
                <TableRow className="item-hover">
              <TableCell colSpan={15} align="center">
                No results found ! <EditButton keyField="table_data_notFound" contentField="No results found !" />
              </TableCell>
            </TableRow>
                </Table>
            </Grid>
           </Grid>
          </Grid>
        </Grid>
    </Paper>
  )
}

export default ListFaqs