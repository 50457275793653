import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../../../components/common/EditButton";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import ButtonCommon from "../../../../components/common/ButtonCommon";
const Overview = () => {
  return (
    <>
      {" "}
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 41/61
            </Typography>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_overview_metaTitle"
              }
              contentField="Meta Title"
            />
          </Box>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton
                keyField={
                  "checkLength"
                }
                contentField="Characters"
              />
              : 157/160
            </Typography>

            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_overview_metaDescription"
              }
              contentField="Meta Description"
            />
          </Box>
          <Box>
            <TextFieldCommon
              keyField={
                "homepage_navigationMenu_findLocalAgent_overview_metaKeywords"
              }
              contentField="Meta Keywords"
              classHeight="h-100"
            />
          </Box>
        </Stack>
      </Paper>
      <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
        <ButtonCommon
          keyField={
            "saveButton"
          }
          contentField="Save"
        />
      </Stack>
    </>
  );
};

export default Overview;
