import { Box } from '@mui/material'
import React from 'react'
import EditButton from './EditButton'

interface Props {
  startIcon?: JSX.Element
  keyField: string
  contentField: string;
  iconColor?: string
  bgColor?: string
  textColor?: string
  borderColor?: string
  borderRadius?: string
}
const ButtonCommon = ({keyField, contentField, iconColor="#fff", bgColor="bg-tkg-blue", textColor="white", borderColor, startIcon, borderRadius}: Props) => {

  return (
    <Box className={`d-flex align-center border-full rounded-4 ${bgColor} ${textColor} ${borderColor} ${borderRadius} px-10 py-5 text-white`}>
            {startIcon}{contentField}{" "}
            <EditButton
              keyField={keyField}
              contentField={contentField}
              color={iconColor}
            />
          </Box>
  )
}

export default ButtonCommon