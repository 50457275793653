import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../components/common/EditButton'
import TextFieldCommon from '../../components/common/TextFieldCommon'
import UploadImageAndSampleCommon from '../../components/common/UploadImageAndSampleCommon'
import TextEditorCommon from '../../components/common/TextEditorCommon'
import ButtonCommon from '../../components/common/ButtonCommon'
import UploadIconAndSampleCommon from '../../components/common/UploadIconAndSampleCommon'

const ContentItem = () => {
  return (
    <Box mt={5}>
    <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            41/50
          </Typography>
          <TextFieldCommon
            keyField={"homepage_whyChooseUs_content_title"}
            contentField="Title"
          />
        </Box>
    <Box my={2} py={2}>
      <UploadImageAndSampleCommon isRevert={true} />
    </Box>

    <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
      <Stack spacing={5}>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            157/50
          </Typography>

          <TextFieldCommon
            keyField={"homepage_whyChooseUs_content_subtitle"}
            contentField="Subtitle"
          />
        </Box>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            157/200
          </Typography>

          <TextEditorCommon
            keyField={"homepage_whyChooseUs_content_bodyText"}
            contentField="Body Text"
          />
        </Box>
        <Box my={2} py={2}>
      <UploadIconAndSampleCommon />
    </Box>
        <Stack alignItems={"flex-end"}>
          <ButtonCommon
            keyField="addMoreButton"
            contentField="Add More"
            bgColor="bg-white"
            textColor="text-tkg-blue"
            iconColor="#0000008a"
            borderColor="border-color-primary"
          />
        </Stack>
      </Stack>
    </Paper>
  </Box>
  )
}

export default ContentItem