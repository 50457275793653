import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import EditButton from "../../components/common/EditButton";
import AutoCompleteCommon from "../../components/common/AutoCompleteCommon";
import { useTranslation } from "react-i18next";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import ButtonCommon from "../../components/common/ButtonCommon";

const AddMenuBar = () => {
  const { t, i18n } = useTranslation();

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">Add Menu Bar<EditButton
                    keyField={`masterSetup_addMenuBar_cardHeader`}
                    contentField={'Add Menu Bar'}
                  /></Typography>
                  
      <Divider />
      <Box mt={2}>
        <Grid container spacing={2} p={4}>
          <Grid item xs={12} md={2}>
            <TextFieldCommon keyField="masterSetup_addMenuBar_title" contentField="Title" />
          </Grid>
          <Grid item xs={12} md={2} pb={0}>
            <Box className="h-full"> <ButtonCommon keyField="masterSetup_addMenuBar_addMenu" contentField="Add Menu" /></Box>
            
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default AddMenuBar;
