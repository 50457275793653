import { createContext, useContext } from "react";
import { ContentLanguageResponse } from "../interfaces/contentLanguage";
export type GlobalContent = {
  companyLogo: string;
  companyName: string;
  setCompanyLogo: (logo: string) => void;
  setCompanyName: (name: string) => void;
  // theme
  mode: string;
  setMode: (m: string) => void;
  openCreateLanguage: boolean;
  setOpenCreateLanguage: (open: boolean) => void;
  keyLanguageContent: {
    section: string;
    content: string;
  };
  setKeyLanguageContent: (data: { section: string; content: string }) => void;

  openCreateSwitchLanguage: boolean;
  setOpenCreateSwitchLanguage: (open: boolean) => void;
  keySwitchLanguageContent: {
    sectionChecked: string;
    contentChecked: string;
    sectionUnChecked: string;
    contentUnChecked: string;
  };
  setKeySwitchLanguageContent: (data: {
    sectionChecked: string;
    contentChecked: string;
    sectionUnChecked: string;
    contentUnChecked: string;
  }) => void;

  listDataLanguage: ContentLanguageResponse;
  setListDataLanguage: (data: ContentLanguageResponse) => void;
};

export const MyGlobalContext = createContext<GlobalContent>({
  companyLogo: localStorage.getItem("companyLogo") ?? "",
  companyName: localStorage.getItem("companyName") ?? "",
  setCompanyLogo: () => {},
  setCompanyName: () => {},

  // theme
  mode: "light",
  setMode: () => {},
  openCreateLanguage: false,
  setOpenCreateLanguage: () => {},
  keyLanguageContent: {
    section: "",
    content: "",
  },
  setKeyLanguageContent: () => {},
  openCreateSwitchLanguage: false,
  setOpenCreateSwitchLanguage: () => {},
  keySwitchLanguageContent: {
    sectionChecked: "",
    contentChecked: "",
    sectionUnChecked: "",
    contentUnChecked: "",
  },
  setKeySwitchLanguageContent: () => {},

  listDataLanguage: {
    en: [],
    fr: [],
    cn: [],
  },
  setListDataLanguage: () => {},
});

export const useGlobalContext = () => useContext(MyGlobalContext);
