import React from 'react'
import WrapperCard from '../../components/common/WrapperCard'
import { Box, Grid, Typography } from '@mui/material'
import EditButton from '../../components/common/EditButton'
import TextFieldCommon from '../../components/common/TextFieldCommon'

const HeroBannerSlogan = () => {
  return (
    <WrapperCard
    cardTitleContent="Hero Banner Slogan"
    cardTitleKey={
      "homepage_heroSection_heroBannerSlogan_titleHeader"
    }
    children={
      <Box p={3} py={4}>
      
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="text-14 mb-10">
                  Characters{" "}
                  <EditButton
                    keyField={"checkLength"}
                    contentField="Characters"
                  />
                  : 41/40
                </Typography>
                <TextFieldCommon
                  keyField={
                    "homepage_heroSection_heroBannerSlogan_sloganPhrase"
                  }
                  contentField="Slogan Phrase"
                  placeHolder='Start your discovery'
                  placeHolderKey='homepage_heroSection_heroBannerSlogan_sloganPhrasePlaceholder'
                  classHeight="h-55"
                />
              </Grid>
             
            
        </Grid>
      </Box>
    }
  />
  )
}

export default HeroBannerSlogan