import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EditButton from "../../components/common/EditButton";
import { makeSlugKey } from "../../utils";
import PersonalDetails from "./PersonalDetails";
import PaymentDetails from "./PaymentDetails";
import FinalStep from "./FinalStep";
import { Paper, Stack } from "@mui/material";

const steps = [
  "Personal Details",
  "Payment Details",
  "Final Step",
];

export default function BookingPage() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return <PersonalDetails />;
      case 1:
        return <PaymentDetails />;
      case 2:
        return <FinalStep />;
    }
  }
  return (
    <Paper sx={{p: 2, mb: 3}}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const labelProps = {};
          return (
            <Step key={label}>
              <StepLabel {...labelProps}>{label} <EditButton keyField={`booking_step_${index}_${makeSlugKey(label)}`} contentField={label} /></StepLabel>
            </Step>
          );
        })}
      </Stepper>
     
        <React.Fragment>
          {renderSteps()}
          <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={2} sx={{pt: 2 }}>
            <Button
              color="warning"
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Prev <EditButton keyField="booking_step_back" contentField="Prev" />
            </Button>
            <Button variant="contained" onClick={handleNext} disabled={activeStep === steps.length - 1}>
              Next <EditButton keyField="booking_step_next" contentField="Next" />
            </Button>
          </Stack>
        </React.Fragment>
      
    </Paper>
  );
}
