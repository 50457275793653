import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import React from "react";
import BookingDetails from "./BookingDetails";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import EditButton from "../../components/common/EditButton";

const PersonalDetails = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Alert severity="warning">
            <AlertTitle>Notice <EditButton keyField="bookingPage_warning_title" contentField="Notice" /></AlertTitle>
            Sign in to book with your saved details or <EditButton keyField="bookingPage_warning_signInCondition" contentField="Sign in to book with your saved details or" /> <strong>
              register <EditButton keyField="bookingPage_warning_registerButton" contentField="register" />
            </strong> 
            to manage your bookings on the go! <EditButton keyField="bookingPage_warning_purpose" contentField="to manage your bookings on the go!" />
          </Alert>
          <Typography variant="h6" className="text-20 fw-600 py-30">
            Add Traveller Details <EditButton keyField="bookingPage_personalDetails_title" contentField="Add Traveller Details" />
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="text-16 fw-600">Room <EditButton keyField="bookingPage_personalDetails_room" contentField="Room" /> 1</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-16 fw-600">
                Lead Traveller <EditButton keyField="bookingPage_personalDetails_leader" contentField="Lead Traveller" /> 1
              </Typography>
              <Typography className="text-16">
                This traveller will serve as the contact person for the booking. <EditButton keyField="bookingPage_personalDetails_leaderFunction" contentField="This traveller will serve as the contact person for the booking." />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextFieldCommon keyField="titleField" contentField="Title" placeHolder="Select title" placeHolderKey="titleSelectPlaceholder" />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="firstNameField"
                    contentField="First Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="lastNameField"
                    contentField="Last Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon keyField="emailField" contentField="Email" />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="phoneNumberField"
                    contentField="Phone Number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextFieldCommon keyField="dayField" contentField="Day" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextFieldCommon
                        keyField="monthField"
                        contentField="Month"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextFieldCommon
                        keyField="yearField"
                        contentField="Year"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldCommon
                    keyField="nationalityField"
                    contentField="Nationality"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <BookingDetails />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalDetails;
