import React from "react";
import WrapperCard from "../../components/common/WrapperCard";
import { Box, Grid, Stack, Typography } from "@mui/material";
import ButtonCommon from "../../components/common/ButtonCommon";
import { CloudUpload, Delete, Edit } from "@mui/icons-material";
import HeroBannerSample from "../../media/images/hero-banner-photo.jpg";
import EmptyImage from "../../media/images/empty-img.jpg";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import UploadFileCommon from "../../components/common/UploadFileCommon";
const HeroBannerPhoto = () => {
  return (
    <WrapperCard
      cardTitleContent="Hero Banner Photo"
      cardTitleKey={
        "homepage_heroSection_heroBannerPhoto_titleHeader"
      }
      children={
        <Box p={3} py={4}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box className="d-inline-block">
                <ButtonCommon
                  startIcon={<CloudUpload sx={{ mr: "5px" }} />}
                  contentField="Add Image"
                  keyField="homepage_heroSection_heroBannerPhoto_addImage"
                ></ButtonCommon>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box>
                <Typography className="text-14">
                  Title 
                  <EditButton keyField={"homepage_heroSection_heroBannerPhoto_titleImage"} contentField="Title" />:
                  Slider 1
                </Typography>
                <Box className="d-flex">
                  <img src={HeroBannerSample} className="h-100" />
                  <Stack justifyContent={"space-between"}>
                    <Edit />
                    <Delete color="error" />
                  </Stack>
                </Box>
              </Box>
            </Grid>
            {Array(2)
              .fill(0)
              .map((_, index) => (
                <Grid key={index} item xs={12} md={3}>
                  <Box className="d-flex gap-10 pt-28">
                    <img src={EmptyImage} className="h-100" />
                  </Box>
                </Grid>
              ))}
          </Grid>

          <Grid container mt={3} py={2} bgcolor={"#ebebeb"}>
            
            <Grid item xs={10}>
                <Typography className="text-28 text-danger fw-600 pl-16">Hero Photo Pop Up</Typography>
              <Grid container spacing={2} p={2}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <img src={HeroBannerSample} className="h-200" />
                    </Grid>
                  <Grid item xs={10}><TextFieldCommon keyField="titleField" contentField="Title" bgTitle="#ebebeb" /></Grid>
                  <Grid item xs={10}><TextFieldCommon keyField="urlField" contentField="URL" bgTitle="#ebebeb" /></Grid>
                  <Grid item xs={10}>  <Stack alignItems={"flex-end"}>
                    <ButtonCommon contentField="Apply" keyField="applyButton" />
                  </Stack></Grid>
                  </Grid>
                
                </Grid>
                <Grid item xs={12} md={3} className="d-inline-block">
                  <Typography className="text-14 mt-5">
                    Image Size{" "}
                    <EditButton
                      keyField={"fileUpload_size"}
                      contentField="Image Size"
                    />
                    : 1440 X 471 Pixel{" "}
                    <EditButton
                      keyField={"fileUpload_unit"}
                      contentField="Pixel"
                    />
                  </Typography>
                  <UploadFileCommon
                    keyCaption="fileUpload_caption"
                    contentCaption="Upload File"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

export default HeroBannerPhoto;
