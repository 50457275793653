import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import SampleImage from "../../../../media/images/example-upload.jpg";
import { BusinessCenterSharp, Delete, People } from "@mui/icons-material";
import EditButton from "../../../components/common/EditButton";
import ButtonCommon from "../../../components/common/ButtonCommon";
const LoginModalTab = () => {
  return (
    <>
      {" "}
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12} justifyContent={"center"}>
            <Typography className="text-22 text-center fw-600 ">Select Your Login Type <EditButton keyField="loginModal_modalTitle" contentField="Select Login Type" /></Typography>
          </Grid>
          <Grid item xs={12}  justifyContent={"center"}>
            <Stack spacing={2} direction="row" alignItems={"center"} justifyContent={"center"}>
              <ButtonCommon startIcon={<People />} keyField="loginModal_agentLoginButton" contentField="Agent" />
              <ButtonCommon startIcon={<BusinessCenterSharp />} keyField="loginModal_passengerLoginButton" contentField="Passenger" />
            </Stack>
          </Grid>
        </Grid>
      </Paper>
   
    </>
  );
};

export default LoginModalTab;
