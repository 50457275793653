import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import UploadIconAndSampleCommon from "../../components/common/UploadIconAndSampleCommon";
import TextEditorCommon from "../../components/common/TextEditorCommon";
import ButtonCommon from "../../components/common/ButtonCommon";

const ContentItem = () => {
  return (
    <>
      <Typography className="text-24 fw-600">
        Data Limit{" "}
        <EditButton keyField={"dataLimit_length"} contentField="Data Limit" />:
        3{" "}
      </Typography>
      <Box my={2} p={2}>
        <UploadIconAndSampleCommon />
      </Box>

      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              41/30
            </Typography>
            <TextFieldCommon
              keyField={"homepage_featureService_content_title"}
              contentField="Title"
            />
          </Box>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              157/80
            </Typography>

            <TextFieldCommon
              keyField={"homepage_featureService_content_subtitle"}
              contentField="Subtitle"
            />
          </Box>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              157/120
            </Typography>

            <TextEditorCommon
              keyField={"homepage_featureService_content_bodyText"}
              contentField="Body Text"
            />
          </Box>
          <Stack alignItems={"flex-end"}>
            <ButtonCommon
              keyField="addMoreButton"
              contentField="Add More"
              bgColor="bg-white"
              textColor="text-tkg-blue"
              iconColor="#0000008a"
              borderColor="border-color-primary"
            />
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default ContentItem;
