import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonCommon from "../../components/common/ButtonCommon";
import { AttachMoney, CloudUpload, Delete, Edit } from "@mui/icons-material";
import HeroBannerSample from "../../media/images/hero-banner-photo.jpg";
import EmptyImage from "../../media/images/empty-img.jpg";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import EditButton from "../../components/common/EditButton";
import UploadFileCommon from "../../components/common/UploadFileCommon";
import AutoCompleteCommon from "../../components/common/AutoCompleteCommon";

const FeatureDestinationPage = () => {
  return (
    <>
      <Paper sx={{ p: 3, mb: 2 }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box className="d-inline-block">
              <ButtonCommon
                startIcon={<CloudUpload sx={{ mr: "5px" }} />}
                contentField="Add Image"
                keyField="homepage_featureDestination_addImageButton"
              ></ButtonCommon>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              <Box className="d-flex">
                <img src={HeroBannerSample} className="h-100" />
                <Stack justifyContent={"space-between"}>
                  <Edit />
                  <Delete color="error" />
                </Stack>
              </Box>
            </Box>
          </Grid>
          {Array(2)
            .fill(0)
            .map((_, index) => (
              <Grid key={index} item xs={12} md={3}>
                <Box className="d-flex gap-10">
                  <img src={EmptyImage} className="h-100" />
                </Box>
              </Grid>
            ))}
        </Grid>

        <Grid container mt={3} py={2}>
          <Grid item xs={12} md={5} bgcolor={"#ebebeb"} p={2}>
            <Typography className="text-28 text-danger fw-600 pl-16">
              Add More Pop Up
            </Typography>

            <Grid container spacing={3} mt={2}>
              <Grid item xs={12} md={6}>
                <img src={HeroBannerSample} className="h-120" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-16">
                  Image Size{" "}
                  <EditButton
                    keyField={"fileUpload_size"}
                    contentField="Image Size"
                  />
                  : 1440 X 471 Pixel{" "}
                  <EditButton
                    keyField={"fileUpload_unit"}
                    contentField="Pixel"
                  />
                </Typography>
                <UploadFileCommon
                  keyCaption="imageUpload_text"
                  contentCaption="Image Upload


"
                />
              </Grid>
              <Grid item xs={12}>
                <AutoCompleteCommon
                  keyField="homepage_featureDestination_addmoreForm_countriesField"
                  contentField="Countries"
                  defaultData={[]}
                  bgTitle="#ebebeb"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldCommon
                  keyField="homepage_featureDestination_addmoreForm_fromField"
                  contentField="From"
                  bgTitle="#ebebeb"
                  slotIcon={<AttachMoney sx={{ mt: 1 }} />}
                  classHeight="h-45"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldCommon
                  keyField="urlField"
                  contentField="URL"
                  bgTitle="#ebebeb"
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Stack alignItems={"flex-end"}>
                  <ButtonCommon contentField="Apply" keyField="applyButton" />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={3} py={2}>
          <Grid item xs={12}>
            <Typography className="text-18 fw-600">
              Current Feature Destination{" "}
              <EditButton
                keyField={
                  "homepage_featureDestination_currentFeatureDestination"
                }
                contentField="Current Feature Destination"
              />
            </Typography>
           
          </Grid> {Array(2)
              .fill(0)
              .map((_, index) => (
                <Grid key={index} item xs={12} md={3}>
                  <Box className={"position-relative h-100 rounded-10"}>
                    <Box
                      className="position-absolute left-0 top-0 w-full h-100 rounded-10 zIndex-1"
                      sx={{ background: "rgba(0, 0, 0, 0.5)" }}
                    ></Box>
                    <img
                      className="position-absolute right-0 top-0 w-full h-100  rounded-10"
                      src={
                        "https://content-platform-tkg.s3.ap-southeast-2.amazonaws.com/development/TWT/feature-destination/2024-06/giola-the-natural-swimming-pool-in-thassos-island-greece-1718247541.jpg"
                      }
                    />

                    <Typography className="position-absolute zIndex-1 left-15 top-10 text-white fw-600">
                      <Stack spacing={1}>
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w20/be.png 2x`}
                          src={`https://flagcdn.com/w20/be.png`}
                          alt=""
                        />
                        {"Belgium"}
                      </Stack>
                    </Typography>
                    <Typography className="position-absolute zIndex-1 bottom-10 right-15 border-full bg-tkg-blue text-white fw-600 rounded-20 px-10 py-5">
                      From{" "}
                      <EditButton
                        keyField={"homepage_featureDestination_priceFrom"}
                        contentField="From"
                      />{" "}
                      $1,234
                    </Typography>
                  </Box>
                </Grid>
              ))}
        </Grid>
      </Paper>
    </>
  );
};

export default FeatureDestinationPage;
