import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../components/common/EditButton'

const FinalStep = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={4} alignItems={"center"}>
          <Typography className="text-22 fw-700 text-center">Thank you for choosing Tweet World Travel. <EditButton keyField="bookingPage_finalStep_thankYou" contentField="Thank you for choosing Tweet World Travel." /></Typography>
          <Typography className="text-18 fw-600 text-center">Your booking request reference is: <EditButton keyField="bookingPage_finalStep_bookingReference" contentField="Your booking request reference is:" /> <strong>TWT78613</strong> </Typography>
          <Typography className="text-14 text-center">We have received your booking request and it is being processed by our operation team. <EditButton keyField="bookingPage_finalStep_processingBookingRequest" contentField="We have received your booking request and it is being processed by our operation team." /> </Typography>
          <Typography className="text-14 text-center">We will be in touch with you shortly about your booking request. <EditButton keyField="bookingPage_finalStep_inTouchWithYou" contentField="We will be in touch with you shortly about your booking request." /> </Typography>
          <Typography className="text-14 text-center w-full">For further enquiries, please contact our Head Office via email <EditButton keyField="bookingPage_finalStep_forFurtherEnquiries" contentField="For further enquiries, please contact our Head Office via email" /> 
          <span className='text-tkg-blue'>contact@tweetworldtravel.com</span> {""}
          or call us on <EditButton keyField="bookingPage_finalStep_orCallUs" contentField="or call us on" /> <strong>1300 739 652 <EditButton keyField="bookingPage_finalStep_phoneNumber" contentField="1300 739 652" /></strong>
           </Typography>
        
           <Typography className="text-14 text-center" sx={{textDecoration: "italic"}}>Please check your email for the confirmation of your booking request sent to us. <EditButton keyField="bookingPage_finalStep_pleaseCheckYourEmail" contentField="Please check your email for the confirmation of your booking request sent to us." /> </Typography>
      </Stack>
    </Box>
  )
}

export default FinalStep