import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WrapperCard from "../../components/common/WrapperCard";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import ButtonCommon from "../../components/common/ButtonCommon";
import { Search } from "@mui/icons-material";

const AdvancedSearch = () => {
  return (
    <WrapperCard
      cardTitleContent="Advance Search"
      cardTitleKey={
        "homepage_heroSection_advancedSearch_titleHeader"
      }
      children={
        <Box p={3} py={4}>
          <Grid container spacing={5} alignItems={"flex-end"}>
            <Grid item xs={12} md={10}>
              <TextFieldCommon
                keyField="homepage_heroSection_advancedSearch_destinationTitle"
                contentField="Destination/trip code"
                placeHolderKey={
                  "homepage_heroSection_advancedSearch_destinationPlaceholder"
                }
                placeHolder="Where are you going?"
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldCommon
                keyField="homepage_heroSection_advancedSearch_departureMonthTitle"
                contentField="Departure Month"
                placeHolderKey={
                  "homepage_heroSection_advancedSearch_departureMonthPlaceholder"
                }
                placeHolder="Select month"
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldCommon
                keyField="homepage_heroSection_advancedSearch_countryTitle"
                contentField="Country"
                placeHolderKey={
                  "homepage_heroSection_advancedSearch_countryPlaceholder"
                }
                placeHolder="Select country"
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldCommon
                keyField="homepage_heroSection_advancedSearch_durationTitle"
                contentField="Duration"
                placeHolderKey={
                  "homepage_heroSection_advancedSearch_durationPlaceholder"
                }
                placeHolder="Select Duration"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <ButtonCommon
                startIcon={<Search sx={{ mr: "5px" }} />}
                keyField={
                  "homepage_heroSection_advancedSearch_searchButton"
                }
                contentField="Search"
              />
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

export default AdvancedSearch;
