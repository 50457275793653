import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import UploadFileCommon from './UploadFileCommon'
import EditButton from './EditButton'
import { Delete } from '@mui/icons-material'
import SampleImage from "../../media/images/example-upload.jpg";

interface Props {
  isRevert?: boolean;
  showFormat?: boolean;
  classWidth?: string
}
const UploadImageAndSampleCommon = ({isRevert, showFormat=false, classWidth="w-half"}: Props) => {
  return (
    <Box className={`d-flex gap-30 ${classWidth}`} sx={{ flexDirection: isRevert ? "row-reverse" : "row"}}>
              <Box className="w-half">
                <UploadFileCommon keyCaption="fileUpload_caption" contentCaption='Upload File' />
                <Typography className="text-14 mt-5">
                  Size{" "}
                  <EditButton
                    keyField={"fileUpload_size"}
                    contentField="Size"
                  />
                  : 1440 X 463 Pixel{" "}
                  <EditButton
                    keyField={"fileUpload_unit"}
                    contentField="Pixel"
                  />
                </Typography>
                {showFormat &&
                  <Typography className="text-14 mt-5">
                  Image Format{" "}
                  <EditButton
                    keyField={"fileUpload_format"}
                    contentField="Image Format"
                  />
                  : Jpeg, PNG{" "}
                  <EditButton
                    keyField={"fileUpload_formatUnit"}
                    contentField="Jpeg, PNG"
                  />
                </Typography>
                }
              </Box>
              <Box className="w-half">
                <Stack direction={"row"} alignItems={"flex-end"}>
                  <img src={SampleImage} alt="why-choose-us" />{" "}
                  <Delete color="error" />
                </Stack>
              </Box>
            </Box>
  )
}

export default UploadImageAndSampleCommon