import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import ButtonCommon from "../../components/common/ButtonCommon";
import BrochureSample1 from "../../media/images/brochure1.png";
import BrochureSample2 from "../../media/images/brochure2.jpg";

import { CloudUpload, Delete, Edit } from "@mui/icons-material";
import UploadFileCommon from "../../components/common/UploadFileCommon";

const CardAdjustments = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
      <Typography className="text-16 fw-600 text-tkg-blue mb-20">
        Card Adjustments{" "}
        <EditButton
          keyField={"homepage_knowMore_flyerBrochure_cardAdjustments_header"}
          contentField="Card Adjustments"
        />
      </Typography>
      <Stack spacing={5}>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            41/25
          </Typography>
          <TextFieldCommon
            keyField={
              "titleField"
            }
            contentField="Title"
          />
        </Box>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton keyField={"checkLength"} contentField="Characters" />:
            157/55
          </Typography>

          <TextFieldCommon
            keyField={
              "subtitleField"
            }
            contentField="Subtitle"
          />
        </Box>

        <Box>
          <Grid container spacing={6}>
            <Grid item xs={12} md={3}>
              <Box className="d-inline-block">
                <ButtonCommon
                  keyField={`homepage_knowMore_flyerBrochure_cardAdjustments_downloadButton`}
                  contentField={"Download now!"}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={6}>
            <Grid item xs={12} md={3}>
              <Box>
                <Box className="d-flex">
                  <img src={BrochureSample1} className="h-400" />
                  <Stack justifyContent={"flex-end"} spacing={2}>
                    <Edit />
                    <Delete color="error" />
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box>
                <Box className="d-flex">
                  <img src={BrochureSample2} className="h-400" />
                  <Stack justifyContent={"flex-end"} spacing={2}>
                    <Edit />
                    <Delete color="error" />
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={3}>
              <Box className="d-flex h-full align-end justify-end">
                <ButtonCommon
                  startIcon={<CloudUpload sx={{ mr: "5px" }} />}
                  contentField="Add Image"
                  keyField="homepage_bestSellingProduct_content_addImageButton"
                ></ButtonCommon>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container mt={3} py={2}>
          <Grid item xs={12} md={5} bgcolor={"#ebebeb"} p={2}>
            <Typography className="text-28 text-danger fw-600 pl-16">
              Add Brochure Pop Up
            </Typography>

            <Grid container spacing={3} mt={2}>
              <Grid item xs={12} md={6}>
                <img src={BrochureSample1} className="h-200" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-16">
                  Image Size{" "}
                  <EditButton
                    keyField={"fileUpload_size"}
                    contentField="Image Size"
                  />
                  : 640 X 316 Pixel{" "}
                  <EditButton
                    keyField={"fileUpload_unit"}
                    contentField="Pixel"
                  />
                </Typography>
                <Typography className="text-14 mt-5">
                  Image Format{" "}
                  <EditButton
                    keyField={"fileUpload_format"}
                    contentField="Image Format"
                  />
                  : Jpeg, Png{" "}
                  <EditButton
                    keyField={"fileUpload_formatUnit"}
                    contentField="Jpeg, PNG"
                  />
                </Typography>
                <UploadFileCommon
                  keyCaption="pdfUpload_text"
                  contentCaption="PDF Upload"
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldCommon
                  keyField="urlField"
                  contentField="URL"
                  bgTitle="#ebebeb"
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <Stack alignItems={"flex-end"}>
                  <ButtonCommon contentField="Apply" keyField="applyButton" />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default CardAdjustments;
