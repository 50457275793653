import {
  CopyAll,
  Download,
  Edit,
  Info,
  Receipt,
  RemoveRedEye,
  Send,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import ButtonCommon from "../../components/common/ButtonCommon";
import TablePriceRoom from "./TablePriceRoom";
import TablePaymentDetails from "./TablePaymentDetails";

const ManageBooking = () => {
  return (
    <Stack spacing={5}>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Typography className="text-18 fw-600 text-tkg-blue">
                OVERVIEW
                <EditButton
                  keyField={"manage_booking_overview"}
                  contentField="OVERVIEW"
                />
                
              </Typography>
              <Typography className="text-18 text-danger">
                Cancel Booking{" "}
                <EditButton
                  keyField={"manage_booking_cancelBooking"}
                  contentField="Cancel Booking"
                />
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack alignItems={"flex-end"}>
              <Typography className="text-14">
                Booking Ref{" "}
                <EditButton
                  keyField={"manage_booking_bookingRef"}
                  contentField="Booking Ref"
                />
                : TWT78613
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="text-14">
              Booked{" "}
              <EditButton
                keyField={"manage_booking_booked"}
                contentField="Booked"
              />
              : Min Park @ 13-Aug-2024 07:59
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="text-14">
              Modified{" "}
              <EditButton
                keyField={"manage_booking_modified"}
                contentField="Modified"
              />
              : Min Park @ 13-Aug-2024 07:59
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography className="text-18 fw-600 text-tkg-blue">
              PASSENGER DETAILS{" "}
              <EditButton
                keyField={"manage_booking_passengerDetails"}
                contentField="PASSENGER DETAILS"
              />
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              spacing={2}
            >
              <Typography className="text-14">
                Passenger Information Online Form{" "}
                <EditButton
                  keyField={"manage_booking_passengerInformationOnlineForm"}
                  contentField="Passenger Information Online Form"
                />
              </Typography>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<RemoveRedEye />}
                  bgColor="bg-white"
                  textColor="text-tkg-blue"
                  borderRadius="rounded-8"
                  keyField="viewButton"
                  contentField="View"
                />
              </div>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<CopyAll />}
                  bgColor="bg-white"
                  textColor="text-tkg-blue"
                  borderRadius="rounded-8"
                  keyField="copyLinkButton"
                  contentField="Copy Link"
                />
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Typography className="text-14">
              ROOM <EditButton keyField={"room"} contentField="ROOM " />: 1
            </Typography>
            <Typography className="text-14">
              Bedding Config Request <EditButton keyField={"beddingConfigRequestField"} contentField="Bedding Config Request" />
            </Typography>
</Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack alignItems={"flex-end"}>
              <div className="d-inline-block">
                <ButtonCommon
                  bgColor="bg-orange"
                  textColor="text-white"
                  borderRadius="rounded-8"
                  keyField="updateDetailButton"
                  contentField="Update Details"
                />
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography className="text-14">
              Passenger{" "}
              <EditButton keyField={"passenger"} contentField="Passenger" />: 1
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Title{" "}
                  <EditButton keyField={"titleField"} contentField="Title" />:
                  Ms
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Medical Concern{" "}
                  <EditButton
                    keyField={"medicalConcernContentField"}
                    contentField="Medical Concern"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  First Name{" "}
                  <EditButton
                    keyField={"firstNameField"}
                    contentField="First Name"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Special Assistant{" "}
                  <EditButton
                    keyField={"specialAssistanceContentField"}
                    contentField="Special Assistant"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Middle Name{" "}
                  <EditButton
                    keyField={"middleNameField"}
                    contentField="Middle Name"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Dietary{" "}
                  <EditButton
                    keyField={"dietaryRequestContentField"}
                    contentField="Dietary"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Surname{" "}
                  <EditButton
                    keyField={"surnameField"}
                    contentField="Surname"
                  />
                  : TRaveller
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-18">
                  Emergency Contact Details{" "}
                  <EditButton
                    keyField={"emergencyContactDetailsField"}
                    contentField="Emergency Contact Details"
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Date of Birth{" "}
                  <EditButton
                    keyField={"dobField"}
                    contentField="Date of Birth"
                  />
                  : 11-Apr-2024
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Emergency Contact Name{" "}
                  <EditButton
                    keyField={"emergencyContactNameField"}
                    contentField="Emergency Contact Name"
                  />
                  : Le Ha
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Nationality{" "}
                  <EditButton
                    keyField={"nationalityField"}
                    contentField="Nationality"
                  />
                  : Australia
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Emergency Phone{" "}
                  <EditButton
                    keyField={"emergencyContactPhoneNumberField"}
                    contentField="Emergency Phone"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Country of Passport{" "}
                  <EditButton
                    keyField={"countryOfPassportField"}
                    contentField="Country of Passport"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Emergency Contact Email{" "}
                  <EditButton
                    keyField={"emergencyContactEmailField"}
                    contentField="Emergency Contact Email"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Passport No{" "}
                  <EditButton
                    keyField={"passportNumberField"}
                    contentField="Passport No"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="text-14">
                  Expired Date{" "}
                  <EditButton
                    keyField={"expiryDateField"}
                    contentField="Expired Date"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="text-14">
                  Email{" "}
                  <EditButton keyField={"emailField"} contentField="Email" />:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="text-14">
                  Phone number{" "}
                  <EditButton
                    keyField={"phoneNumberField"}
                    contentField="Phone number"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="text-18">
                  DOCUMENT{" "}
                  <EditButton
                    keyField={"documentField"}
                    contentField="DOCUMENT"
                  />
                  <Tooltip
                    title={
                      <Typography className="text-12">
                        International Flight/ Travel Insurance/ Visa/ Passport{" "}
                        <EditButton
                          keyField={"documentInfoTooltipTitle"}
                          contentField="International Flight/ Travel Insurance/ Visa/ Passport"
                        />
                        :
                      </Typography>
                    }
                  >
                    <Info sx={{ fontSize: 16 }} />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="text-14">
                  Phone number{" "}
                  <EditButton
                    keyField={"phoneNumberField"}
                    contentField="Phone number"
                  />
                  :
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextFieldCommon keyField={"titleField"} contentField="Title" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldCommon
                  keyField={"firstNameField"}
                  contentField="First Name"
                />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={4}>
                <TextFieldCommon
                  keyField={"middleNameField"}
                  contentField="Middle Name"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldCommon
                  keyField={"surnameField"}
                  contentField="Surname"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Do you have any medical concern (Yes/No){" "}
                  <EditButton
                    keyField={"medicalConcernField"}
                    contentField="Do you have any medical concern (Yes/No)"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldCommon
                  keyField={""}
                  contentField=""
                  placeHolder="If Yes, please provide in details"
                  placeHolderKey="medicalConcernPlaceholder"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldCommon
                  keyField={"dobField"}
                  contentField="Date of Birth"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldCommon
                  keyField={"countryOfPassportField"}
                  contentField="Country of Passport"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Do you require special assistance (Yes/No){" "}
                  <EditButton
                    keyField={"specialAssistanceField"}
                    contentField="Do you require special assistance (Yes/No)"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldCommon
                  keyField={""}
                  contentField=""
                  placeHolder="If Yes, please provide in details"
                  placeHolderKey="specialAssistancePlaceholder"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldCommon
                  keyField={"passportNumberField"}
                  contentField="Passport No."
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldCommon
                  keyField={"expiryDateField"}
                  contentField="Expired Date"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-14">
                  Do you have any dietary request? (Yes/No){" "}
                  <EditButton
                    keyField={"dietaryRequestField"}
                    contentField="Do you have any dietary request? (Yes/No)"
                  />
                  :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldCommon
                  keyField={""}
                  contentField=""
                  placeHolder="If Yes, please provide in details"
                  placeHolderKey="dietaryRequestPlaceholder"
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <TextFieldCommon keyField={"emailField"} contentField="Email" />
              </Grid>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={4}>
                <TextFieldCommon
                  keyField={"countryCodeField"}
                  contentField="Country Code"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldCommon
                  keyField={"phoneNumberField"}
                  contentField="Phone Number"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="text-14 mb-10">
                  Emergency Contact Details{" "}
                  <EditButton
                    keyField={"emergencyContactDetailsField"}
                    contentField="Emergency Contact Details"
                  />
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextFieldCommon keyField={"nameField"} contentField="Name" />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldCommon keyField={"emailField"} contentField="Email" />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldCommon
                  keyField={"countryCodeField"}
                  contentField="Country Code"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldCommon
                  keyField={"phoneNumberField"}
                  contentField="Phone Number"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Typography className="text-18 fw-600 text-tkg-blue">
                TOUR DETAILS{" "}
                <EditButton
                  keyField={"manage_booking_tourDetails"}
                  contentField="TOUR DETAILS"
                />
              </Typography>
              <Typography className="text-18 text-danger">
                Amend{" "}
                <EditButton
                  keyField={"manage_booking_amend"}
                  contentField="Amend"
                />
              </Typography>
              <Typography className="text-18 text-danger">
                Cancel Booking{" "}
                <EditButton
                  keyField={"manage_booking_cancelBooking"}
                  contentField="Cancel Booking"
                />
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack alignItems={"flex-end"}>
              <Typography className="text-14">
                STATUS{" "}
                <EditButton
                  keyField={"manage_booking_status"}
                  contentField="STATUS"
                />
                : UNCONFIRMED
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="text-20 mb-20">
              VIETNAM SCHOOL TOUR - COMMUNITY PROJECT FIELD TREK
            </Typography>
            <Typography className="text-14 mb-10">
              Accommodation{" "}
              <EditButton
                keyField={"manage_booking_accommodation"}
                contentField="Accommodation"
              />
              :
            </Typography>
            <Typography className="text-14 mb-10">
              From{" "}
              <EditButton
                keyField={"manage_booking_from"}
                contentField="From"
              />
              : 30-Aug-2024
            </Typography>
            <Typography className="text-14 mb-10">
              To <EditButton keyField={"manage_booking_to"} contentField="To" />
              : 09-Sep-2024
            </Typography>
            <Typography className="text-14 mb-10">
              Nights{" "}
              <EditButton
                keyField={"manage_booking_nights"}
                contentField="Nights"
              />
              : 10 nights
            </Typography>
            <Typography className="text-14 mb-10">
              Trip Code{" "}
              <EditButton
                keyField={"manage_booking_tripcode"}
                contentField="Trip Code"
              />
              : VNSC37124
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={"row"} alignItems={"center"} spacing={2} mb={2}>
              <Typography className="text-14 mb-10">
                ITINERARY{" "}
                <EditButton
                  keyField={"manage_booking_itinerary"}
                  contentField="ITINERARY"
                />
              </Typography>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<RemoveRedEye />}
                  bgColor="bg-white"
                  textColor="text-tkg-blue"
                  borderRadius="rounded-8"
                  keyField="viewButton"
                  contentField="View"
                />
              </div>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<Download />}
                  bgColor="bg-white"
                  textColor="text-success"
                  borderRadius="rounded-8"
                  keyField="downloadPDFButton"
                  contentField="Download PDF"
                />
              </div>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<Send />}
                  bgColor="bg-white"
                  textColor="text-success"
                  borderRadius="rounded-8"
                  keyField="sendEmailButton"
                  contentField="Send Email"
                />
              </div>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={2} mb={2}>
              <Typography className="text-14 mb-10">
                INVOICE{" "}
                <EditButton
                  keyField={"manage_booking_invoice"}
                  contentField="INVOICE"
                />
              </Typography>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<RemoveRedEye />}
                  bgColor="bg-white"
                  textColor="text-tkg-blue"
                  borderRadius="rounded-8"
                  keyField="viewButton"
                  contentField="View"
                />
              </div>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<Download />}
                  bgColor="bg-white"
                  textColor="text-success"
                  borderRadius="rounded-8"
                  keyField="downloadPDFButton"
                  contentField="Download PDF"
                />
              </div>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<Send />}
                  bgColor="bg-white"
                  textColor="text-success"
                  borderRadius="rounded-8"
                  keyField="sendEmailButton"
                  contentField="Send Email"
                />
              </div>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={2} mb={2}>
              <Typography className="text-14 mb-10">
                TRAVEL VOUCHER{" "}
                <EditButton
                  keyField={"manage_booking_travel_voucher"}
                  contentField="TRAVEL VOUCHER"
                />
              </Typography>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<RemoveRedEye />}
                  bgColor="bg-white"
                  textColor="text-tkg-blue"
                  borderRadius="rounded-8"
                  keyField="viewButton"
                  contentField="View"
                />
              </div>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<Download />}
                  bgColor="bg-white"
                  textColor="text-success"
                  borderRadius="rounded-8"
                  keyField="downloadPDFButton"
                  contentField="Download PDF"
                />
              </div>
              <div className="d-inline-block">
                <ButtonCommon
                  startIcon={<Send />}
                  bgColor="bg-white"
                  textColor="text-success"
                  borderRadius="rounded-8"
                  keyField="sendEmailButton"
                  contentField="Send Email"
                />
              </div>
            </Stack>

            
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Receipt
                sx={{
                  fontSize: 18,
                }}
              />
              <Typography className="text-14">
                PAYMENT DETAILS{" "}
                <EditButton
                  keyField={"manage_booking_package_detail"}
                  contentField="PAYMENT DETAILS"
                />
              </Typography>{" "}
              (
              <Typography className="text-14">
                Currency{" "}
                <EditButton
                  keyField={"manage_booking_currency"}
                  contentField="Currency"
                />
                : USD
              </Typography>
              )
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack alignItems={"flex-end"}>
              <Typography className="text-14">
                PAYMENT STATUS{" "}
                <EditButton
                  keyField={"manage_booking_package_status"}
                  contentField="PAYMENT STATUS"
                />
                : UNPAID
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography className="text-14 mb-10">
              ROOM <EditButton keyField={"room"} contentField="ROOM " />: 1
            </Typography>
            <TablePriceRoom />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography className="text-14 mb-10">
          AGENT PAYMENT DETAILS <EditButton keyField={"manage_booking_agent_payment_details"} contentField="AGENT PAYMENT DETAILS " />
            </Typography>
            <TablePaymentDetails />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
             
              <Typography className="text-18 text-danger">
              BOOKING CONDITIONS
            
                <EditButton
                  keyField={"manage_booking_bookingConditions"}
                  contentField="Booking Conditions"
                />
              </Typography>
          </Grid>

         
        </Grid>
      </Paper>
    </Stack>
  );
};

export default ManageBooking;
