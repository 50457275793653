import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import InspirationBg from "../../../../media/images/inspiration.png";
import InspirationCard from "../../../../media/images/inspirationCard.png";
import ButtonCommon from "../../../../components/common/ButtonCommon";
import EditButton from "../../../../components/common/EditButton";
import {
  Facebook,
  KeyboardArrowDown,
  Share,
  ThumbUp,
  YouTube,
} from "@mui/icons-material";
import moment from "moment";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
const ViewInspirationPage = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
      <Box>
        <img
          src={InspirationBg}
          alt="inspiration"
          style={{ width: "100%", objectFit: "cover" }}
        />
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ p: 3 }}
        >
          <Box>
            <Typography className="text-14 fw-600">
              Filter Contents By{" "}
              <EditButton
                keyField={
                  "navigationMenu_inspiration_view_dropdownMenu_filterContentsBy"
                }
                contentField="Filter Contents By"
              />
            </Typography>
          </Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <Typography className="flex-center text-14 fw-600">
              Destination{" "}
              <EditButton
                keyField={
                  "navigationMenu_inspiration_view_dropdownMenu_destination"
                }
                contentField="Destination"
              />{" "}
              <KeyboardArrowDown sx={{ color: "#ff7017" }} />
            </Typography>
            <Typography className="flex-center text-14 fw-600">
              Country{" "}
              <EditButton
                keyField={
                  "navigationMenu_inspiration_view_dropdownMenu_country"
                }
                contentField="Country"
              />{" "}
              <KeyboardArrowDown sx={{ color: "#ff7017" }} />
            </Typography>
            <Typography className="flex-center text-14 fw-600">
              Tour Type{" "}
              <EditButton
                keyField={
                  "navigationMenu_inspiration_view_dropdownMenu_tourType"
                }
                contentField="Tour Type"
              />{" "}
              <KeyboardArrowDown sx={{ color: "#ff7017" }} />
            </Typography>
            <Typography className="flex-center text-14 fw-600">
              Video{" "}
              <EditButton
                keyField={"navigationMenu_inspiration_view_dropdownMenu_video"}
                contentField="Video"
              />{" "}
              <KeyboardArrowDown sx={{ color: "#ff7017" }} />
            </Typography>
            <Typography className="flex-center text-14 fw-600">
              Photo Album{" "}
              <EditButton
                keyField={
                  "navigationMenu_inspiration_view_dropdownMenu_photoAlbum"
                }
                contentField="Photo Album"
              />{" "}
              <KeyboardArrowDown sx={{ color: "#ff7017" }} />
            </Typography>
          </Stack>
          <Box>
            <ButtonCommon contentField="Apply" keyField="applyButton" />
          </Box>
        </Stack>

        <Stack className="mt-30" spacing={2}>
          <Typography className="text-44 fw-600">
            Explore The World{" "}
            <EditButton
              keyField={
                "navigationMenu_inspiration_view_dropdownMenu_categories_header"
              }
              contentField="Explore The World"
            />
          </Typography>
          <Box>
            <Typography className="text-14 fw-600">
              Most Visited Categories{" "}
              <EditButton
                keyField={
                  "navigationMenu_inspiration_view_dropdownMenu_categories_title"
                }
                contentField="Most Visited Categories"
              />
            </Typography>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Chip label="Luxury Rail" variant="outlined" />
              <Chip label="Vietnam" variant="outlined" />
              <Chip label="Japan" variant="outlined" />
              <Chip label="Korea" variant="outlined" />
              <Typography>
                All Categories{" "}
                <EditButton
                  keyField={
                    "navigationMenu_inspiration_view_dropdownMenu_categories_allCategories"
                  }
                  contentField="All Categories"
                />
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Card>
              <CardHeader
                action={
                  <>
                    <IconButton aria-label="settings">
                      <Share sx={{ fontSize: "18px" }} />
                    </IconButton>
                    <IconButton aria-label="settings">
                      <ThumbUp sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </>
                }
                subheaderTypographyProps={{
                  fontSize: "14px",
                }}
                subheader={moment().format("DD MMM, YYYY")}
              />

              <CardContent className="p-10">
                <Box sx={{ mb: 2 }}>
                  <Typography className="text-22 fw-600">
                    Ready To Go On A Global Golf Adventure?
                  </Typography>
                  <Typography className="text-14">
                    Ready to go on a "global golf adventure"? At Tweet Tours, we
                    believe your passion for golf deserves to be celebrated with
                    extraordinary experiences.
                  </Typography>
                  <Box className="d-inline-block mt-10">
                    <ButtonCommon
                      bgColor="bg-white"
                      textColor="text-tkg-blue"
                      borderColor="border-color-primary"
                      contentField="Continue Reading"
                      keyField="continueReadingButton"
                    />
                  </Box>
                </Box>
                <img
                  src={InspirationCard}
                  alt="inspiration card"
                  className="rounded-10"
                  style={{ objectFit: "cover" }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }} className="rounded-10">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-28 fw-600">
                    Lastest{" "}
                    <EditButton
                      keyField={
                        "navigationMenu_inspiration_view_inspirationCard_lastestComponent_title"
                      }
                      contentField="Lastest"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="194"
                      image={InspirationCard}
                      alt="Inspiration card"
                    />{" "}
                    <CardHeader
                      action={
                        <>
                          <IconButton aria-label="settings">
                            <Share sx={{ fontSize: "18px" }} />
                          </IconButton>
                          <IconButton aria-label="settings">
                            <ThumbUp sx={{ fontSize: "18px" }} />
                          </IconButton>
                        </>
                      }
                      subheaderTypographyProps={{
                        fontSize: "14px",
                      }}
                      subheader={moment().format("DD MMM, YYYY")}
                    />
                    <CardContent className="p-10">
                      <Box sx={{ mb: 2 }}>
                        <Typography className="text-22 fw-600">
                          Ready To Go On A Global Golf Adventure?
                        </Typography>
                        <Typography className="text-14">
                          Ready to go on a "global golf adventure"? At Tweet
                          Tours, we believe your passion for golf deserves to be
                          celebrated with extraordinary experiences.
                        </Typography>
                        <Stack alignItems={"flex-end"}>
                          <Box className="d-inline-block mt-10">
                            <ButtonCommon
                              contentField="Continue Reading"
                              keyField="continueReadingButton"
                            />
                          </Box>
                        </Stack>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="194"
                      image={InspirationCard}
                      alt="Inspiration card"
                    />{" "}
                    <CardHeader
                      action={
                        <>
                          <IconButton aria-label="settings">
                            <Share sx={{ fontSize: "18px" }} />
                          </IconButton>
                          <IconButton aria-label="settings">
                            <ThumbUp sx={{ fontSize: "18px" }} />
                          </IconButton>
                        </>
                      }
                      subheaderTypographyProps={{
                        fontSize: "14px",
                      }}
                      subheader={moment().format("DD MMM, YYYY")}
                    />
                    <CardContent className="p-10">
                      <Box sx={{ mb: 2 }}>
                        <Typography className="text-22 fw-600">
                          Ready To Go On A Global Golf Adventure?
                        </Typography>
                        <Typography className="text-14">
                          Ready to go on a "global golf adventure"? At Tweet
                          Tours, we believe your passion for golf deserves to be
                          celebrated with extraordinary experiences.
                        </Typography>
                        <Stack alignItems={"flex-end"}>
                          <Box className="d-inline-block mt-10">
                            <ButtonCommon
                              contentField="Continue Reading"
                              keyField="continueReadingButton"
                            />
                          </Box>
                        </Stack>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>{" "}
                <Grid item xs={12} md={3}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="194"
                      image={InspirationCard}
                      alt="Inspiration card"
                    />{" "}
                    <CardHeader
                      action={
                        <>
                          <IconButton aria-label="settings">
                            <Share sx={{ fontSize: "18px" }} />
                          </IconButton>
                          <IconButton aria-label="settings">
                            <ThumbUp sx={{ fontSize: "18px" }} />
                          </IconButton>
                        </>
                      }
                      subheaderTypographyProps={{
                        fontSize: "14px",
                      }}
                      subheader={moment().format("DD MMM, YYYY")}
                    />
                    <CardContent className="p-10">
                      <Box sx={{ mb: 2 }}>
                        <Typography className="text-22 fw-600">
                          Ready To Go On A Global Golf Adventure?
                        </Typography>
                        <Typography className="text-14">
                          Ready to go on a "global golf adventure"? At Tweet
                          Tours, we believe your passion for golf deserves to be
                          celebrated with extraordinary experiences.
                        </Typography>
                        <Stack alignItems={"flex-end"}>
                          <Box className="d-inline-block mt-10">
                            <ButtonCommon
                              contentField="Continue Reading"
                              keyField="continueReadingButton"
                            />
                          </Box>
                        </Stack>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={9}>
                  <Stack alignItems={"flex-end"}>
                    <Box className="d-inline-block">
                      <ButtonCommon
                        bgColor="bg-white"
                        textColor="text-tkg-blue"
                        borderColor="border-color-primary"
                        contentField="All"
                        keyField="allButton"
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ p: 2 }} className="rounded-10">
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className="text-22 fw-600">
                        Submit Your Email To Join Our Newsletter{" "}
                        <EditButton
                          keyField="navigationMenu_inspiration_view_newsletterSubscription_header"
                          contentField="Submit Your Email To Join Our Newsletter"
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldCommon
                        keyField=""
                        contentField=""
                        placeHolder="First name"
                        placeHolderKey="navigationMenu_inspiration_view_newsletterSubscription_firstNameFieldPlaceholder"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldCommon
                        keyField=""
                        contentField=""
                        placeHolder="Last name"
                        placeHolderKey="navigationMenu_inspiration_view_newsletterSubscription_lastNameFieldPlaceholder"
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <TextFieldCommon
                        keyField=""
                        contentField=""
                        placeHolder="Email address"
                        placeHolderKey="navigationMenu_inspiration_view_newsletterSubscription_emailAddressFieldPlaceholder"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <ButtonCommon contentField="Join" keyField="joinButton" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }} className="rounded-10">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography className="text-28 fw-600" sx={{ color: "#ff7017" }}>
                      Follow Us{" "}
                      <EditButton
                        keyField="navigationMenu_inspiration_view_FollowUs_header"
                        contentField="Follow Us"
                      />
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <YouTube sx={{ color: "#00668d", fontSize: "40px" }} />
                      <Facebook sx={{ color: "#00668d", fontSize: "40px" }} />
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <img
                    src={InspirationBg}
                    alt="Inspiration background"
                    className="rounded-10"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ViewInspirationPage;
