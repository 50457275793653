import { Stack } from "@mui/material";
import WebsiteLogo from "./WebsiteLogo";
import WishlishDisplay from "./WishlistDisplay";
import ChooseLanguageAndCurrency from "./ChooseLanguageAndCurrency";
import AddMenuBar from "./AddMenuBar";
import ButtonCommon from "../../components/common/ButtonCommon";
import AgentPortal from "./AgentPortal";


const MasterSetupPage = () => {

  return (
    <Stack spacing={2}>
        <WebsiteLogo />
        <WishlishDisplay />
        <ChooseLanguageAndCurrency />
        <AgentPortal />
        <AddMenuBar />
        <Stack alignItems={"flex-end"}>
          <ButtonCommon keyField="masterSetup_save" contentField="Save" />
        </Stack>
    </Stack>
    
  );
};

export default MasterSetupPage;
