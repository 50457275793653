import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import TextFieldCommon from "../../../components/common/TextFieldCommon";
import EditButton from "../../../components/common/EditButton";
import CheckboxCommon from "../../../components/common/CheckboxCommon";
import ButtonCommon from "../../../components/common/ButtonCommon";
import { CheckBox, Clear } from "@mui/icons-material";
import AutoCompleteCommon from "../../../components/common/AutoCompleteCommon";

const RegistrationAgentTab = () => {
  return (
    <>
      <Box>
        <Stack spacing={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Typography className="text-28 fw-600">
                  AGENCY DETAILS
                  <EditButton
                    keyField="loginPage_agentResgistration_agencyDetails_title"
                    contentField="AGENCY DETAILS"
                  />
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {" "}
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_companyNameField"
                      contentField="Company Name"
                      placeHolder="Enter Company Name"
                      placeHolderKey="loginPage_agentResgistration_companyNamePlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_agencyTradingNameField"
                      contentField="Agency Trading Name"
                      placeHolder="Enter Trading Name"
                      placeHolderKey="loginPage_agentResgistration_tradingNamePlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_companyWebsiteField"
                      contentField="Company Website"
                      placeHolder="Website URL"
                      placeHolderKey="loginPage_agentResgistration_websiteURLPlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_industryAccreditationNumberField"
                      contentField="Industry Accreditation Number"
                      placeHolder="ATAS, AFTA, CATO, Travel License No, etc..."
                      placeHolderKey="loginPage_agentResgistration_industryAccreditationNumberPlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_companyRegistrationNumberField"
                      contentField="Company Registration Number"
                      placeHolder="ABN, ACN, or other License Number"
                      placeHolderKey="loginPage_agentResgistration_companyRegistrationNumberPlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_consortiumField"
                      contentField="Consortium"
                      placeHolder="What travel group do you belong to?"
                      placeHolderKey="loginPage_agentResgistration_consortiumPlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_streetAddressField"
                      contentField="Street Address"
                      placeHolder="Enter Street Address"
                      placeHolderKey="loginPage_agentResgistration_streetAddressPlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_cityField"
                      contentField="Suburb / City"
                      placeHolder="City or Suburb"
                      placeHolderKey="loginPage_agentResgistration_cityPlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_postCodeField"
                      contentField="Post Code"
                      placeHolder="City or Suburd postcode"
                      placeHolderKey="loginPage_agentResgistration_postCodePlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_stateField"
                      contentField="State / Region"
                      placeHolder="Enter State / Region"
                      placeHolderKey="loginPage_agentResgistration_statePlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <AutoCompleteCommon
                      keyField="loginPage_agentResgistration_countryField"
                      contentField="Country"
                      defaultData={["Australia"]}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_ifOtherField"
                      contentField="If Other"
                      placeHolder="If Other Country"
                      placeHolderKey="loginPage_agentResgistration_ifOtherPlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_phoneField"
                      contentField="Phone"
                      placeHolder="Phonemain"
                      placeHolderKey="loginPage_agentResgistration_phonePlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_faxField"
                      contentField="Fax"
                      placeHolder="Enter Fax"
                      placeHolderKey="loginPage_agentResgistration_faxPlaceholder"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_companyAboutField"
                      contentField="Company About"
                      placeHolder="About Company"
                      placeHolderKey="loginPage_agentResgistration_companyAboutPlaceholder"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Typography className="text-28 fw-600">
                  MANAGER'S DETAILS
                  <EditButton
                    keyField="loginPage_agentResgistration_managerDetails_title"
                    contentField="MANAGER'S DETAILS"
                  />
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_firstNameField"
                      contentField="First Name"
                      placeHolder="First Name, Given Name"
                      placeHolderKey="loginPage_agentResgistration_firstNamePlaceholder"
                    />
                  </Grid>
                 
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_lastNameField"
                      contentField="Last Name"
                      placeHolder="Enter Last Name, Surname Name"
                      placeHolderKey="loginPage_agentResgistration_lastNamePlaceholder"
                    />
                  </Grid>
                 
                  <Grid item xs={12} md={4}>
                    <AutoCompleteCommon
                      keyField="loginPage_agentResgistration_jobTitleField"
                      contentField="Job Title"
                      defaultData={["Manager"]}
                    />
                  </Grid>
                 
                  <Grid item xs={12} md={4}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_emailField"
                      contentField="Email"
                      placeHolder="Enter Email"
                      placeHolderKey="loginPage_agentResgistration_emailPlaceholder"
                    />
                  </Grid>
                 
                  <Grid item xs={12} md={7} />
                  <Grid item xs={12} md={6}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_passwordField"
                      contentField="Password"
                      placeHolder="Enter Password"
                      placeHolderKey="loginPage_agentResgistration_passwordPlaceholder"
                    />
                  </Grid>
                 
                  <Grid item xs={12} md={6}>
                    <TextFieldCommon
                      keyField="loginPage_agentResgistration_confirmPasswordField"
                      contentField="Confirm Password"
                      placeHolder="Enter Confirm Password"
                      placeHolderKey="loginPage_agentResgistration_confirmPasswordPlaceholder"
                    />
                    <Typography className="text-14 text-danger">
                      Password does not match!{" "}
                      <EditButton
                        keyField="loginPage_registration_passwordNotMatch"
                        contentField="Password does not match!"
                      />
                    </Typography>
                  </Grid>
                 
                  <Grid item xs={12}>
                    <Stack spacing={2} className="p-10" sx={{bgcolor: "#eeeeee"}}>
                      <Typography className="text-18">
                        Password must contain the following:{" "}
                        <EditButton
                          keyField="loginPage_registration_passwordMustContain_title"
                          contentField="Password must contain the following:"
                        />
                      </Typography>
                      <Typography className="d-flex align-center text-16 text-danger ">
                        <Clear color="error" sx={{ mr: "10px" }} />{" "}
                        <span>
                          A <b>lowercase</b> letter
                        </span>{" "}
                        <EditButton
                          keyField="loginPage_registration_passwordMustContain_lowercase"
                          contentField="A lowercase letter"
                        />
                      </Typography>
                      <Typography className="d-flex align-center text-16 text-danger ">
                        <Clear color="error" sx={{ mr: "10px" }} />{" "}
                        <span>
                          A <b>capital (uppercase)</b> letter
                        </span>{" "}
                        <EditButton
                          keyField="loginPage_registration_passwordMustContain_uppercase"
                          contentField="A capital (uppercase) letter"
                        />
                      </Typography>
                      <Typography className="d-flex align-center text-16 text-danger ">
                        <Clear color="error" sx={{ mr: "10px" }} />{" "}
                        <span>
                          A <b>number</b>
                        </span>{" "}
                        <EditButton
                          keyField="loginPage_registration_passwordMustContain_number"
                          contentField="A number"
                        />
                      </Typography>
                      <Typography className="d-flex align-center text-16 text-danger ">
                        <Clear color="error" sx={{ mr: "10px" }} />{" "}
                        <span>
                          Minimum <b>6 characters</b>
                        </span>{" "}
                        <EditButton
                          keyField="loginPage_registration_passwordMustContain_minimumChracters"
                          contentField="Minimum 6 characters"
                        />
                      </Typography>
                     
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className="d-flex align-center">
                      <CheckBox sx={{ mr: "5px" }} />I have read and agree to
                      the{" "}
                      <EditButton
                        keyField={"loginPage_registration_labelCheckAgreeTerms"}
                        contentField="I have read and agree to the"
                      />
                      <Typography
                        className="text-18 text-orange fw-700 "
                        component={"span"}
                      >
                        Terms & Conditions{" "}
                        <EditButton
                          keyField={"loginPage_registration_termsCondition"}
                          contentField="Terms & Conditions"
                        />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="d-flex align-center">
                      <CheckBox sx={{ mr: "5px" }} />
                      Subscribe to our Newsletter to get the best offers{" "}
                      <EditButton
                        keyField={
                          "loginPage_registration_labelSubscribeNewsletter"
                        }
                        contentField="Subscribe to our Newsletter to get the best offers"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="d-flex align-center">
                      <CheckBox sx={{ mr: "5px" }} />
                      I'm not a robot{" "}
                      <EditButton
                        keyField={"loginPage_agentLogin_labelCheckNotRobot"}
                        contentField="I'm not a robot"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Stack alignItems={"center"}>
                    <Box className="d-inline-block">
                      <ButtonCommon
                        bgColor="bg-orange"
                        textColor="text-white"
                        keyField={"registerNowButton"}
                        contentField="Register Now"
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default RegistrationAgentTab;
