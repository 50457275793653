import { Box, Checkbox, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../../components/common/EditButton";
import TextFieldCommon from "../../../components/common/TextFieldCommon";
import CheckboxCommon from "../../../components/common/CheckboxCommon";
import ButtonCommon from "../../../components/common/ButtonCommon";
import TextEditorCommon from "../../../components/common/TextEditorCommon";
import UploadIconAndSampleCommon from "../../../components/common/UploadIconAndSampleCommon";
import UploadImageAndSampleCommon from "../../../components/common/UploadImageAndSampleCommon";

const PassengerLoginTab = () => {
  return (
    <>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
        <Paper sx={{ p: 3 }}>
        <Stack spacing={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-22 fw-600">
                    Login{" "}
                    <EditButton
                      keyField="loginPage_passengerLogin_title"
                      contentField="Login"
                    />
                  </Typography>
                   <Typography className="text-16 fw-600">
                    To Start Your Journey{" "}
                    <EditButton
                      keyField="loginPage_passengerLogin_subtitle"
                      contentField="To Start Your Journey"
                    />
                  </Typography>
                </Grid>
                
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldCommon
                        keyField=""
                        placeHolderKey="loginPage_passengerLogin_placeHolderEmail"
                        contentField=""
                        placeHolder="Email Address"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CheckboxCommon
                        keyField={
                          "loginPage_passengerLogin_mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldCommon
                        keyField=""
                        placeHolderKey="loginPage_passengerLogin_placeHolderPassword"
                        contentField=""
                        placeHolder="Password"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CheckboxCommon
                        keyField={
                          "loginPage_passengerLogin_mandatoryField"
                        }
                        contentField="Mandatory Field"
                      />
                    </Grid>
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="d-flex align-center">
                      <Checkbox sx={{ mr: "5px" }} />
                      I'm not a robot{" "}
                      <EditButton
                        keyField={
                          "loginPage_passengerLogin_labelCheckNotRobot"
                        }
                        contentField="I'm not a robot"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                      <ButtonCommon
                      bgColor="bg-tkg-blue"
                      textColor="text-white"
                        keyField={"signInButton"}
                        contentField="Sign In"
                      />
                  </Grid>
                <Grid item xs={12}>
                  <Box className="d-flex align-center justify-between">
                  <Typography className="text-14">
                    Forgot Your Password?{" "}
                    <EditButton
                      keyField="loginPage_passengerLogin_forgotPassword"
                      contentField="Forgot Your Password?"
                    />
                  </Typography>
                  <Typography className="text-14">
                    Sign Up For Free{" "}
                    <EditButton
                      keyField="loginPage_passengerLogin_signUpForFree"
                      contentField="Sign Up For Free"
                    />
                  </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextEditorCommon keyField="loginPage_passengerLogin_loginInformation" contentField="Login Information" />
                </Grid>
                <Grid item xs={12}>
                      <UploadIconAndSampleCommon classWidth="w-full" />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="text-13 fw-700">
                    Help Desk - 1800519 631 Or +61 (8) 7226 1898{" "}
                    <EditButton
                      keyField="loginPage_passengerLogin_helpDesk"
                      contentField="Help Desk - 1800519 631 Or +61 (8) 7226 1898"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className="text-13 fw-700">
                    To Have A Good Experience On Your Phone, Download Tweet App{" "}
                    <EditButton
                      keyField="loginPage_passengerLogin_downloadTweetApp"
                      contentField="To Have A Good Experience On Your Phone, Download Tweet App"
                    />
                  </Typography>
                </Grid>
                  
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextFieldCommon keyField="loginPage_passengerLogin_tweetApp_googlePlayLink" contentField="Google Play Link" />
            </Grid>
            <Grid item xs={12}>
              <TextFieldCommon keyField="loginPage_passengerLogin_TweetApp_appStoreLink" contentField="All Store Link" />
            </Grid>
          </Grid>
        </Stack>
      </Paper>
        </Stack>
      </Paper>
    </>
  );
};

export default PassengerLoginTab;
