import { Box, Tab, Typography } from "@mui/material";
import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Overview from "./tabs-content/Overview";
import Hero from "./tabs-content/Hero";
import SearchAgent from "./tabs-content/SearchAgent";
import Deals from "./tabs-content/Deals";
import SearchResult from "./tabs-content/SearchResult";
import EditButton from "../../../components/common/EditButton";
import AgentPage from "./tabs-content/AgentPage";

export default function NavigationFindLocalAgent() {
  const [value, setValue] = React.useState("overview");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  Overview{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_findLocalAgent_overviewTab"
                    }
                    contentField="Overview"
                  />
                </Typography>
              }
              value={"overview"}
            />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Hero{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_findLocalAgent_heroTab"
                    }
                    contentField="Hero"
                  />
                </Typography>
              } value={"hero"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Search Agent{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_findLocalAgent_searchAgentTab"
                    }
                    contentField="Search Agent"
                  />
                </Typography>
              } value={"search-agent"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Deals{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_findLocalAgent_dealsTab"
                    }
                    contentField="Deals"
                  />
                </Typography>
              } value={"deals"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Search Result{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_findLocalAgent_searchResultTab"
                    }
                    contentField="Search Result"
                  />
                </Typography>
              } value={"search-result"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  Agent Page{" "}
                  <EditButton
                    keyField={
                      "homepage_navigationMenu_findLocalAgent_agentPageTab"
                    }
                    contentField="Agent Page"
                  />
                </Typography>
              } value={"agent-page"} />
          </TabList>
        </Box>
        <TabPanel value="overview" sx={{ px: 0 }}>
          <Overview />
        </TabPanel>
        <TabPanel value="hero" sx={{ px: 0 }}>
          <Hero />
        </TabPanel>
        <TabPanel value="search-agent" sx={{ px: 0 }}>
          <SearchAgent />
        </TabPanel>
        <TabPanel value="deals" sx={{ px: 0 }}>
          <Deals />
        </TabPanel>
        <TabPanel value="search-result" sx={{ px: 0 }}>
          <SearchResult />
        </TabPanel>
        <TabPanel value="agent-page" sx={{ px: 0 }}>
          <AgentPage />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
