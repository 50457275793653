import React from 'react'
import MetaSEO from './MetaSEO'
import HeroBannerPhoto from './HeroBannerPhoto'
import SearchBoxFeature from '../navigation-menu/find-local-agent/common-feature/SearchBoxFeature'
import HighlightFeaturesBelowSearchBox from './HighlightFeaturesBelowSearchBox'
import AdvancedSearch from './AdvancedSearch'
import HeroBannerSlogan from './HeroBannerSlogan'

const HeroSectionPage = () => {
  return (
    <>
    <MetaSEO />
    <HeroBannerPhoto />
    <HeroBannerSlogan />
    <SearchBoxFeature keywordField={"homepage_heroSection"} placeHolder={"Type Keyword / Destination / Trip Code"} />
    <HighlightFeaturesBelowSearchBox />
    <AdvancedSearch />
    </>
  )
}

export default HeroSectionPage