import React from 'react'
import MetaSEO from './MetaSEO'
import AgentFinder from './AgentFinder'
import SendingBrochure from './SendingBrochure'

const AgentFinderPage = () => {
  return (
    <>
    <MetaSEO />
    <AgentFinder />
    <SendingBrochure />
    </>
  )
}

export default AgentFinderPage