import React from 'react'
import MetaSEO from './MetaSEO'
import BestSellingProduct from './BestSellingProduct'
import { Stack } from '@mui/material'
import ButtonCommon from '../../components/common/ButtonCommon'

const BestSellingProductPage = () => {
  return (
    <>
        <MetaSEO />
        <BestSellingProduct />
        <Stack alignItems={"flex-end"}>
      <ButtonCommon keyField="saveButton" contentField="Save" />
      </Stack>
    </>
  )
}

export default BestSellingProductPage