import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from './EditButton'
import TextFieldCommon from './TextFieldCommon'
import UploadImageAndSampleCommon from './UploadImageAndSampleCommon'
import SelectColorCommon from './SelectColorCommon'
interface Props {
  showButton?: boolean
}
const DataLimitCommon = ({showButton}: Props) => {
  return (
    <>
        <Typography className="text-24 fw-600">Data Limit <EditButton
                    keyField={"dataLimit_length"}
                    contentField="Data Limit"
                  />: 3 </Typography>

        <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
          <Stack spacing={5}>
            <Box>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={
                    "checkLength"
                  }
                  contentField="Characters"
                />
                : 41/35
              </Typography>
              <TextFieldCommon
                keyField={
                  "dataLimit_title"
                }
                contentField="Title"
              />
            </Box>
            <Box>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={
                    "checkLength"
                  }
                  contentField="Characters"
                />
                : 157/90
              </Typography>

              <TextFieldCommon
                keyField={
                  "dataLimit_textBody"
                }
                contentField="Text Body"
              />
            </Box>
            <UploadImageAndSampleCommon />
            {!!showButton ? <SelectColorCommon sectionKey="dataLimit" buttonContent="Learn More" /> : null}
          </Stack>
        </Paper>
      </>
  )
}

export default DataLimitCommon