import { Edit } from "@mui/icons-material";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import ButtonCommon from "../../components/common/ButtonCommon";
import TextEditorCommon from "../../components/common/TextEditorCommon";

const TermAndConditionPage = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
    <Stack spacing={5}>
      <Box>
        <Typography className="text-14 mb-10">
          Characters{" "}
          <EditButton
            keyField={
              "checkLength"
            }
            contentField="Characters"
          />
          : 41/61
        </Typography>
        <TextFieldCommon
          keyField={
            "seo_metaTitle"
          }
          contentField="Meta Title"
        />
      </Box>
      <Box>
        <Typography className="text-14 mb-10">
          Characters{" "}
          <EditButton
            keyField={
              "checkLength"
            }
            contentField="Characters"
          />
          : 157/160
        </Typography>

        <TextFieldCommon
          keyField={
            "seo_metaDescription"
          }
          contentField="Meta Description"
        />
      </Box>
      <Box>
        <TextFieldCommon
          keyField={
            "seo_metaKeywords"
          }
          contentField="Meta Keywords"
          classHeight="h-100"
        />
      </Box>
      <Divider />
      <Box>
        <TextFieldCommon
          keyField={
            "homePage_termAndCondition_titleField"
          }
          contentField="Title"
        />
      </Box>
      <Box>
        <TextEditorCommon
          keyField={
            "homePage_termAndCondition_contentField"
          }
          contentField="Content"
        />
      </Box>
      <Stack alignItems={"flex-end"}>
      <ButtonCommon keyField="saveButton" contentField="Save" />
      </Stack>
    </Stack>
  </Paper>
  );
};

export default TermAndConditionPage;
