import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import WrapperCard from "../../components/common/WrapperCard";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import AutoCompleteCommon from "../../components/common/AutoCompleteCommon";
import ButtonCommon from "../../components/common/ButtonCommon";
import { Percent } from "@mui/icons-material";

const HighlightFeaturesBelowSearchBox = () => {
  return (
    <WrapperCard
      cardTitleContent="Highlight Features Below Search Box"
      cardTitleKey={
        "homepage_heroSection_highlightFeaturesBelowSearchBox_titleHeader"
      }
      children={
        <Box p={3} py={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-14 mb-10">
                    Characters{" "}
                    <EditButton
                      keyField={"checkLength"}
                      contentField="Characters"
                    />
                    : 41/40
                  </Typography>
                  <TextFieldCommon
                    keyField={
                      "homepage_heroSection_highlightFeaturesBelowSearchBox_title"
                    }
                    contentField="Title"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className="text-14 mb-10">
                    Data Limit{" "}
                    <EditButton
                      keyField={
                        "homepage_heroSection_highlightFeaturesBelowSearchBox_countries_dataLimit"
                      }
                      contentField="Data Limit"
                    />
                    : 6{" "}
                    <EditButton
                      keyField={
                        "homepage_heroSection_highlightFeaturesBelowSearchBox_countries_dataLimit_unit"
                      }
                      contentField="Countries"
                    />
                  </Typography>
                  <AutoCompleteCommon
                    keyField={
                      "homepage_heroSection_highlightFeaturesBelowSearchBox_countries"
                    }
                    contentField="Countries"
                    defaultData={[]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={3}
              >
                <Stack spacing={2}>
                  <Typography className="fw-600">Current Promotions <EditButton
                      keyField={
                        "homepage_heroSection_highlightFeaturesBelowSearchBox_countries_currentPromotions"
                      }
                      contentField="Current Promotions"
                    /></Typography>
                  <Typography>Vietnam 20%</Typography>
                  <Typography>Singapore 20%</Typography>
                </Stack>
                <Box>
                  <ButtonCommon
                  startIcon={<Percent sx={{mr: "5px"}} />}
                    keyField={"homepage_heroSection_highlightFeaturesBelowSearchBox_promotionSetupButton"}
                    contentField="Promotion Setup"
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Box className="d-flex justify-end">
                <ButtonCommon keyField={"saveButton"} contentField="Save" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container mt={3}>
                <Grid item xs={4} py={2} bgcolor={"#ebebeb"}>
                  <Typography className="text-28 text-danger fw-600 pl-16">
                    Promotion Setup Pop Up 
                  </Typography>
                  <Grid container spacing={2} p={2}>
                    <Grid item xs={12} md={8}>
                      <Stack spacing={2}>
                        <Typography className="text-14 fw-600">Country <EditButton
                      keyField={
                        "homepage_heroSection_highlightFeaturesBelowSearchBox_promotionSetupTable_countryHeader"
                      }
                      contentField="Country"
                    /></Typography>
                        <Typography className="text-14 bg-white px-20 py-10">Japan</Typography>
                        <Typography className="text-14 bg-white px-20 py-10">Vietnam</Typography>
                        <Typography className="text-14 bg-white px-20 py-10">China</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                        <Typography className="text-14 fw-600">Promotion Text <EditButton
                      keyField={
                        "homepage_heroSection_highlightFeaturesBelowSearchBox_promotionSetupTable_promotionTextHeader"
                      }
                      contentField="Promotion Texts"
                    /></Typography>
                        <Typography className="text-14 bg-white px-20 py-10">---</Typography>
                        <Typography className="text-14 bg-white px-20 py-10">20%</Typography>
                        <Typography className="text-14 bg-white px-20 py-10">---</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};

export default HighlightFeaturesBelowSearchBox;
