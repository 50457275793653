import { Switch } from "@mui/material";
import React from "react";
import EditButton from "../common/EditButton";
import EditButtonSwitch from "../common/EditButtonSwitch";

interface Props {
  checked: boolean;
  setChecked: (data: boolean) => void;
  textChecked: string;
  textUnchecked: string;
  width?: number;
  keyField: string;
}

const SwitchWithName = ({
  checked,
  setChecked,
  textChecked,
  textUnchecked,
  width = 80,
  keyField,
}: Props) => {
  return (
    <>
      <Switch
        size="medium"
        sx={{
          marginTop: "4px",
          width: width,
          padding: "6px",
          ".MuiButtonBase-root.MuiSwitch-switchBase": {
            transform: "translateX(1px)",
            left: checked ? width - 65 : 0,
          },
          ".MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked": {
            color: "#fff",
            transform: "translateX(26px) !important",
          },
          ".MuiSwitch-track": {
            borderRadius: "20px",
            backgroundColor: "#818181",
            opacity: "1 !important",
            "&:after, &:before": {
              color: "white",
              fontSize: "11px",
              position: "absolute",
              top: "12px",
            },
            "&:after": {
              // content: checked ? `"${textChecked} "` : '""',
              content: checked ? `"${textChecked} "` : '""',
              left: "13px",
            },
            "&:before": {
              content: !checked ? `"${textUnchecked}"` : '""',
              right: "12px",
            },
          },
        }}
        name="isShow"
        value={checked}
        checked={checked}
        onChange={(e) => setChecked(e?.target?.checked)}
      />
      <EditButtonSwitch
        keyFieldChecked={`${keyField}_checked`}
        contentFieldChecked={textChecked}
        keyFieldUnChecked={`${keyField}_unchecked`}
        contentFieldUnChecked={textUnchecked}
      />
    </>
  );
};

export default SwitchWithName;
