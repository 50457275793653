import {
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TWTLogo from "../../media/images/tweettours-logo.jpg";
import EditButton from "../../components/common/EditButton";
import SwitchWithName from "../../components/switch/SwitchWithName";
import CheckboxCommon from "../../components/common/CheckboxCommon";
import UploadFileCommon from "../../components/common/UploadFileCommon";

const WebsiteLogo = () => {
  const [check, setCheck] = useState(true);
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">Website Logo <EditButton
                    keyField={`masterSetup_websiteLogo_cardHeader`}
                    contentField={'Website Logo'}
                  /></Typography>
      <Divider />
      <Box mt={2}>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          px={4}
        >
          <Stack
            direction={"row"}
            spacing={4}
            alignItems={"center"}
            justifyContent={"center"}
            className="flex-1"
          >
                <UploadFileCommon keyCaption="fileUpload_caption" contentCaption='Upload File' />
            <Box>
              <img src={TWTLogo} alt="" />
            </Box>
          </Stack>

          <Stack
            direction={"row"}
            spacing={3}
            alignItems={"center"}
            justifyContent={"center"}
            className="flex-1"
          >
            <CheckboxCommon
              keyField={"masterSetup_websiteLogo_headerCheckbox"}
              contentField="Header"
            />
            <CheckboxCommon
              keyField={"masterSetup_websiteLogo_footerCheckbox"}
              contentField="Footer"
            />
            <Box className="d-flex align-center">
              Show{" "}
              <EditButton
                keyField={"masterSetup_websiteLogo_labelSwitch"}
                contentField="Show"
              />
              <SwitchWithName
                checked={check}
                setChecked={setCheck}
                textChecked={"Yes"}
                textUnchecked={"No"}
                keyField="masterSetup_websiteLogo_switch"
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

export default WebsiteLogo;
