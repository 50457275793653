import TableCell from "@mui/material/TableCell";
import TableHeader from "../../../components/Table/TableHeader";
import EditButton from "../../../components/common/EditButton";

const TableHeading = () => {
  return (
    <TableHeader>
      <TableCell className="bg-slight-blue text-left">Type <EditButton keyField="homepage_faqs_typeField" contentField="Type" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Country <EditButton keyField="homePage_faqs_countryField" contentField="Country" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Question<EditButton keyField="homepage_faqs_questionField" contentField="Question" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Answer <EditButton keyField="homepage_faqs_answerField" contentField="Answer" /></TableCell>
      <TableCell className="bg-slight-blue text-center">Action <EditButton keyField="homePage_faqs_actionField" contentField="Action" /></TableCell>
    </TableHeader>
  );
};

export default TableHeading;
