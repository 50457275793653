import { Edit } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../components/common/EditButton";
import TextFieldCommon from "../../components/common/TextFieldCommon";
import ButtonCommon from "../../components/common/ButtonCommon";

const ProductsDisplay = () => {
  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="text-18 fw-600 text-tkg-blue mb-20">
              Text
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography className="text-14 mb-10">
              BOOK ONLINE OR CALL{" "}
              <EditButton
                keyField={"product_productDetails_bookOnlineOrCall"}
                contentField="BOOK ONLINE OR CALL"
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="text-14 mb-10">
              of travelers recommend this experience.{" "}
              <EditButton
                keyField={
                  "product_productDetails_ofTravelersRecommendThisExperience"
                }
                contentField="of travelers recommend this experience."
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} spacing={2}>
              <Typography className="text-14 mb-10">
                Pay over time with smaller, interest free instalments{" "}
                <EditButton
                  keyField={
                    "product_productDetails_payOverTimeWithSmallerInterestFreeInstalments"
                  }
                  contentField="Pay over time with smaller, interest free instalments"
                />
              </Typography>{" "}
              <Typography className="text-14 fw-600 text-tkg-blue mb-10">
                Learn More{" "}
                <EditButton
                  keyField={"learnMoreButton"}
                  contentField="Learn More"
                />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} spacing={2}>
              <Typography className="text-14 mb-10">
                Tripcode{" "}
                <EditButton
                  keyField={"product_productDetails_tripcode"}
                  contentField="Tripcode"
                />
              </Typography>
              <Typography className="text-14 mb-10">
                Duration{" "}
                <EditButton
                  keyField={"product_productDetails_duration"}
                  contentField="Duration"
                />
              </Typography>
              <Typography className="text-14 mb-10">
                Tour Guide{" "}
                <EditButton
                  keyField={"product_productDetails_tourGuide"}
                  contentField="Tour Guide"
                />
              </Typography>
              <Typography className="text-14 mb-10">
                Age Range From{" "}
                <EditButton
                  keyField={"product_productDetails_ageRangeFrom"}
                  contentField="Age Range From"
                />
              </Typography>
              <Typography className="text-14 mb-10">
                Age Range To{" "}
                <EditButton
                  keyField={"product_productDetails_ageRangeTo"}
                  contentField="Age Range To"
                />
              </Typography>
              <Typography className="text-14 mb-10">
                Max Group Size{" "}
                <EditButton
                  keyField={"product_productDetails_maxGroupSize"}
                  contentField="Max Group Size"
                />
              </Typography>

            </Stack>
          </Grid>
       
        <Grid item xs={12}>
          <Typography className="text-16 fw-600 mb-10">
            Places You'll See{" "}
            <EditButton
              keyField={"product_productDetails_feature_placesYoullSee"}
              contentField="Places You'll See"
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="text-16 fw-600 mb-10">
            Overview{" "}
            <EditButton
              keyField={"product_productDetails_feature_overview"}
              contentField="Overview"
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="text-16 fw-600 mb-10">
            Introduction{" "}
            <EditButton
              keyField={"product_productDetails_feature_introduction"}
              contentField="Introduction"
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="text-16 fw-600 mb-10">
            What's Included{" "}
            <EditButton
              keyField={"product_productDetails_feature_whatsIncluded"}
              contentField="What's Included"
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="text-16 fw-600 mb-20">
            Availability{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability"}
              contentField="Availability"
            />
          </Typography>
          <Stack direction={"row"} spacing={2} mb={2}>
          <Typography className="text-14 mb-10">
            Start date{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_priceTable_startDate"}
              contentField="Start date"
            />
          </Typography>
          <Typography className="text-14 mb-10">
            End date{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_priceTable_endDate"}
              contentField="End date"
            />
          </Typography>
          <Typography className="text-14 mb-10">
            Price{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_priceTable_price"}
              contentField="Price"
            />
          </Typography>
          <Typography className="text-14 mb-10">
            Available{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_priceTable_available"}
              contentField="Available"
            />
          </Typography>
          <Typography className="text-14 mb-10">
            Accommodation{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_priceTable_accommodation"}
              contentField="Accommodation"
            />
          </Typography>
          <Typography className="text-14 mb-10">
            Room type{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_priceTable_roomType"}
              contentField="Room type"
            />
          </Typography>
          <Typography className="text-14 mb-10">
            Tour type{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_priceTable_tourType"}
              contentField="Tour type"
            />
          </Typography>
          <Typography className="text-14 mb-10">
            Group size{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_priceTable_groupSize"}
              contentField="Group size"
            />
          </Typography>
          </Stack>
          <Typography className="text-14 mb-10">
            Number of rooms{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_selectedRooms_numberOfRooms"}
              contentField="Number of rooms"
            />
          </Typography>
          <Typography className="text-14 mb-10">
            Max occupancy per room{" "}
            <EditButton
              keyField={"product_productDetails_feature_availability_selectedRooms_maxOccupancyPerRoom"}
              contentField="Max occupancy per room"
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
            <Stack direction={"row"} spacing={2}><Typography className="text-16 fw-600 mb-10">
            Frequently Asked Questions{" "}
            <EditButton
              keyField={"product_productDetails_feature_frequentlyAskedQuestions"}
              contentField="Frequently Asked Questions"
            />
          </Typography><Typography className="text-16 fw-600 mb-10 text-orange">
            View All{" "}
            <EditButton
              keyField={"product_productDetails_feature_viewAllButton"}
              contentField="View All"
            />
          </Typography></Stack>
          
        </Grid>
        <Grid item xs={12}>
            <Typography className="text-16 fw-600 mb-10">
            You Might Also Like...{" "}
            <EditButton
              keyField={"product_productDetails_feature_youMightAlsoLike"}
              contentField="You Might Also Like..."
            />
          </Typography>
          <Typography className="text-14 mb-10">
            Checkout beautiful places around the world{" "}
            <EditButton
              keyField={"product_productDetails_feature_youMightAlsoLike_subtitle"}
              contentField="Checkout beautiful places around the world"
            />
          </Typography>
          
        </Grid>
 </Grid>
        <Box>
          <Typography className="text-18 fw-600 text-tkg-blue mb-20">
            Button
          </Typography>
          <Stack spacing={2} direction="row" justifyContent={"space-between"}>
            <div className="d-inline-block">
              <ButtonCommon
                bgColor="bg-white"
                textColor="text-dark"
                borderRadius="rounded-20"
                keyField="seeMoreButton"
                contentField="See More"
              />
            </div>
            <div className="d-inline-block">
              <ButtonCommon
                bgColor="bg-white"
                textColor="text-dark"
                borderRadius="rounded-20"
                keyField="addToWishlistButton"
                contentField="Add to wishlist"
              />
            </div>
            <div className="d-inline-block">
              <ButtonCommon
                bgColor="bg-white"
                textColor="text-tkg-blue"
                borderColor="border-color-primary"
                borderRadius="rounded-20"
                keyField="checkAvailabilityButton"
                contentField="Check Availability"
              />
            </div>
            <div className="d-inline-block">
              <ButtonCommon
                bgColor="bg-white"
                textColor="text-tkg-blue"
                borderColor="border-color-primary"
                borderRadius="rounded-20"
                keyField="downloadPDFBrochureButton"
                contentField="Download PDF Brochure"
              />
            </div>
            <div className="d-inline-block">
              <ButtonCommon
                bgColor="bg-white"
                textColor="text-tkg-blue"
                borderColor="border-color-primary"
                borderRadius="rounded-20"
                keyField="product_productDetails_feature_availability_upcomingDepartures"
                contentField="Upcoming Departures"
              />
            </div>
            <div className="d-inline-block">
              <ButtonCommon
                bgColor="bg-white"
                textColor="text-tkg-blue"
                borderColor="border-color-primary"
                borderRadius="rounded-20"
                keyField="product_productDetails_feature_availability_priceTable_confirmDatesButton"
                contentField="Confirm Dates"
              />
            </div>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default ProductsDisplay;
