import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../../../components/common/EditButton'
import TextFieldCommon from '../../../../components/common/TextFieldCommon'
import ButtonCommon from '../../../../components/common/ButtonCommon'
import DataLimitCommon from '../../../../components/common/DataLimitCommon'
import SelectColorCommon from '../../../../components/common/SelectColorCommon'

const Deals = () => {
  return (
    <>
    {" "}
    <Box className="bg-white" sx={{ px: "75px", py: 5, mb: 2 }}>
      <Stack spacing={5}>
        <Box>
          <Typography className="text-14 mb-10">
            Characters{" "}
            <EditButton
              keyField={
                "checkLength"
              }
              contentField="Characters"
            />
            : 41/61
          </Typography>
          <TextFieldCommon
            keyField={
              "homepage_navigationMenu_findLocalAgent_deals_title"
            }
            contentField="Title"
          />
        </Box>
        
<DataLimitCommon />
<Paper sx={{ px: 10, py: 5, mb: 2 }}><SelectColorCommon sectionKey='homepage_navigationMenu_findLocalAgent_deals' buttonContent='Learn More' /></Paper>

      </Stack>
    </Box>
    <Stack className="mt-30" alignItems={"flex-end"}>
        <ButtonCommon
          keyField={"addMoreButton"}
          contentField="Add More"
          bgColor="bg-white"
          textColor="text-tkg-blue"
          iconColor="#0000008a"
          borderColor="border-color-primary"
        />
      </Stack>
  </>
  )
}

export default Deals