import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from './EditButton'
import { FormatBold, FormatColorFill, FormatColorText, FormatItalic, FormatListBulleted, FormatListNumbered, FormatUnderlined } from '@mui/icons-material';

interface Props {
  keyField?: string
  contentField?: string;
  classHeight?: string
}
const TextEditorCommon = ({keyField, contentField, classHeight="h-150"}: Props) => {
  return (
    <Stack spacing={1}>
    {(keyField && contentField) && <Typography className="text-16 fw-700">
      {contentField} <EditButton keyField={keyField} contentField={contentField} />
    </Typography>}
    <Box className={`border-full position-relative ${classHeight} rounded-10`}>
    <Box className="d-flex align-center gap-30 border-bottom rounded-10-top position-absolute top-0 left-0 right-0 bg-white p-10">
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <FormatBold /> 
      <FormatItalic />
      <FormatUnderlined />
      </Stack>
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
<FormatColorText />
      <FormatColorFill />
      </Stack>
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
<FormatListBulleted />
      <FormatListNumbered />
      </Stack>
      
      
      
    </Box>
  </Box>
    </Stack>
    
  )
}

export default TextEditorCommon