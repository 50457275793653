import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonCommon from "../../components/common/ButtonCommon";
import { AttachMoney, CloudUpload, Delete, Edit } from "@mui/icons-material";
import EmptyImage from "../../media/images/empty-img.jpg";
import BestSellingProductSample from "../../media/images/best-selling-product.png";
import HeroBannerSample from "../../media/images/hero-banner-photo.jpg";
import EditButton from "../../components/common/EditButton";
import UploadFileCommon from "../../components/common/UploadFileCommon";
import AutoCompleteCommon from "../../components/common/AutoCompleteCommon";
import TextFieldCommon from "../../components/common/TextFieldCommon";

const BestSellingProduct = () => {
  return (
    <Box sx={{ p: 3, mb: 2 }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box className="d-inline-block">
            <ButtonCommon
              startIcon={<CloudUpload sx={{ mr: "5px" }} />}
              contentField="Add Image"
              keyField="homepage_bestSellingProduct_content_addImageButton"
            ></ButtonCommon>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box>
            <Box className="d-flex">
              <img src={BestSellingProductSample} className="h-400" />
              <Stack justifyContent={"flex-end"} spacing={2}>
                <Edit />
                <Delete color="error" />
              </Stack>
            </Box>
          </Box>
        </Grid>
        {Array(2)
          .fill(0)
          .map((_, index) => (
            <Grid key={index} item xs={12} md={3}>
              <Box className="d-flex gap-10">
                <img src={EmptyImage} className="h-400" />
              </Box>
            </Grid>
          ))}
        <Grid item xs={12} md={3}>
          <Stack
            className="h-full"
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
          >
            <ButtonCommon
              keyField="addMoreButton"
              contentField="Add More"
              bgColor="bg-white"
              textColor="text-tkg-blue"
              iconColor="#0000008a"
              borderColor="border-color-primary"
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid container mt={3} py={2}>
        <Grid item xs={12} md={5} bgcolor={"#ebebeb"} p={2}>
          <Typography className="text-28 text-danger fw-600 pl-16">
            Add A Best Selling Product
          </Typography>

          <Grid container spacing={3} mt={2}>
            <Grid item xs={12} md={6}>
              <img src={HeroBannerSample} className="h-120" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="text-16">
                Image Size{" "}
                <EditButton
                  keyField={"fileUpload_size"}
                  contentField="Image Size"
                />
                : 1440 X 471 Pixel{" "}
                <EditButton keyField={"fileUpload_unit"} contentField="Pixel" />
              </Typography>
              <UploadFileCommon
                keyCaption="fileUpload_caption"
                contentCaption="Upload File"
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldCommon
                keyField="homepage_bestSellingProduct_content_addABestSellingProductForm_cardTitleField"
                contentField="Card Title"
                bgTitle="#ebebeb"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldCommon
                keyField="urlField"
                contentField="URL"
                bgTitle="#ebebeb"
              />
            </Grid>
            <Grid item xs={12}>
              {" "}
              <Stack alignItems={"flex-end"}>
                <ButtonCommon contentField="Apply" keyField="applyButton" />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={3} py={2}>
        <Grid item xs={12}>
          <Typography className="text-18 fw-600">
            Preview{" "}
            <EditButton
              keyField={"homepage_bestSellingProduct_content_preview"}
              contentField="Preview"
            />
          </Typography>
        </Grid>{" "}
        {Array(2)
          .fill(0)
          .map((_, index) => (
            <Grid key={index} item xs={12} md={2}>
              <Box className={"position-relative h-400 rounded-10"}>
                <Box
                  className="position-absolute left-0 top-0 w-full h-400 rounded-10 zIndex-1"
                  sx={{ background: "rgba(0, 0, 0, 0.5)" }}
                ></Box>
                <img
                  className="position-absolute right-0 top-0 w-full h-400  rounded-10"
                  src={
                    "https://content-platform-tkg.s3.ap-southeast-2.amazonaws.com/development/TWT/feature-destination/2024-06/frame-427324013-1718262711.png"
                  }
                />

                <Stack
                  spacing={1}
                  className="position-absolute zIndex-1 left-15 top-30"
                >
                  <Typography className="text-white fw-600">
                    {"All Tour"}
                  </Typography>
                  <Typography className="text-white text-14">
                    36 itineraries <EditButton
              keyField={"itineraries"}
              contentField="itineraries"
            />
                  </Typography>
                  <Typography className="border-full bg-tkg-blue text-white fw-600 rounded-20 px-10 py-5">
                    Learn More <EditButton
              keyField={"learnMoreButton"}
              contentField="Learn More"
            />
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default BestSellingProduct;
