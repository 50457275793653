import { Paper, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../../components/common/EditButton'

const ViewFaqs = () => {
  return (
    <Paper sx={{p: 3, mb: 2}}>
        <Typography component={"h1"} className="text-32 text-center fw-600 mb-20 text-tkg-blue">Tweet Tour: Your FAQs Answered for Tours & Packages <EditButton keyField="view_faqs_title" contentField="Tweet Tour: Your FAQs Answered for Tours & Packages" /></Typography>
        <Typography component={"p"} className="text-18 text-center fw-600 mb-20">Your Essential Guide to Tweet Tour: Unveiling Answers to Frequently Asked Questions <EditButton keyField="view_faqs_subtitle" contentField="Your Essential Guide to Tweet Tour: Unveiling Answers to Frequently Asked Questions" /></Typography>
    </Paper>
  )
}

export default ViewFaqs