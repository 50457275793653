import { CloudUploadOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import EditButton from './EditButton'

interface Props {
  keyCaption: string
  contentCaption: string
}
const UploadFileCommon = ({ keyCaption, contentCaption }: Props) => {
  return (
    <Box className="px-16 flex-center bg-tkg-blue rounded-4 py-8 text-white">
              <CloudUploadOutlined sx={{ color: "#fff", mr: 2 }} />
              <Box>
                <Typography variant="body2" className="text-14">
                  {contentCaption}{" "}
                  <EditButton
                    keyField={keyCaption}
                    contentField={contentCaption}
                    color="white"
                  />
                </Typography>
                <Typography variant="caption" className="text-12">
                  Drag & Drop Here{" "}
                  <EditButton
                    keyField={"fileUpload_content"}
                    contentField="Drag & Drop Here"
                    color="white"
                  />
                </Typography>
              </Box>
            </Box>
  )
}

export default UploadFileCommon