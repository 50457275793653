import {
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EditButton from "../../components/common/EditButton";
import SwitchWithName from "../../components/switch/SwitchWithName";
import CheckboxCommon from "../../components/common/CheckboxCommon";

const WishlishDisplay = () => {
  const [check, setCheck] = useState(true);
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">Wishlish Display <EditButton
                    keyField={`masterSetup_wishlishDisplay_cardHeader`}
                    contentField={'Wishlish Display'}
                  /></Typography>
      <Divider />
      <Box mt={2}>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          px={4}
        >
          <Stack
            direction={"row"}
            spacing={4}
            alignItems={"center"}
            justifyContent={"center"}
            className="flex-1"
          ></Stack>

          <Stack
            direction={"row"}
            spacing={3}
            alignItems={"center"}
            justifyContent={"center"}
            className="flex-1"
          >
            <CheckboxCommon
              keyField={"masterSetup_wishlishDisplay_headerCheckbox"}
              contentField="Header"
            />
            <CheckboxCommon
              keyField={"masterSetup_wishlishDisplay_footerCheckbox"}
              contentField="Oncard"
            />
            <Box className="d-flex align-center">
              Show{" "}
              <EditButton
                keyField={"masterSetup_wishlishDisplay_labelSwitch"}
                contentField="Show"
              />
              <SwitchWithName
                checked={check}
                setChecked={setCheck}
                textChecked={"Yes"}
                textUnchecked={"No"}
                keyField="masterSetup_wishlishDisplay_switch"
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

export default WishlishDisplay;
