import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../components/common/EditButton'
import TextFieldCommon from '../../components/common/TextFieldCommon'
import ButtonCommon from '../../components/common/ButtonCommon'

const MetaSEO = () => {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
    <Stack spacing={5}>
      <Box>
        <Typography className="text-14 mb-10">
          Characters{" "}
          <EditButton
            keyField={
              "checkLength"
            }
            contentField="Characters"
          />
          : 41/61
        </Typography>
        <TextFieldCommon
          keyField={
            "seo_metaTitle"
          }
          contentField="Meta Title"
        />
      </Box>
      <Box>
        <Typography className="text-14 mb-10">
          Characters{" "}
          <EditButton
            keyField={
              "checkLength"
            }
            contentField="Characters"
          />
          : 157/160
        </Typography>

        <TextFieldCommon
          keyField={
            "seo_metaDescription"
          }
          contentField="Meta Description"
        />
      </Box>
      <Box>
        <TextFieldCommon
          keyField={
            "seo_metaKeywords"
          }
          contentField="Meta Keywords"
          classHeight="h-100"
        />
      </Box>
      <Stack alignItems={"flex-end"}>
      <ButtonCommon keyField="saveButton" contentField="Save" />
      </Stack>
    </Stack>
  </Paper>
  )
}

export default MetaSEO