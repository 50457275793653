import { Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from '../../components/common/EditButton'
import ButtonCommon from '../../components/common/ButtonCommon'

const OtherPage = () => {
  return (
    <Paper sx={{p: 3}}>
      <Stack justifyContent={"center"} alignItems={"center"} spacing={3}>
        <Typography className='text-28 fw-700'>We'll be glad to hear from you <EditButton
              keyField={"homepage_otherpage_header"}
              contentField="We'll be glad to hear from you"
            /></Typography>

            <ButtonCommon keyField={"contactUsButton"} contentField="Contact Us" />
    </Stack>
    </Paper>

    
  )
}

export default OtherPage