import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";

const settingService = {
    companySettings: {
        getCompany: async () => {
            try {
                return await apiInstance.get(endPointAPI.COMPANY_SETTINGS.GET_COMPANY_SETTINS);
            }
            catch {
                return null
            }
        }, 
    },
 
}

export default settingService;
