import { Box, Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import EditButton from './EditButton'
import { ArrowDropDown, HighlightOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  keyField: string
  contentField: string;
  classHeight?: string;
  defaultData: string[]
  bgTitle?: string
}
const AutoCompleteCommon = ({keyField, contentField, classHeight="h-55", defaultData, bgTitle="bg-white"}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <Box className={`border-full position-relative ${classHeight} rounded-10`}>
    <Typography className={`text-16 fw-700 position-absolute top-minus-15 left-10 left-0 ${bgTitle} py-0`}>
      {contentField} <EditButton keyField={keyField} contentField={contentField} />
    </Typography>

<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={1} className='position-absolute top-15 left-10 right-10'>
  <Stack direction={"row"} alignItems={"center"} spacing={1}>
    {/* {defaultData.map((item, index) => (
      <Chip label={<Box className="flex-center">{item} <HighlightOff sx={{ml: "3px", fontSize: "14px"}} /></Box>} />
    ))} */}
  </Stack>
    
    <ArrowDropDown />
    </Stack>
    
  </Box>
  )
}

export default AutoCompleteCommon