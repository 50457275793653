const endPointAPI = {
    USER: {
        CURRENT: "/user/current",
        FIND_SIMPLE: "/user/find-simple"
    },
    LANGUAGE_CONTENT: {
        STORE: "/language-content/contents/store",
        UPDATE: "/language-content/contents/update",
        DELETE: "/language-content/contents/delete",
        GET_BY_ID: "/language-content/contents/get-by-id",
        GET_DETAIL_BY_KEY: "/language-content/contents/find-detail-by-key",
        GET_LIST: "/language-content/contents/get-list-lang",
    },
    COMPANY_SETTINGS: {
        GET_COMPANY_SETTINS: "/setting/public/find-company-setting",
      },

}

export default endPointAPI;