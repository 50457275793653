import React from 'react'
import MetaSEO from './MetaSEO'
import CardAdjustments from './CardAdjustments'

const BrochureUploadPage = () => {
  return (
    <>
    <MetaSEO />
    <CardAdjustments />
    </>
  )
}

export default BrochureUploadPage