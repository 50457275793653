import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import EditButton from "../../../../components/common/EditButton";
import TextFieldCommon from "../../../../components/common/TextFieldCommon";
import SectionCommon from "../../../../components/common/SectionCommon";
import ButtonCommon from "../../../../components/common/ButtonCommon";
import DataLimitCommon from "../../../../components/common/DataLimitCommon";
import SelectColorCommon from "../../../../components/common/SelectColorCommon";
import AutoCompleteCommon from "../../../../components/common/AutoCompleteCommon";
import UploadImageAndSampleCommon from "../../../../components/common/UploadImageAndSampleCommon";
import UploadIconAndSampleCommon from "../../../../components/common/UploadIconAndSampleCommon";
import TextEditorCommon from "../../../../components/common/TextEditorCommon";

const AccreditationsCompany = () => {
  return (
    <>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        <Stack spacing={5}>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              41/120
            </Typography>
            <TextFieldCommon
              keyField={"titleField"}
              contentField="Title"
            />
          </Box>
          <Box>
            <Typography className="text-14 mb-10">
              Characters{" "}
              <EditButton keyField={"checkLength"} contentField="Characters" />:
              41/200
            </Typography>
            <TextFieldCommon
              keyField={"subtitleField"}
              contentField="Subtitle"
            />
          </Box>
          <Box>
            <UploadImageAndSampleCommon />
          </Box>
        </Stack>
      </Paper>
      <>
        <Typography className="text-24 fw-600">Data Limit <EditButton
                    keyField={"dataLimit_length"}
                    contentField="Data Limit"
                  />: 3 </Typography>

        <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>            <UploadIconAndSampleCommon classWidth="w-full" />
            </Grid>
            <Grid item xs={12} md={8}>
              <Stack spacing={5}>
            <Box>
              <Typography className="text-14 mb-10">
                Characters{" "}
                <EditButton
                  keyField={
                    "checkLength"
                  }
                  contentField="Characters"
                />
                : 41/35
              </Typography>
              <TextFieldCommon
                keyField={
                  "dataLimit_title"
                }
                contentField="Title"
              />
            </Box>
            <Box>
              

              <TextEditorCommon
                keyField={
                  "contentField"
                }
                contentField="Content"
              />
            </Box>
          </Stack>
            </Grid>
            <Grid item xs={12}>
               <Stack className="mt-30" alignItems={"flex-end"}>
        <ButtonCommon
          keyField={
            "addMoreButton"
          }
          contentField="Add More"
          bgColor="bg-white"
          textColor="text-tkg-blue"
          iconColor="#0000008a"
          borderColor="border-color-primary"
        />
      </Stack>
            </Grid>
          </Grid>
          
        </Paper>
      </>
     
    </>
  );
};

export default AccreditationsCompany;
